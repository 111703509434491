export default [
  {
    groupName: 'ContactsTile.Contact',
    fields: [
      {
        label: 'ContactsTile.FirstName',
        name: 'name',
        required: true,
        disabled: true,
        component: 'text'
      },
      {
        label: 'ContactsTile.JobTitle',
        name: 'jobTitle',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'ContactsTile.Function',
        name: 'function',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'ContactsTile.Department',
        name: 'department',
        required: false,
        disabled: true,
        component: 'text'
      }
    ]
  }
];
