import React, { useEffect, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import moment from 'moment';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { ACTION_MODES, FOCUS_FIELD_TIMEOUT, TAB_KEYS } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import { DIALOG_PATHS } from '@common/network/ApiPaths';
import { clearState, selectSavedState } from '@common/storage/persistSlice';
import CreateFooter from '@components/CreateFooter';
import CreateForm from '@components/CreateForm';
import {
  dragAndDropSaveResult,
  fetchData,
  fetchMeetingRoomsByVenueId,
  resetState,
  selectDragAndDropDraggableItems,
  selectDragAndDropEvents,
  selectDragAndDropFilter,
  setDragAndDropDraggableItems,
  setDragAndDropNonDraggableItems
} from '@components/DragAndDropCalendar/dragAndDropDialogSlice';
import { scrubFiltersForBE } from '@components/FilterDialog/filtersSlice';
import Header from '@components/Header';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { coursesPath, iltSessionsPath } from '@navigation/routes/Routes';
import {
  CLASSROOM_TYPE_ID,
  WEBINAR_TYPE_ID,
  createIltSession
} from '@pages/IltSession/iltSessionSlice';
import initialValues from '@pages/IltSession/util/initialValues';
import useCreateSchema from '@pages/IltSession/util/schemas/useCreateSchema';

import getFieldsConfig from './getFieldsConfig';

const CreateIltSession = () => {
  const {
    data: {
      type: { data: type },
      status: { data: status }
    }
  } = useMatch();
  const savedState = useSelector(selectSavedState) || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const iltSessionValidationSchema = useCreateSchema();
  const { course, module } = savedState;
  const isNavigatedFromCourses = Boolean(course && module);
  const filteredTypes = type.filter(({ id }) => id === WEBINAR_TYPE_ID || id === CLASSROOM_TYPE_ID);
  const dragAndDropDraggableItems = useSelector(selectDragAndDropDraggableItems);
  const dragAndDropFilter = useSelector(selectDragAndDropFilter);
  const dragAndDropNonDraggableItems = useSelector(selectDragAndDropEvents);

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createIltSession(values))
      .unwrap()
      .then(() => {
        isNavigatedFromCourses
          ? navigate({ to: coursesPath, replace: false })
          : navigate({
              to: iltSessionsPath,
              search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
              replace: false
            });
      })
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'ILT_SESSION', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const onCancel = () => {
    isNavigatedFromCourses
      ? navigate({ to: coursesPath, replace: true })
      : navigate({
          to: iltSessionsPath,
          search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
          replace: true
        });
  };

  const clearDragAndDrop = () => dispatch(resetState());

  const onVenueChange = (venues = []) => {
    if (!venues?.length) {
      return;
    }

    dispatch(
      fetchMeetingRoomsByVenueId({
        filter: {
          page: 0,
          filters: {
            advancedFilters: scrubFiltersForBE({
              filters: [
                {
                  id: 'venueId',
                  operator: { key: 'contains' },
                  value: venues[0].id
                }
              ]
            })
          }
        }
      })
    )
      .then(({ payload }) =>
        dispatch(
          fetchData({
            path: DIALOG_PATHS.MEETING_ROOMS,
            filter: {
              ...dragAndDropFilter,
              meetingRoomIds: payload?.content
                ? payload.content.slice(0, 3).map(({ id }) => id)
                : [],
              venueIds: venues?.length && venues[0].id
            },
            filterValues: venues
          })
        )
      )
      .catch(() => {});
  };

  const onSessionDayUpdate = (key, value) => {
    // Event already placed on diagram
    if (dragAndDropNonDraggableItems?.length) {
      const nonDraggableEvents = dragAndDropNonDraggableItems.filter((e) => !e.isDraggable);
      const draggableEvent = dragAndDropNonDraggableItems.filter((e) => e.isDraggable)[0];

      if (!draggableEvent) {
        dispatch(
          setDragAndDropDraggableItems({
            items: [
              {
                ...dragAndDropDraggableItems[0],
                [key]: value
              }
            ],
            saveInitial: true
          })
        );
      } else {
        dispatch(
          setDragAndDropNonDraggableItems(
            nonDraggableEvents.concat({ ...draggableEvent, [key]: value })
          )
        );
      }

      return;
    }

    const oldDraggableItem = dragAndDropDraggableItems[0];
    const updatedDraggableItem = { ...oldDraggableItem, [key]: value };
    dispatch(
      setDragAndDropDraggableItems({
        items: [
          {
            ...updatedDraggableItem,
            isPredefinedTimeInUse: Boolean(
              updatedDraggableItem.predefinedStart && updatedDraggableItem.predefinedEnd
            )
          }
        ],
        saveInitial: true
      })
    );
  };

  const onSessionDayDateChange = (value) => {
    dispatch(
      fetchData({
        path: DIALOG_PATHS.MEETING_ROOMS,
        filter: {
          ...dragAndDropFilter,
          sessionDayDate: moment(value).format(moment.HTML5_FMT.DATE)
        }
      })
    );
  };

  const onRoomRemove = () => {
    const nonDraggableEvents = dragAndDropNonDraggableItems.filter((e) => !e.isDraggable);
    const draggableEvent = dragAndDropNonDraggableItems.filter((e) => e.isDraggable)[0];
    dispatch(
      setDragAndDropDraggableItems({
        items: [draggableEvent]
      })
    );
    dispatch(setDragAndDropNonDraggableItems(nonDraggableEvents));
    dispatch(dragAndDropSaveResult());
  };

  const sessionDayMetrics = useMemo(
    () => [
      { value: Localize.get('MetricTypes.Minutes'), id: 1 },
      { value: Localize.get('MetricTypes.Hours'), id: 2 },
      { value: Localize.get('MetricTypes.Days'), id: 3 }
    ],
    []
  );

  useEffect(() => {
    if (!isNavigatedFromCourses) {
      return;
    }

    setTimeout(() => document.getElementsByName('name')[0]?.focus(), FOCUS_FIELD_TIMEOUT);
  }, []);

  useEffect(() => {
    return () => {
      // Cleans drag and drop slice
      dispatch(resetState());
      // Cleans persist slice
      dispatch(clearState());
    };
  }, []);

  return (
    <>
      <Header>{Localize.get('IltSession.NewIltSession')}</Header>

      <Formik
        validateOnMount
        enableReinitialize={true}
        initialValues={
          isNavigatedFromCourses
            ? {
                // Set IltModule and Course default values - form opened from Courses Module
                ...initialValues,
                iltModule: module,
                course: course,
                name: module?.name
              }
            : initialValues
        }
        validationSchema={iltSessionValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          handleSubmit,
          isValid,
          setFieldValue,
          values,
          setValues,
          setFieldTouched,
          errors
        }) => (
          <>
            <CreateForm
              values={values}
              fieldsConfig={getFieldsConfig(
                setFieldValue,
                values,
                setValues,
                setFieldTouched,
                filteredTypes,
                status,
                isNavigatedFromCourses,
                errors,
                clearDragAndDrop,
                onSessionDayUpdate,
                onVenueChange,
                onSessionDayDateChange,
                onRoomRemove,
                sessionDayMetrics
              )}
            />
            <CreateFooter>
              <Button
                data-test-id="cancel-btn"
                variant="outlined"
                onClick={onCancel}
                color="error"
                startIcon={<CancelIcon />}
              >
                {Localize.get('Buttons.Cancel')}
              </Button>

              <Button
                data-test-id="save-btn"
                variant="outlined"
                disabled={isSubmitting || !isValid}
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
              >
                {Localize.get('Buttons.Save')}
              </Button>
            </CreateFooter>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateIltSession;
