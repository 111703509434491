import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'IltSession.IltSessionOverview',
    fields: [
      {
        label: 'IltSession.IltSessionName',
        name: 'sessionName',
        required: true,
        disabled: true,
        component: 'text'
      },
      {
        label: 'IltSession.StartDate',
        name: 'sessionStartDateTime',
        required: true,
        disabled: true,
        component: 'datepicker'
      },
      {
        label: 'IltSession.EndDate',
        name: 'sessionEndDateTime',
        required: true,
        disabled: true,
        component: 'datepicker'
      },
      {
        label: 'IltSession.Course',
        name: 'course',
        required: true,
        disabled: true,
        dialogTitle: 'IltSession.SearchCourse',
        component: 'tokenValue'
      },
      {
        label: 'IltSession.IltModule',
        name: 'module',
        required: true,
        disabled: true,
        dialogTitle: 'IltSession.SearchIltModule',
        component: 'tokenValue'
      }
    ]
  },
  {
    groupName: 'IltSession.SessionDayInformation',
    fields: [
      {
        label: 'IltSession.SessionDayName',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'IltSession.SessionDayDetails',
        name: 'description',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'IltSession.Date',
        name: 'dayDate',
        required: true,
        disabled: true,
        component: 'datepicker'
      },
      {
        label: 'IltSession.TimeFrom',
        name: 'startTimeDate',
        required: true,
        disabled: true,
        component: 'timepicker'
      },
      {
        label: 'IltSession.TimeTo',
        name: 'endTimeDate',
        required: true,
        disabled: true,
        component: 'timepicker'
      },
      {
        label: 'IltSession.Instructors',
        name: 'instructors',
        required: true,
        dialogTitle: 'IltSession.SearchInstructor',
        component: 'tokenValueArray',
        fetchDataPath: DIALOG_PATHS.INSTRUCTOR,
        value: '',
        initQueryState: {
          id: '',
          fullName: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'IltSession.RoomName',
        name: 'room',
        required: false,
        disabled: true,
        dialogTitle: 'IltSession.RoomName',
        component: 'tokenValueArray',
        fetchDataPath: DIALOG_PATHS.INSTRUCTOR,
        value: '',
        initQueryState: {
          id: '',
          fullName: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'fullName',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      }
    ]
  },
  {
    groupName: 'IltSession.SessionDayReminder',
    fields: [
      {
        label: 'IltSession.SendRemindersBeforeSession',
        name: 'hasReminder',
        required: false,
        disabled: false,
        component: 'switch',
        column: 4
      },
      {
        label: 'IltSession.ReminderValue',
        name: 'reminderValue',
        required: true,
        disabled: false,
        component: 'text',
        column: 3,
        type: 'number'
      },
      {
        label: 'IltSession.ReminderMetric',
        name: 'metricTypeId',
        required: true,
        disabled: false,
        component: 'select',
        column: 3
      }
    ]
  }
];
