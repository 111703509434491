import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import Overview from '@components/Overview';
import { contactsNewPath } from '@navigation/routes/Routes';
import { selectDetails, selectIsDetailsLoading } from '@pages/Contacts/contactsSlice';

const config = {
  title: 'ContactsTile.Overview',
  skeletonCount: 6,
  header: {
    title: 'ContactsTile.ContactName',
    subTitle: ['person.firstName', 'person.lastName'],
    icon: 'person'
  },
  details: [
    {
      label: 'ContactsTile.Function',
      name: 'function'
    },
    {
      label: 'ContactsTile.Department',
      name: 'department'
    },
    {
      label: 'ContactsTile.Phone',
      name: 'phoneDtos[0].phone'
    },
    {
      label: 'ContactsTile.Email',
      name: 'emailDtos[0].email'
    }
  ]
};

const ContactsOverview = () => {
  const details = useSelector(selectDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  return (
    <Overview
      invite={{
        text: Localize.get('Links.InviteLink', {
          entity: Localize.get('ContactsTile.Contact')
        }),
        link: contactsNewPath
      }}
      isLoading={isDetailsLoading}
      data={details}
      config={config}
    />
  );
};

export default ContactsOverview;
