import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

export const resourcesFilters = [
  {
    label: 'ResourcesTile.ResourceName',
    id: 'name',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'ResourcesTile.Category',
    id: 'category',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  },
  {
    label: 'ResourcesTile.AmountIrrelevant',
    id: 'amountIrrelevant',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'ResourcesTile.ConsumableItem',
    id: 'consumableItem',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'ResourcesTile.BillableItem',
    id: 'billableItem',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'ResourcesTile.Price',
    id: 'price',
    type: FILTER_TYPES.number,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' }
  },
  {
    label: 'ResourcesTile.Currency',
    id: 'currency',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  }
];
