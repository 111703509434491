import React, { useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useLocation, useNavigate, useSearch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { selectActiveTab, setActiveTab } from '@components/DetailsToolbar/detailsToolbarSlice';
import { useAppStyles } from '@navigation/AppRouter';
import { newPath, rootPath } from '@navigation/routes/Routes';

const useStyles = makeStyles(({ palette, sizes }) => {
  return {
    tabs: {
      backgroundColor: palette.primary.contrastText,
      height: sizes.tabsHeight
    }
  };
});

const TabPanel = ({ children, value, index, ...rest }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...rest}
  >
    {value === index && (
      <Box>
        <Typography component="span">{children}</Typography>
      </Box>
    )}
  </div>
);

const TabsWrapper = ({
  tabsConfig = null,
  onTabChange = () => {},
  renderContent = () => {},
  count = null,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeTab = useSelector(selectActiveTab);
  const navigate = useNavigate();
  const search = useSearch();
  const appStyles = useAppStyles({ isBlur: search?.mode === ACTION_MODES.Create });
  const tabsLengthRef = useRef(tabsConfig.tabs.length);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    dispatch(setActiveTab(newValue));
    navigate({
      search: (previousUrlParams) => ({
        ...previousUrlParams,
        activeTab: newValue || TAB_KEYS.OVERVIEW
      })
    });
    onTabChange && onTabChange(event, newValue);
  };

  useEffect(() => {
    if (search?.activeTab === activeTab) {
      return;
    }

    dispatch(setActiveTab(search?.activeTab));
  }, [search?.activeTab, activeTab]);

  // Fallback to default tab here, in case one entity has different tabs than another
  useEffect(() => {
    // Don't fallback for dashboard
    if (location?.current?.pathname === rootPath) {
      return;
    }

    // Don't fallback for create forms
    if ([newPath].some((path) => location?.current?.pathname.includes(path))) {
      return;
    }

    if (!tabsConfig.tabs?.length) {
      return;
    }

    const defaultTab = tabsConfig.tabs[0];
    const isUrlTabAvailable = !!tabsConfig?.tabs?.find((t) => t.key === search.activeTab);
    if (!isUrlTabAvailable) {
      navigate({ search: () => ({ activeTab: defaultTab.key }) });
    }
  }, [tabsConfig?.tabs?.length, tabsLengthRef.current]);

  return (
    <>
      <Box>
        <Tabs
          className={`${classes.tabs} ${appStyles.blur}`}
          value={
            tabsConfig?.tabs?.find((t) => t.key === activeTab)
              ? activeTab
              : tabsConfig?.tabs[0]?.key
          }
          onChange={handleChange}
          aria-label="tabs"
          {...rest}
        >
          {tabsConfig?.tabs?.map(
            ({ key = '', icon = 'error', label = '', isCountVisible = true }, index) => {
              return (
                <Tab
                  data-test-id={`${key}-tabs`}
                  value={key || 'ExampleTab'}
                  key={index}
                  icon={
                    <Badge
                      max={9999}
                      showZero
                      badgeContent={isCountVisible ? (count && count[key]) || 0 : null}
                      color="primary"
                    >
                      <Icon>{icon}</Icon>
                    </Badge>
                  }
                  label={<Typography variant="caption">{Localize.get(label)}</Typography>}
                />
              );
            }
          )}
        </Tabs>
      </Box>
      <TabPanel
        value={
          tabsConfig?.tabs.find((t) => t.key === activeTab) ? activeTab : tabsConfig?.tabs[0].key
        }
        index={activeTab}
      >
        {renderContent(tabsConfig.tabs.find((t) => t.key === activeTab))}
      </TabPanel>
    </>
  );
};

export default TabsWrapper;
