import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { createAttachment } from '@pages/IltSession/util/fields/createAttachment';

const getFieldsConfig = (setFieldValue) => {
  return mapPropsToComponent(createAttachment, {
    ['file']: {
      onFileInputChange: (event) => {
        setFieldValue('file', event.currentTarget.files[0]);
      },
      onClearInputChange: (uploadRef) => {
        setFieldValue('file', null);
        if (uploadRef.current) {
          uploadRef.current.value = '';
        }
      }
    }
  });
};

export default getFieldsConfig;
