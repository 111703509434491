export default [
  {
    groupName: 'Labels.Contingent',
    fields: [
      {
        label: 'Event.Event',
        name: 'event.name',
        required: false,
        disabled: true,
        component: 'tokenValue',
        dialogTitle: 'Labels.SearchAccount'
      },
      {
        label: 'Labels.ContingentAmount',
        name: 'contingent',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'ResourcesTile.Price',
        name: 'price',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Hotel.RoomType',
        name: 'accommodation.roomType.value',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'ResourcesTile.Currency',
        name: 'currency.value',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Labels.StartDate',
        name: 'startDate',
        required: false,
        disabled: true,
        component: 'datepicker'
      },
      {
        label: 'Labels.EndDate',
        name: 'endDate',
        required: false,
        disabled: true,
        component: 'datepicker'
      },
      {
        label: 'Labels.ValidUntil',
        name: 'validUntil',
        required: false,
        disabled: true,
        component: 'datepicker'
      }
    ]
  }
];
