import { useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';

import { DIALOG_PATHS } from '@common/network/ApiPaths';
import DatePicker from '@components/DatePicker';
import { scrubFiltersForBE } from '@components/FilterDialog/filtersSlice';
import { FilterLabel, FilterSelect } from '@components/TokenValueModalTrigger/components/styled';

import {
  fetchData,
  fetchMeetingRoomsByVenueId,
  selectDragAndDropFilter,
  selectDragAndDropResources,
  selectListOfMeetingRooms,
  selectMeetingRoomFilter,
  selectVenuesFilterValue
} from '../dragAndDropDialogSlice';
import RoomsFilter from './RoomsFilter';

const getVenueOptions = (venueDtos = []) => [...venueDtos];

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative'
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: {
    height: '31px',
    '&:not(:last-child)': {
      marginRight: '2px'
    }
  },
  buttonLeft: {
    float: 'left',
    marginRight: '10px'
  },
  buttonRight: {
    float: 'right',
    marginLeft: '10px'
  },
  currentMonth: {
    display: 'inline',
    fontWeight: '600',
    color: theme.palette.primary.main
  },
  buttonsHeight: {
    height: '31px'
  }
}));

const CustomToolbar = ({ date, onNavigate }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const venuesFilter = useSelector(selectVenuesFilterValue) || [];
  const dragAndDropFilter = useSelector(selectDragAndDropFilter) || {};
  const resources = useSelector(selectDragAndDropResources);
  const meetingRoomFilter = useSelector(selectMeetingRoomFilter);
  const listOfMeetingRooms = useSelector(selectListOfMeetingRooms);

  const onFilterChange = (key, value) => {
    dispatch(
      fetchData({
        path: DIALOG_PATHS.MEETING_ROOMS,
        filter: {
          ...dragAndDropFilter,
          [key]: value
        },
        filterValues: venuesFilter
      })
    );
  };

  const getMeetingRooms = (value) => {
    return dispatch(
      fetchMeetingRoomsByVenueId({
        filter: {
          ...meetingRoomFilter,
          page: 0,
          search: '',
          filters: {
            advancedFilters: scrubFiltersForBE({
              filters: [
                {
                  id: 'venueId',
                  operator: { key: 'contains' },
                  value
                }
              ]
            })
          }
        },
        isReset: true
      })
    )
      .unwrap()
      .then(({ content }) => {
        return dispatch(
          fetchData({
            path: DIALOG_PATHS.MEETING_ROOMS,
            filter: {
              ...dragAndDropFilter,
              venueIds: value,
              meetingRoomIds: content.slice(0, 3).map(({ id }) => id)
            },
            filterValues: venuesFilter
          })
        );
      })
      .catch(() => {});
  };

  const filterItems = useMemo(() => {
    return getVenueOptions(venuesFilter)?.map(({ id, name }, index) => (
      <MenuItem className={classes.menuItem} divider key={index} value={id}>
        {name}
      </MenuItem>
    ));
  }, [JSON.stringify(venuesFilter)]);

  return (
    <Box className={classes.container}>
      {venuesFilter?.length ? (
        <Box className={classes.filtersContainer}>
          <FormControl>
            <FilterLabel id="venues-filter-select-label">
              {Localize.get('DragAndDrop.VenuesFilter')}
            </FilterLabel>
            <FilterSelect
              sx={{ maxHeight: 400, overflow: scroll, m: 1 }}
              id="venues-filter-select"
              value={dragAndDropFilter?.venueIds || null}
              labelId="venues-filter-select-label"
              onChange={(e) => getMeetingRooms(e.target.value)}
            >
              {filterItems}
            </FilterSelect>
          </FormControl>

          {Boolean(resources?.length) && Boolean(listOfMeetingRooms?.length) && (
            <RoomsFilter listOfMeetingRooms={listOfMeetingRooms} />
          )}
        </Box>
      ) : (
        <Box></Box>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', height: '35px', mt: '4px' }}>
        <Button
          className={classes.button}
          data-test-id="today-button"
          variant="outlined"
          onClick={() => onNavigate('TODAY')}
        >
          {Localize.get('Calendar.Today')}
        </Button>
        <Button
          data-test-id="prev-button"
          variant="outlined"
          className={classes.button}
          onClick={() => onNavigate('PREV')}
        >
          {'<'}
        </Button>
        <DatePicker
          className={classes.button}
          data-test-id="date-input"
          isManualInputDisabled
          field={{ value: date }}
          InputProps={{ className: classes.button }}
          onChange={(value) => {
            onFilterChange('sessionDayDate', moment(value).format(moment.HTML5_FMT.DATE));
          }}
        />
        <Button
          data-test-id="next-button"
          variant="outlined"
          className={classes.button}
          onClick={() => onNavigate('NEXT')}
        >
          {'>'}
        </Button>
      </Box>
    </Box>
  );
};

export default CustomToolbar;
