export default Object.freeze({
  NOTE: 'NOTE',
  AGENDA: 'AGENDA',
  ABSTRACT: 'ABSTRACT',
  INTERNAL: 'INTERNAL',
  CV: 'CV',
  ROOM_NOTE: 'ROOM_NOTE',
  TRAINER_NOTE: 'TRAINER_NOTE',
  LOCATION_AREA_NOTE: 'LOCATION_AREA_NOTE',
  PARTICIPANTS_AREA_NOTE: 'PARTICIPANTS_AREA_NOTE'
});
