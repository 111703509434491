import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

const meetingRoomsFilters = [
  {
    label: 'MeetingRoomsFilter.Name',
    id: 'name',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'MeetingRoomsFilter.AvailableSeats',
    id: 'availableSeats',
    type: FILTER_TYPES.number,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' }
  },
  {
    label: 'MeetingRoomsFilter.Floor',
    id: 'floor',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'MeetingRoomsFilter.VenueName',
    id: 'venueName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  }
];

export default meetingRoomsFilters;
