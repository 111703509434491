import React, { useState, useMemo, useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate, useSearch } from 'react-location';
import { useDispatch } from 'react-redux';

import { Form, Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import EntityTypes from '@common/network/EntityTypes';
import CreateFooter from '@components/CreateFooter';
import CreateForm from '@components/CreateForm';
import FormContainer from '@components/FormContainer/FormContainer';
import Header from '@components/Header';
import {
  businessPartnersPath,
  iltSessionsPath,
  participantsNewPath
} from '@navigation/routes/Routes';
import {
  customerInitialValues,
  accountInitialValues
} from '@pages/BusinessPartners/util/initialValues';
import useAccountSchema from '@pages/BusinessPartners/util/schemas/useAccountSchema';
import useCustomerSchema from '@pages/BusinessPartners/util/schemas/useCustomerSchema';
import {
  ACCOUNT_STEPS,
  INDIVIDUAL_BUSINESS_STEPS,
  SUPPLIER_STEPS
} from '@pages/BusinessPartners/util/stepsConfig';

import { createBusinessPartner } from '../../businessPartnersSlice';
import getAccountConfig from './getAccountConfig';
import getCustomerConfig, { mapPropsToAddress } from './getCustomerConfig';
import BusinessPartnerType from './steps/PartnerType';
import RoleTypes from './steps/RoleTypes';
import SupplierTypes from './steps/SupplierTypes';

const CreateBusinessPartner = () => {
  const { isAccount, iltSessionId } = useSearch();
  const ACCOUNT_STEPS_LOCALIZED = useMemo(
    () => ACCOUNT_STEPS.map((option) => Localize.get(option)),
    []
  );
  const SUPPLIER_STEPS_LOCALIZED = useMemo(
    () => SUPPLIER_STEPS.map((option) => Localize.get(option)),
    []
  );
  const INDIVIDUAL_BUSINESS_STEPS_LOCALIZED = useMemo(
    () => INDIVIDUAL_BUSINESS_STEPS.map((option) => Localize.get(option)),
    []
  );

  const {
    data: {
      businessPartnerTypes: { data: businessPartnerTypes },
      supplierTypes: { data: supplierTypes },
      roleTypes: { data: roleTypes },
      phoneTypes: { data: phoneTypes },
      addressTypes: { data: addressTypes },
      emailTypes: { data: emailTypes }
    }
  } = useMatch();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerValidationSchema = useCustomerSchema();
  const accountValidationSchema = useAccountSchema();

  const [validationSchema, setValidationSchema] = useState(customerValidationSchema);
  const [initialValues, setInitialValues] = useState(customerInitialValues);
  const [steps, setSteps] = useState(INDIVIDUAL_BUSINESS_STEPS_LOCALIZED);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [title, setTitle] = useState(
    `${Localize.get('BusinessPartners.NewBusinessPartner')} - ${Localize.get(
      'BusinessPartners.IndividualCustomer'
    )}`
  );

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createBusinessPartner({ postData: values, type: EntityTypes.BUSINESS_PARTNER }))
      .unwrap()
      .then(() => {
        if (isAccount && iltSessionId) {
          navigate({
            to: iltSessionsPath,
            replace: true,
            search: { activeTab: TAB_KEYS.PARTICIPANTS, mode: ACTION_MODES.Create }
          });
          return;
        }

        if (isAccount) {
          navigate({ to: participantsNewPath, replace: true });
          return;
        }

        navigate({
          to: businessPartnersPath,
          search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
          replace: true
        });
      })
      .finally(() => setSubmitting(false));
  };

  // Query param isAccount=true is present in the url
  useEffect(() => {
    if (!isAccount) {
      return;
    }

    setInitialValues(accountInitialValues);
    setValidationSchema(accountValidationSchema);
    setSteps(ACCOUNT_STEPS_LOCALIZED);
    setTitle(
      `${Localize.get('BusinessPartners.NewBusinessPartner')} - ${Localize.get(
        'BusinessPartners.Account'
      )}`
    );
    setActiveStep(1);
  }, [isAccount]);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isNextDisabled = (isValid) => {
    if (activeStep === 0 || activeStep === 1) {
      return false;
    }

    if (activeStep === 2) {
      return !isValid;
    }

    return true;
  };

  const isBackDisabled = () => {
    if (isAccount) {
      return activeStep === 1;
    }

    return activeStep === 0;
  };

  const onBusinessTypeChange = (event) => {
    // Customer
    if (event?.target?.value === 1) {
      setSteps(INDIVIDUAL_BUSINESS_STEPS_LOCALIZED);
      setInitialValues(customerInitialValues);
      setValidationSchema(customerValidationSchema);
      setTitle(
        `${Localize.get('BusinessPartners.NewBusinessPartner')} - ${Localize.get(
          'BusinessPartners.IndividualCustomer'
        )}`
      );
      return;
    }

    // Account
    setInitialValues(accountInitialValues);
    setValidationSchema(accountValidationSchema);
    setSteps(ACCOUNT_STEPS_LOCALIZED);
    setTitle(
      `${Localize.get('BusinessPartners.NewBusinessPartner')} - ${Localize.get(
        'BusinessPartners.Account'
      )}`
    );
  };

  const onRoleTypesChange = (event) => {
    event.target.value === 2
      ? setSteps(SUPPLIER_STEPS_LOCALIZED) // Supplier Role
      : setSteps(ACCOUNT_STEPS_LOCALIZED); // Prospect&Customer Role
  };

  const onCancel = () => {
    if (isAccount && iltSessionId) {
      navigate({
        to: iltSessionsPath,
        replace: true,
        search: { activeTab: TAB_KEYS.PARTICIPANTS, mode: ACTION_MODES.Create }
      });
      return;
    }

    if (isAccount) {
      navigate({ to: participantsNewPath, replace: false });
      return;
    }

    navigate({
      to: businessPartnersPath,
      search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
      replace: false
    });
  };

  return (
    <>
      <Header>{title}</Header>
      <Stepper sx={{ p: '2rem' }} activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Typography variant="h5" sx={{ textAlign: 'center', color: 'primary.main' }}>
        {Localize.get('Labels.Step')} {activeStep + 1}
      </Typography>

      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values, handleChange, setFieldValue, handleSubmit, isValid }) => (
          <>
            <Box sx={{ width: '100%' }}>
              <Form>
                {/* 1st step */}
                {activeStep === 0 && (
                  <FormContainer>
                    <BusinessPartnerType
                      options={businessPartnerTypes}
                      handleChange={(event) => {
                        onBusinessTypeChange(event);
                        handleChange(event);
                      }}
                    />
                  </FormContainer>
                )}
                {/* 2nd step Customer form */}
                {activeStep === 1 && values.businessPartnerType === 1 && (
                  <CreateForm
                    values={values}
                    mapPropsToAddress={mapPropsToAddress}
                    fieldsConfig={getCustomerConfig(
                      values,
                      setFieldValue,
                      supplierTypes,
                      roleTypes,
                      phoneTypes,
                      emailTypes,
                      addressTypes
                    )}
                  />
                )}

                {/* 2nd step Role type */}
                {activeStep === 1 && values.businessPartnerType === 2 && (
                  <FormContainer>
                    <RoleTypes
                      options={roleTypes}
                      handleChange={(values) => {
                        onRoleTypesChange(values);
                        handleChange(values);
                      }}
                    />
                  </FormContainer>
                )}

                {/* 3rd step Role type */}
                {activeStep === 2 && (
                  <CreateForm
                    values={values}
                    mapPropsToAddress={mapPropsToAddress}
                    fieldsConfig={getAccountConfig(
                      values,
                      setFieldValue,
                      roleTypes,
                      phoneTypes,
                      emailTypes,
                      addressTypes
                    )}
                  />
                )}

                {/* 4th step */}
                {activeStep === 3 && values.roles === 2 && (
                  <FormContainer>
                    <SupplierTypes
                      options={supplierTypes}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </FormContainer>
                )}
              </Form>
            </Box>

            <CreateFooter>
              <Button
                data-test-id="cancel-btn"
                variant="outlined"
                startIcon={<CancelIcon />}
                color="error"
                onClick={onCancel}
                sx={{ mr: 1 }}
              >
                {Localize.get('Buttons.Cancel')}
              </Button>
              <Button
                data-test-id="back-btn"
                variant="outlined"
                sx={{ mr: 1 }}
                startIcon={<NavigateBeforeIcon />}
                disabled={isBackDisabled()}
                onClick={handleBack}
              >
                {Localize.get('Buttons.Back')}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              {activeStep === steps.length - 1 ? (
                <Button
                  data-test-id="save-btn"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                  disabled={isSubmitting || !isValid}
                  onClick={handleSubmit}
                >
                  {Localize.get('Buttons.Save')}
                </Button>
              ) : (
                <Button
                  data-test-id="next-btn"
                  endIcon={<NavigateNextIcon />}
                  variant="outlined"
                  disabled={isNextDisabled(isValid)}
                  onClick={handleNext}
                >
                  {Localize.get('Buttons.Next')}
                </Button>
              )}
            </CreateFooter>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateBusinessPartner;
