import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CreateFooter from '@components/CreateFooter';

import DialogDataGrid from './DialogDataGrid';

const TokenValueArrayDialog = ({
  selectItem = () => {},
  selectionModel = [],
  extendedComponents,
  dialogTitle = '',
  dialogColumns = [],
  fetchDataPath = '',
  initQueryState = {},
  getRowId = undefined,
  modalOpen,
  setModalOpen
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const isSaveDisabled = selectedItems.length === 0;

  const onSelectChange = (selectedIds, data) => {
    if (!data?.length) {
      return;
    }

    if (!Array.isArray(selectedIds)) {
      return;
    }

    // Save selection data (could be new page)
    const onePageData = data.filter(({ id }) =>
      selectedIds.some((selectedId) => id === selectedId)
    );

    // Merge previousUrlParams selectedItems with onePage data (which can be new page) by removing duplicates
    const mergedValues = [...new Set(onePageData.concat(...selectedItems))];

    const newItems = mergedValues
      // Filter non-selected values
      .filter(({ id }) => selectedIds.includes(id))
      // Remove duplicates
      .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

    setSelectedItems(newItems);
  };

  useEffect(() => {
    if (modalOpen) {
      setSelectedItems(selectionModel);
    } else {
      setSelectedItems([]);
    }
  }, [modalOpen]);

  return (
    <>
      <Dialog fullWidth maxWidth={'lg'} onClose={() => setModalOpen(false)} open={modalOpen}>
        <DialogTitle>{Localize.get(dialogTitle)}</DialogTitle>
        <DialogContent>
          <DialogDataGrid
            fetchDataPath={fetchDataPath}
            initQueryState={initQueryState}
            columns={dialogColumns}
            selectionModel={selectionModel}
            extendedComponents={extendedComponents}
            getRowId={getRowId}
            onSelectChange={onSelectChange}
            selectedItems={selectedItems}
          />
        </DialogContent>
        <CreateFooter position="absolute">
          <Button
            data-test-id="cancel-btn"
            variant="outlined"
            onClick={() => {
              setModalOpen(false);
            }}
            color="error"
            startIcon={<CancelIcon />}
          >
            {Localize.get('Buttons.Cancel')}
          </Button>
          <Button
            data-test-id="save-btn"
            variant="outlined"
            startIcon={<SaveIcon />}
            disabled={isSaveDisabled}
            onClick={() => {
              selectItem(selectedItems);
              setModalOpen(false);
            }}
          >
            {Localize.get('Labels.Select')}
          </Button>
        </CreateFooter>
      </Dialog>
    </>
  );
};

export default TokenValueArrayDialog;
