import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import CreateFooter from '@components/CreateFooter';
import CreateForm from '@components/CreateForm';
import Header from '@components/Header';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { employeesPath } from '@navigation/routes/Routes';
import { createEmployee } from '@pages/Employees/employeesSlice';
import initialValues from '@pages/Employees/util/initialValues';
import useCreateSchema from '@pages/Employees/util/schemas/useCreateSchema';

import getFieldsConfig, { mapPropsToAddress } from './getFieldsConfig';

const CreateEmployee = () => {
  const {
    data: {
      phoneTypes: { data: phoneTypes },
      emailTypes: { data: emailTypes },
      addressTypes: { data: addressTypes }
    }
  } = useMatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employeesValidationSchema = useCreateSchema();

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createEmployee(values))
      .unwrap()
      .then(() =>
        navigate({
          to: employeesPath,
          search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
          replace: false
        })
      )
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'EMPLOYEE', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const onCancel = () =>
    navigate({
      to: employeesPath,
      search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
      replace: true
    });

  return (
    <>
      <Header>{Localize.get('Employees.NewEmployee')}</Header>

      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={employeesValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, handleSubmit, isValid, setFieldValue, values, setValues }) => (
          <>
            <CreateForm
              values={values}
              mapPropsToAddress={mapPropsToAddress}
              fieldsConfig={getFieldsConfig(
                setFieldValue,
                phoneTypes,
                emailTypes,
                addressTypes,
                values,
                setValues
              )}
            />

            <CreateFooter>
              <Button
                data-test-id="cancel-btn"
                variant="outlined"
                onClick={onCancel}
                color="error"
                startIcon={<CancelIcon />}
              >
                {Localize.get('Buttons.Cancel')}
              </Button>

              <Button
                data-test-id="save-btn"
                variant="outlined"
                disabled={isSubmitting || !isValid}
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
              >
                {Localize.get('Buttons.Save')}
              </Button>
            </CreateFooter>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateEmployee;
