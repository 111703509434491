import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import { omit } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import CreateForm from '@components/CreateForm';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { selectLocationDetails } from '@pages/BusinessPartners/businessPartnersSlice';

import { createAccommodations } from '../accommodationTableSlice';
import { accommodationSchema } from './accommodationSchema';
import getFieldsConfig from './getFieldsConfig';
import initialValues from './initialValues';

const CreateAccommodation = () => {
  const {
    data: {
      roomTypes: { data: roomTypes }
    }
  } = useMatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = useSelector(selectLocationDetails);

  const onSubmit = (values, { setSubmitting }) => {
    const role = details?.roles?.find(({ hotel }) => Boolean(hotel?.id));
    if (role?.hotel?.id) {
      dispatch(createAccommodations({ entityId: role.hotel.id, values, setSubmitting, navigate }));
    }
  };

  const onCancel = () => {
    navigate({ search: (previousUrlParams) => ({ ...omit(previousUrlParams, 'mode') }) });
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={accommodationSchema}
    >
      {({ isSubmitting, values, handleSubmit, isValid }) => (
        <>
          <InlineCreateHeaderContainer>
            <Button
              data-test-id="cancel-btn"
              variant="outlined"
              onClick={onCancel}
              color="error"
              sx={{ mr: 1 }}
              startIcon={<CancelIcon />}
            >
              {Localize.get('Buttons.Cancel')}
            </Button>
            <Button
              data-test-id="save-btn"
              variant="outlined"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
            >
              {Localize.get('Buttons.Save')}
            </Button>
          </InlineCreateHeaderContainer>

          <CreateForm values={values} fieldsConfig={getFieldsConfig(roomTypes)} />
        </>
      )}
    </Formik>
  );
};

export default CreateAccommodation;
