import { Navigate } from 'react-location';

import { TAB_KEYS } from '@common/Constants';
import {
  DOCUMENTS_PATHS,
  EVENTS_PATHS,
  PARTICIPANTS_PATHS,
  SESSION_PATHS,
  SETTINGS_PATH,
  LOCALES_PATHS,
  ACTIVITIES_PATHS
} from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import Events, { CreateEvent } from '@pages/Events';
import Hotels from '@pages/Events/components/Hotels/Hotels';
import Session from '@pages/Events/components/Tabs/SessionsTable/Session';
//import BookingCalendar from '@pages/Events/Booking/components/BookingCalendar';
import Details from '@pages/Participants/pages/Details';
import { getByPathAndParams } from '@services/BaseApi';

import {
  eventsPath,
  newPath,
  rootPath,
  eventsSessionDetailsSlug,
  eventsDetailsSlug,
  eventsParticipantDetailsSlug,
  eventsDetailsHotelSlug
  //eventsHotelBookingSlug
} from '../Routes';

const EventRoute = (routeCache) => ({
  path: eventsPath,
  children: [
    {
      path: rootPath,
      element: <Events />,
      loader: routeCache.createLoader(
        async () => ({
          config: getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.EVENT }
          }),
          documentTypes: await getByPathAndParams({
            path: DOCUMENTS_PATHS.GET_DOCUMENT_TYPES
          }),
          status: await getByPathAndParams({
            path: EVENTS_PATHS.EVENTS_STATUSES
          }),
          type: await getByPathAndParams({
            path: EVENTS_PATHS.EVENTS_TYPES
          }),
          nativeLanguage: await getByPathAndParams({
            path: EVENTS_PATHS.EVENTS_LANGUAGES
          }),
          sessionTypes: await getByPathAndParams({
            path: SESSION_PATHS.TYPES
          }),
          priceType: await getByPathAndParams({
            path: EVENTS_PATHS.PRICE_TYPES
          }),
          currencyType: await getByPathAndParams({
            path: LOCALES_PATHS.CURRENCIES
          }),
          activityStatuses: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_STATUSES
          }),
          activityPriorities: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_PRIORITY
          }),
          activityTaskCategories: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_TASK_CATEGORIES
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: newPath,
      element: <CreateEvent />,
      loader: routeCache.createLoader(async () => ({
        type: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_TYPES
        }),
        status: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_STATUSES
        }),
        nativeLanguage: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_LANGUAGES
        })
      }))
    },
    {
      path: eventsDetailsSlug,
      element: null,
      meta: { breadcrumb: (params, event) => event?.name },
      loader: async ({ params: { eventId } }) => ({
        event: await getByPathAndParams({
          path: EVENTS_PATHS.GET_DETAILS,
          pathVariables: { id: eventId }
        })
      }),
      children: [
        {
          path: rootPath,
          element: <Navigate to={eventsPath} search={{ activeTab: TAB_KEYS.OVERVIEW }} />
        },
        {
          path: eventsSessionDetailsSlug,
          meta: {
            breadcrumb: (params, session) => {
              return session?.name;
            }
          },
          element: <Session />,
          loader: async ({ params: { id } }) => ({
            sessionDetails: await getByPathAndParams({
              path: SESSION_PATHS.GET_DETAILS,
              pathVariables: { id }
            })
          })
        },
        {
          path: eventsParticipantDetailsSlug,
          element: <Details />,
          loader: async ({ params: { id } }) => ({
            participantDetails: await getByPathAndParams({
              path: PARTICIPANTS_PATHS.GET_DETAILS,
              pathVariables: { id }
            }),
            participantData: await getByPathAndParams({
              path: PARTICIPANTS_PATHS.PARTICIPANT_FORM
            }),
            participantStatuses: await getByPathAndParams({
              path: PARTICIPANTS_PATHS.PARTICIPANT_STATUSES
            })
          }),
          meta: {
            breadcrumb: (params, participant) => {
              return `${participant?.personDto?.firstName} ${participant?.personDto?.lastName}`;
            }
          }
        },
        {
          path: eventsDetailsHotelSlug,
          element: <Hotels />,
          loader: async ({ params: { id } }) => {
            return {
              hotelDetails: await getByPathAndParams({
                path: EVENTS_PATHS.GET_CONTINGENTS_HOTEL,
                pathVariables: { id }
              })
            };
          },
          meta: {
            breadcrumb: (params, hotelDetails) => {
              return hotelDetails?.hotel?.name;
            }
          }
        }
        // {
        //   path: eventsHotelBookingSlug,
        //   element: <BookingCalendar />,
        //   loader: async ({ params: { id } }) => ({
        //     hotel: await getByPathAndParams({
        //       path: EVENTS_PATHS.GET_CONTINGENTS_HOTEL,
        //       pathVariables: { id }
        //     })
        //   }),
        //   meta: {
        //     breadcrumb: (params, hotel) => {
        //       return hotel?.name;
        //     }
        //   }
        // }
      ]
    },
    {
      element: <Navigate to={eventsPath} />
    }
  ]
});

export default EventRoute;
