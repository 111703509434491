import Localize from 'react-intl-universal';

import moment from 'moment';
import * as yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export default () => {
  return yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.IltSessionName')),
    course: yup
      .object()
      .shape({
        id: yup.string().nullable(true).required(Localize.get('ValidationMessages.Course'))
      })
      .required(Localize.get('ValidationMessages.Course'))
      .nullable(true),
    iltModule: yup
      .object()
      .shape({
        id: yup.string().nullable(true).required(Localize.get('ValidationMessages.IltModule'))
      })
      .required(Localize.get('ValidationMessages.IltModule'))
      .nullable(true),
    registrationBeforeStart: yup
      .number()
      .min(1, Localize.get('ValidationMessages.MinNumber'))
      .required(Localize.get('ValidationMessages.RegistrationDeadlineValueRequired')),
    cancellationBeforeStart: yup
      .number()
      .min(1, Localize.get('ValidationMessages.MinNumber'))
      .required(Localize.get('ValidationMessages.CancellationDeadlineValueRequired')),
    automaticCancellationBeforeStart: yup
      .number()
      .min(1, Localize.get('ValidationMessages.MinNumber'))
      .required(Localize.get('ValidationMessages.AutomaticCancellationDeadlineValueRequired')),
    minSeats: yup
      .number()
      .min(0, Localize.get('ValidationMessages.MinNumber0'))
      .max(yup.ref('maxSeats'), Localize.get('ValidationMessages.MaxMinSeats')),
    maxSeats: yup
      .number()
      .min(1, Localize.get('ValidationMessages.MinNumber'))
      .when('minSeats', {
        is: (minSeats) => minSeats,
        then: yup.number().min(yup.ref('minSeats'), Localize.get('ValidationMessages.MinMaxSeats')),
        otherwise: yup.number()
      })
      .required(Localize.get('ValidationMessages.MaxSeats')),
    sessionDayDto: yup.object().shape({
      name: yup.string().required(Localize.get('ValidationMessages.IltSessionDayName')),
      instructor: yup
        .array()
        .nullable(true)
        .required(Localize.get('ValidationMessages.Instructor'))
        .min(1, Localize.get('ValidationMessages.Instructor')),
      startTime: yup
        .date()
        .required(Localize.get('ValidationMessages.StartTimeRequired'))
        .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
        .nullable(),
      endTime: yup
        .date()
        .required(Localize.get('ValidationMessages.StartTimeRequired'))
        .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
        .test(
          'is-after-start',
          Localize.get('ValidationMessages.EndTimeAfterStart'),
          function (value, e) {
            const { startTime } = e?.parent;
            const endTime = moment(value).toDate();
            const startTimeParsed = moment(startTime).toDate();

            return moment(endTime).isAfter(startTimeParsed);
          }
        )
        .nullable(),
      dayDate: yup
        .date()
        .required(Localize.get('ValidationMessages.DateInvalid'))
        .typeError(Localize.get('ValidationMessages.DateInvalid')),
      reminderValue: yup
        .number()
        .min(1, Localize.get('ValidationMessages.MinNumber'))
        .required(Localize.get('ValidationMessages.ReminderValueRequired'))
        .test('is-required', Localize.get('ValidationMessages.NumberRequired'), (value, e) => {
          return e.parent.sendReminder ? !!value : true;
        })
    })
  });
};
