import ExternalLogin from '@pages/Login/ExternalLogin';
import Login from '@pages/Login/Login';

import { catchPath, rootPath } from './Routes';

const publicRoutes = [
  {
    children: [
      {
        path: rootPath,
        element: <Login />
      },
      {
        path: '/saml-login/:externalToken',
        element: <ExternalLogin />
      },
      {
        path: catchPath,
        element: <Login />
      }
    ]
  }
];

export default publicRoutes;
