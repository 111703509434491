const dialogColumns = [
  {
    field: 'id',
    headerName: 'Labels.ID',
    width: 100,
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    sortable: false
  },
  {
    field: 'subject',
    headerName: 'Labels.Name',
    width: 350,
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    sortable: false
  },
  {
    field: 'processor',
    headerName: 'Labels.Processor',
    width: 350,
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    sortable: false,
    renderCell: (params) => {
      const processor = params.row.taskDto?.processor;
      const fullName = processor ? `${processor.firstName} ${processor.lastName}` : '';
      return fullName;
    }
  },
  {
    field: 'status',
    headerName: 'Labels.Status',
    width: 350,
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    sortable: false,
    renderCell: (params) => {
      return params.row.status?.value || '';
    }
  }
];

export default dialogColumns;
