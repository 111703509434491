import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import { TABLE_OPTIONS } from '@config/network';
import { selectDetails as selectBusinessPartnerDetails } from '@pages/BusinessPartners/businessPartnersSlice';
import {
  fetchParticipations,
  resetState,
  selectFilter,
  selectIsLoading,
  selectList,
  selectSelectionModel,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  setSelectionModel
} from '@pages/BusinessPartners/components/Tabs/ParticipationTable/participationTableSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const ParticipationTable = ({ columns = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const details = useSelector(selectBusinessPartnerDetails);

  useEffect(() => {
    if (details?.organizationPerson?.id) {
      dispatch(fetchParticipations({ entityId: details?.organizationPerson?.id, filter }));
    }
  }, [
    details?.organizationPerson?.id,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.size
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  return (
    <>
      <div className={classes.root}>
        <TabContent className={classes.content}>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Participations')}
            </Typography>
            <Toolbar className={classes.toolbar}></Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={data}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName)
                }))
              ]}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{
                Toolbar: () => <TableToolbar />
              }}
              onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
              onPageSizeChange={(value) =>
                dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
              }
            />
          </TabContainer>
        </TabContent>
      </div>
    </>
  );
};

export default ParticipationTable;
