import React from 'react';
import Localize from 'react-intl-universal';
import { Link, useMatch, useRouter } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { TAB_KEYS } from '@common/Constants';
import DetailsContainer from '@components/DetailsContainer/DetailsContainer';
import DetailsToolbar from '@components/DetailsToolbar';
import Header from '@components/Header';
import { openSidebar } from '@components/RightSidebar/rightSidebarSlice';
import { iltSessionsDetailsSessionDaySlug, iltSessionsPath } from '@navigation/routes/Routes';
import { selectSessionDayDetails } from '@pages/IltSession/iltSessionSlice';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.action.active,
  fontWeight: '600',
  fontSize: '1rem',
  lineHeight: 1.5,
  letterSpacing: '0.00938em'
}));

const SessionDayDetails = ({ entityId = '', children }) => {
  const dispatch = useDispatch();
  const { state } = useRouter();
  const details = useSelector(selectSessionDayDetails);
  const {
    data: {
      session: { data: session }
    }
  } = useMatch();

  const toolbarOptions = [
    {
      icon: 'menu_open',
      label: 'menu',
      tooltip: Localize.get('Labels.ViewSidebar'),
      isDisabled: !entityId,
      onClick: () => dispatch(openSidebar())
    }
  ];

  return (
    <>
      <Header display="flex" flexDirection="row">
        <Box display="flex" flexGrow="1" textAlign="right" justifyContent="flex-end">
          <Breadcrumbs aria-label="breadcrumb">
            {state.matches
              .filter((match) => match.route.meta?.breadcrumb)
              .map((match) =>
                match.route.path !== iltSessionsDetailsSessionDaySlug ? (
                  <StyledLink
                    key={match.pathname}
                    to={iltSessionsPath}
                    search={{ activeTab: TAB_KEYS.SESSION_DAY }}
                  >
                    {match.route.meta.breadcrumb(match.params, session)}
                  </StyledLink>
                ) : (
                  <Typography key={match.pathname} sx={{ fontWeight: 600, color: 'action.active' }}>
                    {match.route.meta.breadcrumb(match.params, details)}
                  </Typography>
                )
              )}
          </Breadcrumbs>
        </Box>

        <Box flexGrow="1" flexDirection="row" justifyContent="flex-end">
          <DetailsToolbar options={toolbarOptions} />
        </Box>
      </Header>

      <DetailsContainer>{children}</DetailsContainer>
    </>
  );
};

export default SessionDayDetails;
