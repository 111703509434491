import Localize from 'react-intl-universal';

import * as yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export default () => {
  return yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.IltSessionName')),
    minSeats: yup
      .number()
      .min(0, Localize.get('ValidationMessages.MinNumber0'))
      .max(yup.ref('maxSeats'), Localize.get('ValidationMessages.MaxMinSeats')),
    maxSeats: yup
      .number()
      .min(1, Localize.get('ValidationMessages.MinNumber'))
      .when('minSeats', {
        is: (minSeats) => minSeats,
        then: yup.number().min(yup.ref('minSeats'), Localize.get('ValidationMessages.MinMaxSeats')),
        otherwise: yup.number()
      })
      .required(Localize.get('ValidationMessages.MaxSeats')),
    registrationBeforeStart: yup.number().when('hasRegistrationPeriod', {
      is: (hasRegistrationPeriod) => Boolean(hasRegistrationPeriod),
      then: yup
        .number()
        .min(1, Localize.get('ValidationMessages.MinNumber'))
        .required(Localize.get('ValidationMessages.RegistrationDeadlineValueRequired')),
      otherwise: yup.number().nullable()
    }),
    cancellationBeforeStart: yup.number().when('hasCancellationDeadline', {
      is: (hasCancellationDeadline) => Boolean(hasCancellationDeadline),
      then: yup
        .number()
        .min(1, Localize.get('ValidationMessages.MinNumber'))
        .required(Localize.get('ValidationMessages.CancellationDeadlineValueRequired')),
      otherwise: yup.number().nullable()
    }),
    automaticCancellationBeforeStart: yup.number().when('hasAutomaticCancellation', {
      is: (hasAutomaticCancellation) => Boolean(hasAutomaticCancellation),
      then: yup
        .number()
        .min(1, Localize.get('ValidationMessages.MinNumber'))
        .required(Localize.get('ValidationMessages.AutomaticCancellationDeadlineValueRequired')),
      otherwise: yup.number().nullable()
    })
  });
};
