import { WORK_EMAIL_TYPE_ID } from '@common/Constants';
import { address } from '@components/FormArray';

export default {
  personDto: {
    firstName: '',
    lastName: '',
    birthDate: '',
    birthPlace: '',
    id: '',
    title: { id: 0, value: '' },
    name: '',
    website: null,
    gender: null
  },
  status: 3, // Reserved
  iltSession: { id: '', name: '' },
  allergies: [],
  nativeLanguage: '',
  isVegetarian: false,
  hotelRequest: false,
  isVegan: false,
  isHandicap: false,
  remarks: '',
  cancellationReason: '',
  result: '',
  invoiceCompany: { id: null, name: '' },
  company: { id: '', name: '' },
  biography: '',
  addressDtos: [address],
  emailDtos: [{ type: { id: WORK_EMAIL_TYPE_ID }, email: '' }]
};
