import { omit } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  dateInitFormats,
  NAVIGATE_TOASTER_TIMEOUT,
  TAB_KEYS
} from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { successMessageFormatter } from '@common/helpers/MessageFormatter';
import { EVENTS_PATHS, SESSION_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { setDetails, setIsDetailsLoading } from '@pages/Events/eventsSlice';
import { getByPathAndParams, postByPathAndData, patchByPathAndData } from '@services/BaseApi';
export const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  filter: {
    search: '',
    match: '',
    searchValue: '',
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0]
  },
  totalElements: 0,
  selectionModel: [],
  isDetailsLoading: false,
  sessionDetails: {}
};

export const EVENTS_SESSIONS_SLICE = 'eventsSessions';

export const fetchSessions = createAsyncThunk(
  `${EVENTS_SESSIONS_SLICE}/fetchAll`,
  ({ entityId, filter: { page, size } }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: EVENTS_PATHS.GET_SESSIONS,
      pathVariables: { id: entityId },
      params: { page, size }
    })
      .then((response) => ({ ...response?.data, content: response?.data?.content }))
      .catch((error) => rejectWithValue(error.response));
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

// Session
export const createSession = createAsyncThunk(
  `${EVENTS_SESSIONS_SLICE}/create`,
  (postData, { rejectWithValue, dispatch, getState }) => {
    const state = getState();
    return postByPathAndData({
      path: SESSION_PATHS.POST,
      data: {
        ...omit(postData, 'eventName', 'eventStartDateTime', 'eventEndDateTime', 'venues'),
        endTime: moment(postData.endTime).format(dateInitFormats.time),
        startTime: moment(postData.startTime).format(dateInitFormats.time),
        timeBlockDate: moment(postData?.timeBlockDate).format(moment.HTML5_FMT.DATE),
        duration: null,
        minParticipants: postData.minParticipants !== '' ? postData.minParticipants : 0,
        optParticipants: postData.optParticipants !== '' ? postData.optParticipants : 0
      }
    })
      .then(async (response) => {
        const { totalElements = 0 } = await dispatch(
          fetchSessions({
            entityId: state.EVENTS_SLICE.selectedId,
            filter: state.EVENTS_SESSIONS_SLICE.filter
          })
        ).unwrap();

        dispatch(setDetails({ counts: { [TAB_KEYS.SESSION]: totalElements } }));

        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.SESSION, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const saveSession = createAsyncThunk(
  `${EVENTS_SESSIONS_SLICE}/edit`,
  (patchData, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    dispatch(setIsDetailsLoading(true));

    if (!Object.keys(patchData).includes('timeBlockDate')) {
      delete patchData.dayScheduleId;
    }
    const data = Object.keys(patchData).reduce((objValue, currentParameter) => {
      switch (currentParameter) {
        case 'type':
          objValue.typeId = patchData?.type?.id;
          break;
        case 'timeTo':
          objValue.endTime = patchData.timeTo
            ? moment(patchData.timeTo).format(dateInitFormats.time)
            : '';
          break;
        case 'timeFrom':
          objValue.startTime = patchData.timeFrom
            ? moment(patchData.timeFrom).format(dateInitFormats.time)
            : '';
          break;
        case 'timeBlockDate':
          objValue.timeBlockDate = patchData?.timeBlockDate
            ? moment(patchData?.timeBlockDate).format(moment.HTML5_FMT.DATE)
            : '';
          break;
        case 'minParticipants':
          objValue.minParticipants =
            patchData.minParticipants !== '' ? patchData.minParticipants : 0;
          break;
        case 'optParticipants':
          objValue.optParticipants =
            patchData.optParticipants !== '' ? patchData.optParticipants : 0;
          break;
        default:
          objValue[currentParameter] = patchData[currentParameter];
          break;
      }

      return objValue;
    }, {});

    return patchByPathAndData({
      path: SESSION_PATHS.PATCH,
      pathVariables: { id: state?.EVENTS_SESSIONS_SLICE?.sessionDetails?.id },
      data: data
    })
      .then((response) => {
        dispatch(setIsDetailsLoading(false));

        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.SESSION, ACTION_MODES.Edit),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        return response?.data;
      })
      .catch((error) => rejectWithValue(error?.response));
  }
);

export const eventsSessionsSlice = createSlice({
  name: EVENTS_SESSIONS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setSelectionModel: (state, { payload }) => {
      state.selectionModel = [...payload];
    },
    setFilterUpdate: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        page: payload?.value?.page ?? state.filter.page,
        size: payload?.value?.size ?? state.filter.size,
        search: payload?.value?.search ?? state.filter.search,
        match: payload?.value?.match ?? state.filter.match,
        searchValue: payload?.value?.searchValue ?? state.filter.searchValue
      };
    },
    setSessionDetails: (state, { payload }) => {
      state.sessionDetails = deepMerge(state.sessionDetails, payload);

      // Need to get time data in order to convert it to date object since time component expects it.
      const startTime = state.sessionDetails && state.sessionDetails?.startTime.split(':');
      const endTime = state.sessionDetails && state.sessionDetails?.endTime.split(':');

      state.sessionDetails = {
        ...state.sessionDetails,
        timeFrom: new Date(0, 0, 0, startTime[0], startTime[1], 0),
        timeTo: new Date(0, 0, 0, endTime[0], endTime[1], 0)
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = payload?.content;
        state.filter = {
          ...state.filter,
          page: payload?.pageable?.pageNumber ?? state.filter.page,
          size: payload?.pageable?.pageSize ?? state.filter.size
        };
        state.totalElements = payload?.totalElements;
      })
      .addCase(fetchSessions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSessions.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
        state.isError = true;
        state.totalElements = 0;
      })
      .addCase(saveSession.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.sessionDetails = deepMerge(state.sessionDetails, action.meta.arg);
        if (action.meta.arg.timeTo) {
          state.sessionDetails.timeTo = action.meta.arg.timeTo;
        }
        if (action.meta.arg.timeFrom) {
          state.sessionDetails.timeFrom = action.meta.arg.timeFrom;
        }
        if (action.meta.arg.timeBlockDate) {
          state.sessionDetails.timeBlockDate = action.meta.arg.timeBlockDate;
          state.sessionDetails.dayScheduleId = '';
        }
      })
      .addCase(saveSession.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      .addCase(saveSession.pending, (state) => {
        state.isDetailsLoading = true;
      });
  }
});

// { isLoading, data, filter, totalElements, selectionModel }
export const selectIsLoading = (state) => state.EVENTS_SESSIONS_SLICE.isLoading;
export const selectData = (state) => state.EVENTS_SESSIONS_SLICE.data;
export const selectFilter = (state) => state.EVENTS_SESSIONS_SLICE.filter;
export const selectTotalElements = (state) => state.EVENTS_SESSIONS_SLICE.totalElements;
export const selectSelectionModel = (state) => state.EVENTS_SESSIONS_SLICE.selectionModel;
export const selectSessionDetails = (state) => state.EVENTS_SESSIONS_SLICE.sessionDetails;

const { actions, reducer } = eventsSessionsSlice;

export const { resetState, setLoading, setSelectionModel, setFilterUpdate, setSessionDetails } =
  actions;

export default reducer;
