import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import { checkChangedFields } from '@common/helpers/helpers';
import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { resetFilterState } from '@components/FilterDialog/filtersSlice';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import {
  deleteEvent,
  resetState,
  selectIsDetailsLoading as selectEventIsDetailsLoading
} from '@pages/Events/eventsSlice';

import SessionDetails from './Details/SessionDetails';
import { selectSessionDetails, setSessionDetails, saveSession } from './sessionsSlice';
import Sidebar from './Sidebar';
import SessionTabs from './Tabs/SessionTabs';

const Session = () => {
  const dispatch = useDispatch();
  const {
    data: {
      sessionDetails: { data: sessionDetails }
    }
  } = useMatch();

  const isOpen = useSelector(selectIsOpen);
  const details = useSelector(selectSessionDetails);

  const isDetailsLoading = useSelector(selectEventIsDetailsLoading);
  // Set session details to store (TODO: Figure out how to do this from route resolver)
  useEffect(
    () =>
      sessionDetails &&
      dispatch(
        setSessionDetails({
          ...sessionDetails,
          minParticipants: sessionDetails.minParticipants || '',
          optParticipants: sessionDetails.optParticipants || ''
        })
      ),
    [sessionDetails?.id]
  );

  useEffect(() => {
    return () => {
      dispatch(resetState());
      dispatch(resetFilterState());
    };
  }, []);

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('Events.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteEvent(details?.id))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  const onSave = (values) => {
    const changedFields = checkChangedFields({ ...details, dayScheduleId: null }, values);

    return dispatch(saveSession({ ...changedFields }))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.SESSION
        });
      });
  };

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen></LeftContainer>

      <RightContainer open={isOpen} isFullScreen>
        <SessionDetails entityId={details?.id} headerTitle={details?.name}>
          <SessionTabs
            entityId={details?.id}
            entityType={EntityTypes.SESSION}
            details={details}
            setDetails={setSessionDetails}
          />
        </SessionDetails>
      </RightContainer>

      <Sidebar
        isDetailsLoading={isDetailsLoading}
        data={details}
        onSave={onSave}
        onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default Session;
