import { useState } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { darken } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles, styled } from '@mui/styles';
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import GridToolbarSearch from '@components/GridToolbarSearch/GridToolbarSearch';
import { TABLE_OPTIONS } from '@config/network';
import {
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  setFilterParams
} from '@pages/Courses/coursesSlice';
import renderDescription, {
  StyledDescriptionContainer
} from '@pages/Courses/util/renderDescription';

import ModuleRow from '../ModuleRow/ModuleRow';

const SHADER = 0.2;

export const StyledActive = styled('span')(({ theme }) => ({
  color: darken(theme.palette.success.main, SHADER)
}));

export const StyledInactive = styled('span')(({ theme }) => ({
  color: darken(theme.palette.error.main, SHADER)
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  container: {
    height: `calc(100vh - ${theme.spacing(16.25)})`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`
    }
  }
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%'
  },
  '& .MuiDataGrid-columnHeaders': {
    display: 'none !important'
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0!important',
    height: `calc(100vh - ${theme.spacing(36.25)}) !important`
  }
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <Table>
      <TableBody>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell sx={{ '& > *': { borderBottom: 'unset' }, width: '3%' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell
            align="left"
            sx={{
              width: '29%',
              minWidth: '29%',
              maxWidth: '29%',
              verticalAlign: 'middle !important',
              overflowX: 'hidden',
              overflowY: 'auto'
            }}
          >
            <StyledDescriptionContainer width={'19.75rem'}>
              <Tooltip arrow title={renderDescription(row.name, true)}>
                {renderDescription(row.name)}
              </Tooltip>
            </StyledDescriptionContainer>
          </TableCell>
          <TableCell
            sx={{
              width: '30%',
              maxWidth: '30%',
              minWidth: '30%',
              overflowX: 'hidden',
              overflowY: 'auto',
              verticalAlign: 'middle !important'
            }}
          >
            <StyledDescriptionContainer width={'24rem'}>
              <Tooltip arrow title={renderDescription(row.description, true)}>
                {renderDescription(row.description)}
              </Tooltip>
            </StyledDescriptionContainer>
          </TableCell>
          <TableCell align="left" sx={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}>
            {row.active ? (
              <StyledActive>{Localize.get('Labels.Yes')}</StyledActive>
            ) : (
              <StyledInactive>{Localize.get('Labels.No')}</StyledInactive>
            )}
          </TableCell>
          <TableCell align="left">{row.code}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Typography m="6px" variant="h6" component="div" fontWeight={500}>
                {Localize.get('Courses.Modules')}
              </Typography>

              <Table>
                <TableHead
                  sx={{ backgroundColor: (theme) => theme.palette.calendar.bigCalOfRange }}
                >
                  <TableRow sx={{ display: 'table', width: '100%' }}>
                    <TableCell sx={{ width: '2.95%' }}></TableCell>
                    <TableCell align="left" sx={{ width: '29%', fontWeight: 900 }}>
                      {Localize.get('Labels.ModuleName')}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '29.1%', fontWeight: 900 }}>
                      {Localize.get('Labels.Description')}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '29.1%', fontWeight: 900 }}>
                      {Localize.get('Labels.ModuleType')}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '10%', fontWeight: 900 }}>
                      {Localize.get('Labels.Active')}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              {row?.moduleDtos?.map((module) => (
                <ModuleRow key={module?.id} module={module} row={row}></ModuleRow>
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

const Panel = () => {
  const dispatch = useDispatch();

  return (
    <Table>
      <TableHead sx={{ display: 'table', width: '100%' }}>
        <TableRow sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[400]}` }}>
          <TableCell sx={{ p: 1, borderBottom: 'none' }}>
            <GridToolbarFilterButton data-test-id="filters-button" />
            <GridToolbarSearch
              onChange={(value) => dispatch(setFilterParams({ key: 'search', value }))}
            />
          </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: (theme) => theme.palette.calendar.bigCalOfRange }}>
          <TableCell align="left" sx={{ width: '35%', fontWeight: 900, pl: '80px' }}>
            {Localize.get('Labels.CourseName')}
          </TableCell>
          <TableCell align="left" sx={{ width: '28%', fontWeight: 900 }}>
            {Localize.get('Labels.Description')}
          </TableCell>
          <TableCell align="left" sx={{ width: '10%', fontWeight: 900 }}>
            {Localize.get('Labels.Active')}
          </TableCell>
          <TableCell align="left" sx={{ width: '30%', fontWeight: 900 }}>
            {Localize.get('Labels.Code')}
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

const LoadingOverlay = () => (
  <Box
    sx={{
      width: '100%',
      top: (theme) => `-${theme.spacing(7)}`,
      height: (theme) => `calc(100vh - ${theme.spacing(30)})`,
      position: 'absolute',
      background: (theme) =>
        theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255,255,255, 0.2)',
      zIndex: 999,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <CircularProgress />
  </Box>
);

export default function CoursesTable({ data = [], isLoading = false }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const filter = useSelector(selectFilter);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);

  return (
    <div className={classes.root}>
      <Paper className={classes.content}>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Courses.AllCourses')}
          </Typography>
        </div>
        <div className={classes.container}>
          <StyledDataGrid
            disableVirtualization
            initialState={{
              pagination: {
                pageSize: totalPages,
                rowCount: totalElements,
                page: filter?.page
              }
            }}
            loading={isLoading}
            rows={data}
            columns={[]}
            localeText={getLocalesText(Localize)}
            pagination
            paginationMode="server"
            rowCount={totalPages}
            disableColumnMenu
            rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
            disableSelectionOnClick
            components={{
              LoadingOverlay,
              Row,
              Panel,
              Pagination: () => (
                <Pagination
                  color="primary"
                  boundaryCount={0}
                  siblingCount={1}
                  showFirstButton
                  showLastButton
                  shape="rounded"
                  variant="outlined"
                  count={totalPages}
                  disabled={isLoading}
                  page={filter?.page + 1}
                  onChange={(event, page) => {
                    dispatch(setFilterParams({ key: 'page', value: page - 1 }));
                  }}
                />
              )
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
