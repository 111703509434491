import { Navigate } from 'react-location';

import { BUSINESS_PARTNERS_PATHS, SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import BusinessPartners, { CreateBusinessPartner } from '@pages/BusinessPartners';
import { getByPathAndParams } from '@services/BaseApi';

import { businessPartnersPath, newPath, rootPath } from '../Routes';

const BusinessPartnerRoute = (routeCache) => ({
  path: businessPartnersPath,
  loader: routeCache.createLoader(
    async () => ({
      roleTypes: await getByPathAndParams({
        path: BUSINESS_PARTNERS_PATHS.BUSINESS_PARTNER_ROLE_TYPES
      }),
      businessPartnerTypes: await getByPathAndParams({
        path: BUSINESS_PARTNERS_PATHS.BUSINESS_PARTNER_TYPES
      }),
      supplierTypes: await getByPathAndParams({
        path: BUSINESS_PARTNERS_PATHS.SUPPLIER_TYPES
      }),
      abcClassification: await getByPathAndParams({
        path: BUSINESS_PARTNERS_PATHS.ABC_CLASSIFICATION
      })
    }),
    { policy: 'cache-first' }
  ),
  children: [
    {
      path: rootPath,
      element: <BusinessPartners />,
      loader: routeCache.createLoader(
        () => ({
          config: getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.BUSINESS_PARTNER }
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: newPath,
      element: <CreateBusinessPartner />
    },
    {
      element: <Navigate to={businessPartnersPath} />
    }
  ]
});

export default BusinessPartnerRoute;
