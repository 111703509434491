import Localize from 'react-intl-universal';

import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import initialValues from '@pages/Activities/util/initialValues';

export const formatActivitiesForSave = (postData) => {
  const { startDateTime, endDateTime } = postData;
  const formatted = {
    startDateTime: dateToFormat(startDateTime, dateInitFormats.dateTime),
    endDateTime: dateToFormat(endDateTime, dateInitFormats.dateTime)
  };
  return {
    startDateTime: formatted.startDateTime.split('.')[0],
    endDateTime: formatted.endDateTime.split('.')[0],
    subject: postData?.subject,
    statusId: postData?.status,
    priorityId: postData?.priority,
    typeId: postData?.type,
    appointmentDto: {
      location: postData?.appointmentDto?.location,
      appointmentCategoryId: postData?.appointmentDto?.appointmentCategory?.id
    },
    taskDto: {
      completion: !postData?.taskDto?.completion
        ? initialValues.taskDto.completion
        : postData?.taskDto?.completion,
      processorId: postData?.taskDto?.processor?.id,
      taskCategoryId: postData?.taskDto?.taskCategory?.id,
      eventId: null
    },
    ownerId: postData?.owner?.id,
    accountId: postData?.account?.organizationPersonId,
    contactId: postData?.contact?.personTypeId
  };
};

export const formatActivityUpdate = (responseData) => {
  return {
    subject: responseData?.subject,
    startDateTime: responseData?.startDateTime,
    endDateTime: responseData?.endDateTime,
    taskDto: {
      completion: !responseData?.taskDto?.completion
        ? initialValues.taskDto.completion
        : parseInt(responseData?.taskDto?.completion),
      processorId: responseData?.taskDto?.processor?.id,
      taskCategoryId: responseData?.taskDto?.taskCategory?.id || null,
      eventId: null
    },
    statusId: responseData?.status?.id,
    priorityId: responseData?.priority?.id,
    ownerId: responseData?.owner?.id,
    contactId: responseData?.contact?.id,
    accountId: responseData?.account?.organizationPersonId,
    appointmentDto: {
      appointmentCategoryId: responseData?.appointmentDto?.appointmentCategory?.id,
      location: responseData?.appointmentDto?.location
    },
    typeId: responseData?.type?.id
  };
};

export const getActivitiesOptions = (activitiesOption) => {
  return activitiesOption?.map((option) => {
    if (option?.label) {
      return {
        ...option,
        key: option?.translateKey ? Localize.get(option.key) : option.key,
        label: Localize.get(option.label)
      };
    }
    return Localize.get(option);
  });
};
