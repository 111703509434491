import React from 'react';
import { Provider } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';

import persistReducer from '@common/storage/persistSlice';
import attachmentsReducer from '@components/AttachmentsTable/attachmentsTableSlice';
import confirmDialogReducer from '@components/ConfirmDialog/confirmDialogSlice';
import detailsToolbarReducer from '@components/DetailsToolbar/detailsToolbarSlice';
import dragAndDropDialog from '@components/DragAndDropCalendar/dragAndDropDialogSlice';
import filterDialogReducer from '@components/FilterDialog/filtersSlice';
import navbarReducer from '@components/NavBar/navbarSlice';
import notesReducer from '@components/NotesTable/notesTableSlice';
import sidebarReducer from '@components/RightSidebar/rightSidebarSlice';
import snackbarReducer from '@components/Snackbar/snackbarSlice';
import activitiesReducer from '@pages/Activities/activitiesSlice';
import businessPartnersReducer from '@pages/BusinessPartners/businessPartnersSlice';
import businessPartnerContactsReducer from '@pages/BusinessPartners/components/Tabs/ContactTable/contactTableSlice';
import individualCustomerParticipationsReducer from '@pages/BusinessPartners/components/Tabs/ParticipationTable/participationTableSlice';
import calendarReducer from '@pages/Calendar/calendarSlice';
import contactParticipationsReducer from '@pages/Contacts/components/Tabs/ParticipationTable/participationTableSlice';
import contactsReducer from '@pages/Contacts/contactsSlice';
import coursesReducer from '@pages/Courses/coursesSlice';
import employeeParticipationsReducer from '@pages/Employees/components/Tabs/ParticipationTable/participationTableSlice';
import employeesReducer from '@pages/Employees/employeesSlice';
import eventHotelsReducer from '@pages/Events/components/Tabs/Hotel/hotelTableSlice';
import eventParticipants from '@pages/Events/components/Tabs/ParticipantsTable/participantTableSlice';
import eventPriceReducer from '@pages/Events/components/Tabs/Price/priceTableSlice';
import eventsSessionsReducer from '@pages/Events/components/Tabs/SessionsTable/sessionsSlice';
import eventTaskReducer from '@pages/Events/components/Tabs/Tasks/tasksTableSlice';
import eventsReducer from '@pages/Events/eventsSlice';
import iltSessionAttachmentsReducer from '@pages/IltSession/components/Tabs/AttachmentsTable/attachmentsTableSlice';
import iltSessionHotelsReducer from '@pages/IltSession/components/Tabs/Hotel/hotelTableSlice';
import iltSessionParticipantsReducer from '@pages/IltSession/components/Tabs/ParticipantsTable/participantTableSlice';
import iltSessionPricesReducer from '@pages/IltSession/components/Tabs/Price/priceTableSlice';
import sessionDaysReducer from '@pages/IltSession/components/Tabs/SessionDays/sessionDaysSlice';
import sessionRoomsReducer from '@pages/IltSession/components/Tabs/SessionRooms/sessionRoomsSlice';
import iltSessionTasksReducer from '@pages/IltSession/components/Tabs/Tasks/tasksTableSlice';
import iltSessionTeamReducer from '@pages/IltSession/components/Tabs/Team/teamTableSlice';
import iltSessionWaitingListReducer from '@pages/IltSession/components/Tabs/WaitingList/waitingListTableSlice';
import IltSessionReducer from '@pages/IltSession/iltSessionSlice';
import bookingReducer from '@pages/IltSession/pages/Booking/bookingSlice';
import locationAccommodationReducer from '@pages/Locations/components/Tabs/AccommodationTable/accommodationTableSlice';
import locationContactsReducer from '@pages/Locations/components/Tabs/ContactTable/contactTableSlice';
import locationContingentsReducer from '@pages/Locations/components/Tabs/ContingentTable/contingentTableSlice';
import locationHotelBookingsReducer from '@pages/Locations/components/Tabs/HotelBooking/hotelBookingTableSlice';
import locationMeetingRoomsReducer from '@pages/Locations/components/Tabs/MeetingRoomTable/meetingRoomTableSlice';
import loginReducer from '@pages/Login/loginSlice';
import meetingRoomsReducer from '@pages/MeetingRooms/meetingRoomsSlice';
import orgStructureReducer from '@pages/OrgStructures/orgStructureSlice';
import participantsSessionsReducer from '@pages/Participants/components/Tabs/SessionsTable/components/sessionsTableSlice';
import participantsReducer from '@pages/Participants/participantSlice';
import resourceReducer from '@pages/Resources/resourcesSlice';
import userReducer from '@pages/Users/usersSlice';

export const store = configureStore({
  reducer: {
    RIGHTSIDEBAR_SLICE: sidebarReducer,

    DETAILS_TOOLBAR_SLICE: detailsToolbarReducer,

    LOGIN_SLICE: loginReducer,

    NAVBAR: navbarReducer,

    SNACKBAR_SLICE: snackbarReducer,

    USER_SLICE: userReducer,

    CALENDAR_SLICE: calendarReducer,

    PERSIST_SLICE: persistReducer,

    CONFIRM_DIALOG: confirmDialogReducer,

    FILTER_DIALOG_SLICE: filterDialogReducer,

    DRAG_AND_DROP_SLICE: dragAndDropDialog,

    BOOKING_SLICE: bookingReducer,

    ACTIVITIES_SLICE: activitiesReducer,

    ORG_STRUCTURE_SLICE: orgStructureReducer,

    MEETING_ROOMS_SLICE: meetingRoomsReducer,

    RESOURCE_SLICE: resourceReducer,

    NOTES_SLICE: notesReducer,

    ATTACHMENTS_SLICE: attachmentsReducer,

    EMPLOYEE_SLICE: employeesReducer,
    EMPLOYEE_PARTICIPATIONS_SLICE: employeeParticipationsReducer,

    COURSES_SLICE: coursesReducer,

    CONTACT_SLICE: contactsReducer,
    CONTACT_PARTICIPATIONS: contactParticipationsReducer,

    BUSINESS_PARTNER_SLICE: businessPartnersReducer,
    INDIVIDUAL_CUSTOMER_PARTICIPATIONS_SLICE: individualCustomerParticipationsReducer,
    BUSINESS_PARTNER_CONTANCT_SLICE: businessPartnerContactsReducer,

    LOCATION_CONTINGENTS_SLICE: locationContingentsReducer,
    LOCATION_HOTEL_BOOKINGS_SLICE: locationHotelBookingsReducer,
    LOCATION_CONTACT_SLICE: locationContactsReducer,
    LOCATION_MEETING_ROOMS_SLICE: locationMeetingRoomsReducer,
    LOCATION_ACCOMMODATION_SLICE: locationAccommodationReducer,

    EVENTS_SLICE: eventsReducer,
    EVENTS_PARTICIPANT_SLICE: eventParticipants,
    EVENTS_HOTELS_SLICE: eventHotelsReducer,
    EVENTS_PRICES_SLICE: eventPriceReducer,
    EVENTS_TASKS_SLICE: eventTaskReducer,
    EVENTS_SESSIONS_SLICE: eventsSessionsReducer,

    ILT_SESSION_SLICE: IltSessionReducer,
    ILT_SESSION_PARTICIPANT_SLICE: iltSessionParticipantsReducer,
    ILT_SESSION_ROOM_SLICE: sessionRoomsReducer,
    SESSION_DAYS_SLICE: sessionDaysReducer,
    ILT_SESSION_TASKS_SLICE: iltSessionTasksReducer,
    ILT_SESSION_HOTELS_SLICE: iltSessionHotelsReducer,
    ILT_SESSION_PRICES_SLICE: iltSessionPricesReducer,
    ILT_SESSION_WAITING_LIST_SLICE: iltSessionWaitingListReducer,
    ILT_ATTACHMENTS_SLICE: iltSessionAttachmentsReducer,
    ILT_SESSION_TEAM_SLICE: iltSessionTeamReducer,

    PARTICIPANTS_SLICE: participantsReducer,
    PARTICIPANTS_SESSIONS_SLICE: participantsSessionsReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

const ReduxProvider = ({ children }) => <Provider store={store}>{children}</Provider>;

export default ReduxProvider;
