import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import moment from 'moment';

import { EVENTS_PATHS, LOCALES_PATHS } from '@common/network/ApiPaths';
import RightSidebar from '@components/RightSidebar';
import sidebar from '@pages/Events/util/fields/sidebar';
import initialValues from '@pages/Events/util/initialValues';
import useSidebarSchema from '@pages/Events/util/schemas/useSidebarSchema';

const Sidebar = ({ isDetailsLoading = false, onSave = () => {}, onDelete = () => {}, data }) => {
  const {
    data: {
      type: { data: type },
      status: { data: status },
      nativeLanguage: { data: nativeLanguage }
    }
  } = useMatch();
  const sidebarSchema = useSidebarSchema();

  const extendedConfig = (setFieldValue, values, setValues) => ({
    ['startDateTime']: {
      onChange: (value) => setFieldValue('startDateTime', value || null),
      disablePast: true,
      ampm: false,
      minDateTime: moment()
    },
    ['endDateTime']: {
      onChange: (value) => setFieldValue('endDateTime', value),
      disablePast: true,
      ampm: false
    },
    ['registrationDueDate']: {
      onChange: (value) => setFieldValue('registrationDueDate', value || null),
      ampm: false,
      disablePast: true
    },
    ['status.id']: {
      options: status,
      onChange: (event) => {
        setFieldValue('status', {
          id: event.target.value,
          value: status.find((s) => s.id === event.target.value).value
        });
      }
    },
    ['type']: {
      path: EVENTS_PATHS.EVENTS_TYPES,
      options: type,
      formItemChangeHandler: (e, data) => {
        setFieldValue('type', data.newValue);
      }
    },
    ['nativeLanguage.id']: {
      options: nativeLanguage,
      onChange: (event) => {
        setFieldValue('nativeLanguage', {
          id: event.target.value,
          value: nativeLanguage.find((s) => s.id === event.target.value).value
        });
      }
    },
    ['isPrivate']: {
      onChange: (e, newValue) =>
        setValues({
          ...values,
          isPrivate: newValue,
          isMeaPublished: newValue ? false : initialValues.isMeaPublished,
          isWebPublished: newValue ? false : initialValues.isWebPublished
        })
    },
    ['venues']: {
      required: Boolean(values.status.id === 1),
      tokenValue: values?.venues?.map((venue) => ({ title: venue.name, id: venue.id })) ?? [],
      onIconClick: (id) => {
        setFieldValue(
          'venues',
          values.venues.filter((item) => item.id !== id)
        );
        setFieldValue('country', '');
        setFieldValue('city', '');
      },
      selectItem: (value = []) => {
        if (!value?.length) {
          setFieldValue('venues', initialValues.venues);
          return;
        }

        setFieldValue('venues', value);
        setFieldValue('country', '');
        setFieldValue('city', '');
      },
      selectionModel: values.venues
    },
    ['isMeaPublished']: {
      disabled: values.isPrivate
    },
    ['isWebPublished']: {
      disabled: values.isPrivate
    },
    ['country']: {
      path: LOCALES_PATHS.COUNTRIES,
      formItemChangeHandler: (e, data) => setFieldValue('country', data?.newValue),
      isVisible: values.status.id !== 1 && Boolean(!values.venues?.length)
    },
    ['city']: {
      isVisible: values.status.id !== 1 && Boolean(!values.venues?.length)
    }
  });

  return (
    <RightSidebar
      config={{ title: Localize.get('Events.Details'), fieldsConfig: sidebar }}
      validationSchema={sidebarSchema}
      extendedConfig={extendedConfig}
      data={data}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
};

export default Sidebar;
