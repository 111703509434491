import { Navigate } from 'react-location';

import Courses from '@pages/Courses';

import { coursesPath, rootPath } from '../Routes';

const CourseRoute = () => ({
  path: coursesPath,
  children: [
    {
      path: rootPath,
      element: <Courses />
    },
    {
      element: <Navigate to={coursesPath} />
    }
  ]
});

export default CourseRoute;
