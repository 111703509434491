import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  colorMode: 'light'
};

export const NAVBAR = 'navbar';

export const navbarSlice = createSlice({
  name: NAVBAR,
  initialState,
  reducers: {
    toggleColorMode: (state) => {
      state.colorMode = state.colorMode === 'light' ? 'dark' : 'light';
    }
  }
});

export const selectColorMode = (state) => state.NAVBAR.colorMode;

const { actions, reducer } = navbarSlice;

export const { toggleColorMode } = actions;

export default reducer;

// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   isLayoutFullScreen: false
// };

// export const detailsToolbarSlice = createSlice({
//   name: 'detailsToolbar',
//   initialState,
//   reducers: {
//     setLayoutFullScreen: (state, action) => {
//       state.isLayoutFullScreen = action.payload;
//     }
//   }
// });

// export const selectIsFullScreen = (state) => state.detailsToolbar.isLayoutFullScreen;

// const { actions, reducer } = detailsToolbarSlice;

// export const { setLayoutFullScreen } = actions;

// export default reducer;
