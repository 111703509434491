import React, { useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate, useSearch } from 'react-location';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';
import { omit } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import CreateFooter from '@components/CreateFooter';
import CreateForm from '@components/CreateForm';
import Header from '@components/Header';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { locationsPath, meetingRoomsPath } from '@navigation/routes/Routes';
import { createMeetingRoom } from '@pages/MeetingRooms/meetingRoomsSlice';
import initialValues from '@pages/MeetingRooms/util/initialValues';
import useCreateSchema from '@pages/MeetingRooms/util/schemas/useCreateSchema';

import getFieldsConfig from './getFieldsConfig';

const NewMeetingRoom = ({ venue = null }) => {
  const {
    data: {
      phoneTypes: { data: phoneTypes }
    }
  } = useMatch();
  const search = useSearch();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createMeetingRoomSchema = useCreateSchema();
  const isNavigatedFromMeetingRoomTabInLocation = Boolean(venue?.id);

  const onSubmit = (meetingRoom, { setSubmitting }) => {
    dispatch(createMeetingRoom({ postData: meetingRoom, isNavigatedFromMeetingRoomTabInLocation }))
      .unwrap()
      .then(() =>
        navigate({
          to: isNavigatedFromMeetingRoomTabInLocation ? locationsPath : meetingRoomsPath,
          search: (previousUrlParams) => {
            const newParams = { ...omit(previousUrlParams, 'venueId', 'mode') };
            return {
              ...newParams,
              activeTab: isNavigatedFromMeetingRoomTabInLocation
                ? TAB_KEYS.MEETING_ROOM
                : TAB_KEYS.OVERVIEW
            };
          }
        })
      )
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'MEETING_ROOM', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const memoizedInitialValues = useMemo(() => {
    return {
      ...initialValues,
      venue: isNavigatedFromMeetingRoomTabInLocation
        ? {
            id: venue?.roles[0]?.venue?.id || venue?.roles[1]?.venue?.id,
            name: venue?.organizationPerson?.name
          }
        : initialValues.venue
    };
  }, [isNavigatedFromMeetingRoomTabInLocation, venue]);

  const onCancelClick = () => {
    if (isNavigatedFromMeetingRoomTabInLocation) {
      navigate({
        to: locationsPath,
        search: (previousUrlParams) => {
          const newParams = { ...omit(previousUrlParams, 'venueId', 'mode') };
          return { ...newParams, activeTab: TAB_KEYS.MEETING_ROOM };
        }
      });
      return;
    }

    navigate({
      to: meetingRoomsPath,
      search: (previousUrlParams) => {
        const newParams = { ...omit(previousUrlParams, 'venueId', 'mode') };
        return { ...newParams, activeTab: TAB_KEYS.OVERVIEW };
      }
    });
  };

  return (
    <>
      {!search?.venueId && <Header>{Localize.get('MeetingRooms.NewMeetingRoom')}</Header>}

      <Formik
        enableReinitialize
        validateOnMount
        initialValues={memoizedInitialValues}
        validationSchema={createMeetingRoomSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, handleSubmit, isValid, setFieldValue, values }) => (
          <>
            {!search?.venueId ? (
              <>
                <CreateForm
                  values={values}
                  fieldsConfig={getFieldsConfig(
                    values,
                    setFieldValue,
                    phoneTypes,
                    isNavigatedFromMeetingRoomTabInLocation
                  )}
                />
                <CreateFooter>
                  <Button
                    data-test-id="cancel-btn"
                    variant="outlined"
                    onClick={onCancelClick}
                    color="error"
                    startIcon={<CancelIcon />}
                  >
                    {Localize.get('Buttons.Cancel')}
                  </Button>

                  <Button
                    data-test-id="save-btn"
                    variant="outlined"
                    disabled={isSubmitting || !isValid}
                    onClick={handleSubmit}
                    startIcon={<SaveIcon />}
                  >
                    {Localize.get('Buttons.Save')}
                  </Button>
                </CreateFooter>
              </>
            ) : (
              <>
                <InlineCreateHeaderContainer>
                  <Button
                    data-test-id="cancel-btn"
                    variant="outlined"
                    onClick={onCancelClick}
                    sx={{ mr: 1 }}
                    color="error"
                    startIcon={<CancelIcon />}
                  >
                    {Localize.get('Buttons.Cancel')}
                  </Button>

                  <Button
                    data-test-id="save-btn"
                    variant="outlined"
                    disabled={isSubmitting || !isValid}
                    onClick={handleSubmit}
                    startIcon={<SaveIcon />}
                  >
                    {Localize.get('Buttons.Save')}
                  </Button>
                </InlineCreateHeaderContainer>

                <CreateForm
                  values={values}
                  fieldsConfig={getFieldsConfig(
                    values,
                    setFieldValue,
                    phoneTypes,
                    isNavigatedFromMeetingRoomTabInLocation
                  )}
                />
              </>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default NewMeetingRoom;
