import Localize from 'react-intl-universal';

import moment from 'moment';

import { FOCUS_FIELD_TIMEOUT, dateInitFormats } from '@common/Constants';
import { isValidDate } from '@common/helpers/dates';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { DIALOG_PATHS } from '@common/network/ApiPaths';
import { WEBINAR_TYPE_ID } from '@pages/IltSession/iltSessionSlice';
import create from '@pages/IltSession/util/fields/create';
import createWebinar from '@pages/IltSession/util/fields/createWebinar';
import initialValues from '@pages/IltSession/util/initialValues';

export const getFieldsConfig = (
  setFieldValue,
  values,
  setValues,
  setFieldTouched,
  filteredTypes,
  status,
  isNavigatedFromCourses,
  errors,
  clearDragAndDrop,
  onSessionDayUpdate,
  onVenueChange,
  onSessionDayDateChange,
  onRoomRemove,
  sessionDayMetrics
) => {
  const isWebinar = values?.type === WEBINAR_TYPE_ID;

  return mapPropsToComponent(isWebinar ? createWebinar : create, {
    ['status']: {
      options: status
    },
    ['type']: {
      options: filteredTypes
    },
    ['publishInCatalog']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.PublishInCatalog')
      }
    },
    ['enableHotelRequest']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.HotelRequestSettings')
      }
    },
    ['venueDtos']: {
      tokenValue: values?.venueDtos?.map((venue) => ({ title: venue.name, id: venue.id })) ?? [],
      onIconClick: (id, venue) => {
        const filtered = values?.venueDtos?.filter((item) => item.id !== id);
        setFieldValue('venueDtos', filtered);

        // Remove room if belongs to removed venue
        if (venue?.id === values?.sessionDayDto?.meetingRoomId?.venue?.id) {
          setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
          onRoomRemove();
        }

        if (!filtered?.length) {
          setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
          clearDragAndDrop();
        }

        onVenueChange(filtered);
      },
      selectItem: (value = []) => {
        if (!value?.length) {
          setFieldValue('venueDtos', initialValues.venueDtos);
          setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
          clearDragAndDrop();
          return;
        }

        // Remove room if belongs to removed venue when venue is removed
        if (value?.length < values?.venueDtos?.length) {
          value?.forEach((venue) => {
            if (venue?.id !== values?.sessionDayDto?.meetingRoomId?.venue?.id) {
              setFieldValue(
                'sessionDayDto.meetingRoomId',
                initialValues.sessionDayDto.meetingRoomId
              );
              clearDragAndDrop();
            }
          });
        }

        setFieldValue('venueDtos', value);
        onVenueChange(value);
      },
      selectionModel: values.venueDtos
    },
    ['sessionDayDto.instructor']: {
      tokenValue:
        values?.sessionDayDto?.instructor?.map((instructor) => ({
          title: `${instructor.firstName} ${instructor.lastName}`,
          id: instructor.id
        })) ?? [],
      onIconClick: (id) => {
        setFieldValue(
          'sessionDayDto.instructor',
          values.sessionDayDto.instructor.filter((instructor) => instructor.id !== id)
        );
      },
      selectItem: (value = []) => {
        setFieldValue('sessionDayDto.instructor', value);
      },
      selectionModel: values.sessionDayDto.instructor
    },
    ['sessionDayDto.meetingRoomId']: {
      helperText: 'ValidationMessages.MeetingRoomILTSession',
      fetchDataPath: DIALOG_PATHS.MEETING_ROOMS,
      disabled:
        values?.venueDtos?.length === 0 ||
        !values?.sessionDayDto?.name ||
        Boolean(
          errors?.sessionDayDto?.endTime === Localize.get('ValidationMessages.EndTimeAfterStart')
        ),
      tokenValue:
        values?.sessionDayDto?.meetingRoomId?.id && values?.sessionDayDto?.meetingRoomId?.name
          ? [
              {
                title: `${values?.sessionDayDto?.meetingRoomId?.name} `
              }
            ]
          : undefined,
      onIconClick: (e, result, resource) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
          onRoomRemove();
        }

        if (e?.target?.name === 'save') {
          if (!result?.length) {
            setFieldValue('sessionDayDto.dayDate', initialValues.sessionDayDto.dayDate);
            setFieldValue('sessionDayDto.startTime', initialValues.sessionDayDto.startTime);
            setFieldValue('sessionDayDto.endTime', initialValues.sessionDayDto.endTime);
            setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
            return;
          }

          const { start, end } = result[0];

          setFieldValue('sessionDayDto.meetingRoomId', {
            ...resource,
            name: resource?.resourceTitle,
            id: resource?.resourceId
          });
          setFieldValue('sessionDayDto.dayDate', moment(start).format(dateInitFormats.basicDate));
          setFieldValue('sessionDayDto.startTime', start);
          setFieldValue('sessionDayDto.endTime', end);
        }
      }
    },
    ['course']: {
      disabled: isNavigatedFromCourses,
      tokenValue:
        values?.course?.id && values.course?.name
          ? [
              {
                title: `${values.course.name} `
              }
            ]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            course: initialValues.course,
            iltModule: initialValues.iltModule
          });
        }
      },
      selectItem: (value) => setValues({ ...values, course: value, iltModule: null })
    },
    ['iltModule']: {
      tokenValue:
        values?.iltModule?.id && values.iltModule?.name
          ? [{ title: `${values.iltModule.name}` }]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') setFieldValue('iltModule', null);
      },
      selectItem: (value) => {
        setValues({ ...values, iltModule: value, name: value?.name });
        setTimeout(() => document.getElementsByName('name')[0]?.focus(), FOCUS_FIELD_TIMEOUT);
      },
      initQueryState: {
        courseId: values?.course?.id
      },
      disabled: !values?.course?.id || isNavigatedFromCourses
    },
    ['sessionDayDto.name']: {
      onChange: (e) => {
        setFieldValue('sessionDayDto.name', e.target.value);
        onSessionDayUpdate('name', e.target.value);
      }
    },
    ['sessionDayDto.dayDate']: {
      disabled: Boolean(values?.sessionDayDto.meetingRoomId?.id),
      onChange: (value) => {
        setFieldValue('sessionDayDto.dayDate', value || null);
        onSessionDayDateChange(value);
      },
      ampm: false
    },
    ['sessionDayDto.endTime']: {
      disabled: Boolean(values?.sessionDayDto.meetingRoomId?.id),
      ampm: false,
      onChange: (value) => {
        setFieldTouched('sessionDayDto.endTime', true);
        if (!value || !isValidDate(value)) {
          onSessionDayUpdate('predefinedEnd', null);
          setFieldValue('sessionDayDto.endTime', value, true);
          return;
        }

        setFieldValue('sessionDayDto.endTime', moment(value, 'HH:mm'), true);
        onSessionDayUpdate('predefinedEnd', moment(value, 'HH:mm'));
      }
    },
    ['sessionDayDto.startTime']: {
      disabled: Boolean(values?.sessionDayDto.meetingRoomId?.id),
      ampm: false,
      onChange: (value) => {
        setFieldTouched('sessionDayDto.startTime', true);
        if (!value || !isValidDate(value)) {
          onSessionDayUpdate('predefinedStart', null);
          setFieldValue('sessionDayDto.startTime', value, true);
          return;
        }

        setFieldValue('sessionDayDto.startTime', moment(value, 'HH:mm'), true);
        onSessionDayUpdate('predefinedStart', moment(value, 'HH:mm'));
      }
    },
    ['sessionDayDto.hasReminder']: {
      value: values?.sessionDayDto?.hasReminder || false,
      onChange: (event) => {
        const { name, checked } = event.target;
        setFieldValue(name, checked);
        if (!checked) {
          setFieldValue('sessionDayDto.reminderValue', initialValues.sessionDayDto.reminderValue);
          setFieldValue('sessionDayDto.metricTypeId', initialValues.sessionDayDto.metricTypeId);
        }
      },
      tooltip: {
        title: Localize.get('IltSession.Tooltips.SendRemindersBeforeSession')
      }
    },
    ['sessionDayDto.reminderValue']: {
      isVisible: values?.sessionDayDto?.hasReminder
    },
    ['sessionDayDto.metricTypeId']: {
      isVisible: values?.sessionDayDto?.hasReminder,
      options: sessionDayMetrics,
      value: values?.sessionDayDto?.metricTypeId || ''
    },
    ['hasRegistrationPeriod']: {
      value: values?.hasRegistrationPeriod || false,
      onChange: (event) => {
        const { name, checked } = event.target;
        setFieldValue(name, checked);
        setFieldValue('registrationBeforeStart', initialValues.registrationBeforeStart);
        setFieldValue(
          'registrationBeforeStartMetricType',
          initialValues.registrationBeforeStartMetricType
        );
      },
      tooltip: {
        title: Localize.get('IltSession.Tooltips.RegistrationDeadline')
      }
    },
    ['registrationBeforeStart']: {
      isVisible: values?.hasRegistrationPeriod
    },
    ['registrationBeforeStartMetricType']: {
      onChange: (event) => {
        setFieldValue('registrationBeforeStartMetricType', {
          id: event.target.value,
          value: sessionDayMetrics.find((t) => t.id === event.target.value).value
        });
      },
      isVisible: values?.hasRegistrationPeriod,
      options: sessionDayMetrics,
      value:
        values?.registrationBeforeStartMetricType?.id ||
        initialValues.registrationBeforeStartMetricType.id
    },
    ['description']: {
      isVisible: values?.hasRegistrationPeriod
    },
    ['hasCancellationDeadline']: {
      value: values?.hasCancellationDeadline || false,
      onChange: (event) => {
        const { name, checked } = event.target;
        setFieldValue(name, checked);
        setFieldValue('cancellationBeforeStart', initialValues.cancellationBeforeStart);
        setFieldValue(
          'cancellationBeforeStartMetricType',
          initialValues.cancellationBeforeStartMetricType
        );
      },
      tooltip: {
        title: Localize.get('IltSession.Tooltips.CancellationDeadline')
      }
    },
    ['cancellationBeforeStart']: {
      isVisible: values?.hasCancellationDeadline
    },
    ['cancellationBeforeStartMetricType']: {
      onChange: (event) => {
        setFieldValue('cancellationBeforeStartMetricType', {
          id: event.target.value,
          value: sessionDayMetrics.find((t) => t.id === event.target.value).value
        });
      },
      isVisible: values?.hasCancellationDeadline,
      options: sessionDayMetrics,
      value:
        values?.cancellationBeforeStartMetricType?.id ||
        initialValues.cancellationBeforeStartMetricType.id
    },
    ['descriptionCancellation']: {
      isVisible: values?.hasCancellationDeadline
    },
    ['hasWaitingList']: {
      onChange: (event) => {
        const { checked } = event.target;
        setValues({
          ...values,
          hasWaitingList: checked,
          hasAutomaticWaitingList: false,
          hasAutomaticCancellation: false
        });
      },
      tooltip: {
        title: Localize.get('IltSession.Tooltips.WaitingList')
      }
    },
    ['hasAutomaticWaitingList']: {
      isVisible: values?.hasWaitingList,
      tooltip: {
        title: Localize.get('IltSession.Tooltips.AutomaticWaitingList')
      }
    },
    ['hasAutomaticCancellation']: {
      isVisible: values?.hasWaitingList,
      onChange: (event) => {
        const { name, checked } = event.target;
        setFieldValue(name, checked);
        setFieldValue(
          'automaticCancellationBeforeStart',
          initialValues.automaticCancellationBeforeStart
        );
        setFieldValue(
          'automaticCancellationBeforeStartMetricType',
          initialValues.automaticCancellationBeforeStartMetricType
        );
      },
      tooltip: {
        title: Localize.get('IltSession.Tooltips.AutomaticWaitingListCancellation')
      }
    },
    ['automaticCancellationBeforeStart']: {
      isVisible: values?.hasAutomaticCancellation
    },
    ['automaticCancellationBeforeStartMetricType']: {
      onChange: (event) => {
        setFieldValue('automaticCancellationBeforeStartMetricType', {
          id: event.target.value,
          value: sessionDayMetrics.find((t) => t.id === event.target.value).value
        });
      },
      isVisible: values?.hasAutomaticCancellation,
      options: sessionDayMetrics,
      value:
        values?.automaticCancellationBeforeStartMetricType?.id ||
        initialValues.automaticCancellationBeforeStartMetricType.id
    },
    ['descriptionAutomatic']: {
      isVisible: values?.hasAutomaticCancellation
    }
  });
};

export default getFieldsConfig;
