import Localize from 'react-intl-universal';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/styles/useTheme';

import CreateForm from '@components/CreateForm';
import Footer from '@components/Footer';
import {
  toggleCreateDialog,
  uploadAttachment
} from '@pages/IltSession/components/Tabs/AttachmentsTable/attachmentsTableSlice';
import createAttachmentSchema from '@pages/IltSession/util/schemas/createAttachmentSchema';

import getFieldsConfig from './getFieldsConfig';
import { initialValues } from './initialValues';

const CreateAttachment = ({ isOpen, entityId, entityType, setDetails }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(
      uploadAttachment({
        formData: { objectId: entityId, objectName: entityType, ...values },
        setDetails
      })
    );
  };

  const onCancel = () => {
    dispatch(toggleCreateDialog(false));
  };

  return (
    <Dialog fullWidth fullScreen={fullScreen} maxWidth={'xs'} open={isOpen}>
      <DialogTitle
        sx={{
          boxShadow: 2,
          marginBottom: 0
        }}
      >
        <Typography width="100%" fontSize="1.5rem" fontWeight="500" align="center">
          {Localize.get('IltSession.UploadAttachment')}
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '650px',
          padding: 0
        }}
      >
        <Formik
          validateOnMount
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={createAttachmentSchema}
        >
          {({ isSubmitting, values, setFieldValue, handleSubmit, isValid }) => (
            <>
              <CreateForm
                formContainerSX={{ margin: '5px' }}
                values={values}
                gridItemSX={{ mb: -1 }}
                formGroupNameSX={{ mb: 1, mt: 2 }}
                fieldsConfig={getFieldsConfig(setFieldValue)}
              />
              <Footer>
                <Box
                  sx={{
                    maxWidth: '400px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    padding: '1rem'
                  }}
                >
                  <Button
                    sx={{ mr: 1 }}
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                    onClick={handleSubmit}
                    startIcon={<SaveIcon />}
                  >
                    {Localize.get('Buttons.Save')}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={onCancel}
                    color="error"
                    startIcon={<CancelIcon />}
                  >
                    {Localize.get('Buttons.Cancel')}
                  </Button>
                </Box>
              </Footer>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateAttachment;
