import React, { memo, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import { FieldArray } from 'formik';
import { get } from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { ACTION_MODES } from '@common/Constants';
import ArrayItem from '@components/ArrayItem';
import { selectMode } from '@components/RightSidebar/rightSidebarSlice';

import { email, phone, address } from './models';

const arrayDefinitions = (arrayName) => {
  switch (true) {
    case arrayName.includes('phoneDtos'): {
      return {
        maxItems: 5,
        model: phone,
        addButton: 'Buttons.AddPhone',
        removeButton: 'Buttons.RemovePhone'
      };
    }
    case arrayName.includes('addressDtos'): {
      return {
        maxItems: 5,
        model: address,
        addButton: 'Buttons.AddAddress',
        removeButton: 'Buttons.RemoveAddress'
      };
    }
    default:
      return {
        maxItems: 3,
        model: email,
        addButton: 'Buttons.AddEmail',
        removeButton: 'Buttons.RemoveEmail'
      };
  }
};

const FormArray = ({
  arrayName = '',
  values = {},
  fields = [],
  isSubmitting = false,
  mapPropsToAddress,
  context = '',
  arrayConfig = { actions: null }
}) => {
  const mode = useSelector(selectMode);
  const isFieldOrButtonDisabled = useMemo(
    () => isSubmitting || (context === 'edit' && mode === 'read'),
    [context, mode]
  );

  const arrayValues = get(values, arrayName, []);

  const isEmailRemovable =
    arrayValues.length === 1
      ? arrayName.includes('emailDtos')
        ? arrayName.includes('organizationPerson.emailDtos')
          ? values?.businessPartnerType?.id === 1
            ? true
            : values?.businessPartnerType === 1
          : true
        : false
      : false;

  const isCreateContext = ACTION_MODES.Create === context;

  return (
    <FieldArray name={arrayName}>
      {({ remove, push, form: { setFieldValue } }) => (
        <>
          {arrayValues.map((arrayItem, arrayItemIndex) => (
            <ArrayItem
              key={arrayItemIndex}
              arrayItemIndex={arrayItemIndex}
              arrayName={arrayName}
              isFieldOrButtonDisabled={isFieldOrButtonDisabled}
              arrayDefinitions={arrayDefinitions}
              fields={fields}
              context={context}
              remove={remove}
              arrayItem={arrayItem}
              setFieldValue={setFieldValue}
              mapPropsToAddress={mapPropsToAddress}
              values={values}
              arrayConfig={arrayConfig}
              isEmailRemovable={isEmailRemovable}
            />
          ))}

          {!arrayConfig?.actions?.areButtonsHidden && (
            <Button
              disabled={
                isFieldOrButtonDisabled ||
                get(values, arrayName, []).length === arrayDefinitions(arrayName).maxItems ||
                arrayConfig?.actions?.isAddDisabled
              }
              type="button"
              sx={{
                ml: 2,
                top: isCreateContext ? 0 : context === ACTION_MODES.Edit ? '4px' : '-4px',
                mt: isCreateContext ? 1 : 0,
                mb: [ACTION_MODES.Create, ACTION_MODES.Edit].includes(context) ? 2 : 0
              }}
              disableFocusRipple
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => push({ ...arrayDefinitions(arrayName).model })}
            >
              {Localize.get(arrayDefinitions(arrayName).addButton)}
            </Button>
          )}
        </>
      )}
    </FieldArray>
  );
};

export default memo(FormArray);
