import AccountTypes from '@common/network/AccountTypes';
import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'Labels.Information',

    fields: [
      {
        label: 'Labels.Completion',
        name: 'taskDto.completion',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Labels.Processor',
        name: 'taskDto.processor',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchEmployee',
        component: 'tokenValue',
        value: '',
        initQueryState: { personTypeId: '', name: '', type: AccountTypes.EMPLOYEE },
        fetchDataPath: DIALOG_PATHS.PERSONS,
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'companyName',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.Category',
        name: 'taskDto.taskCategory',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  }
];
