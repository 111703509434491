import { copyObjectWithoutRef } from '@common/helpers/helpers';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import {
  ADDRESS_PATH,
  LOCALES_PATHS,
  PARTICIPANTS_PATHS,
  PERSONS_PATHS
} from '@common/network/ApiPaths';
import { saveState } from '@common/storage/persistSlice';
import ContactTypeDropdown from '@components/ContactTypeDropdown/ContactTypeDropdown';
import { WEBINAR_TYPE_ID } from '@pages/IltSession/iltSessionSlice';
import { classroomFields, webinarFields } from '@pages/IltSession/util/fields/createWaitingList';

import initialValues from './initialValues';

export const mapPropsToAddress = (setFieldValue, arrayItemIndex, arrayItem, values) => {
  return {
    ['region']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue(`addressDtos[${arrayItemIndex}].region`, data?.newValue);
      },
      path: ADDRESS_PATH.GET_REGION_BY_ID,
      pathVariables: { id: arrayItem?.country?.id },
      pathVariablesRequired: ['id'],
      disabled: true
    },
    ['country']: {
      path: LOCALES_PATHS.COUNTRIES,
      formItemChangeHandler: (e, data, name) => {
        const fieldIndex = name && name.split('.')[1];
        const addressDtos = copyObjectWithoutRef(values.addressDtos);
        addressDtos[fieldIndex].country = data.newValue;
        addressDtos[fieldIndex].region = null;
        setFieldValue('addressDtos', addressDtos);
      }
    }
  };
};

export const getFieldsConfig = (
  values,
  setFieldValue,
  setValues,
  allergy,
  language,
  phoneTypes,
  emailTypes,
  addressTypes,
  statuses,
  session,
  dispatch,
  { selectedId, filter }
) => {
  const isWebinarType = session?.type?.id === WEBINAR_TYPE_ID;
  return mapPropsToComponent(isWebinarType ? webinarFields : classroomFields, {
    ['company']: {
      tokenValue: values?.company?.name ? [{ title: values.company?.name }] : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('company', initialValues.company);
        }
      },
      selectItem: (value) => {
        setFieldValue('company', {
          ...value
        });
        setFieldValue('company.id', value.organizationPersonId);
      },
      create: {
        to: '/business-partners/new',
        search: {
          isAccount: true,
          iltSessionId: session?.id
        },
        onClick: () =>
          dispatch(
            saveState({
              participantFormValues: values,
              iltSessionFilter: filter,
              selectedId
            })
          )
      }
    },
    ['company.id']: {
      tokenValue: values?.company?.name ? [{ title: values.company?.name }] : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('company', initialValues.company);
        }
      },
      selectItem: (value) => {
        setFieldValue('company', {
          ...value
        });
      }
    },
    ['invoiceCompany']: {
      tokenValue: values?.invoiceCompany?.name
        ? [{ title: values.invoiceCompany?.name }]
        : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('invoiceCompany', initialValues.invoiceCompany);
        }
      },
      selectItem: (value) => {
        setFieldValue('invoiceCompany', { ...value });
        setFieldValue('invoiceCompany.id', value.organizationPersonId);
      }
    },
    ['nativeLanguage.id']: {
      options: language
    },
    ['arrivalDate']: {
      disabled: !values?.hotelRequest,
      onChange: (value) => setFieldValue('arrivalDate', value)
    },
    ['departureDate']: {
      disabled: !values?.hotelRequest,
      onChange: (value) => setFieldValue('departureDate', value)
    },
    ['nativeLanguage']: {
      options: language,
      formItemChangeHandler: (e, { newValue = {} }) => {
        setValues({
          ...values,
          nativeLanguage: newValue
        });
      }
    },
    ['allergies']: {
      options: allergy,
      onChange: (e, newValue) => setFieldValue('allergies', newValue)
    },
    ['iltSession']: {
      tokenValue: values?.iltSession?.name ? [{ title: values?.iltSession?.name }] : null
    },
    ['personDto.name']: {
      tokenValue: values?.personDto?.name ? [{ title: values?.personDto?.name }] : null,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            addressDtos: initialValues.addressDtos,
            emailDtos: [],
            phoneDtos: [],
            personDto: {
              ...initialValues.personDto,
              birthDate: null
            },
            company: initialValues.company
          });
        }
      },
      selectItem: (value) => {
        setValues({
          ...values,
          personDto: {
            firstName: value?.firstName,
            lastName: value?.lastName,
            name: `${value.firstName} ${value.lastName}`,
            birthDate: value?.birthDate || null,
            birthPlace: value?.birthPlace || '',
            id: value?.id || null,
            title: value?.title || initialValues.personDto.title
          },
          company: value?.companyName,
          emailDtos: value?.emailDtos,
          addressDtos: value?.addressDtos,
          phoneDtos: value?.phoneDtos
        });
      },
      selectionModel: values?.personDto?.id,
      extendedComponents: ContactTypeDropdown
    },
    ['status']: {
      options: statuses,
      disabled: true
    },
    ['cancellationReason']: {
      path: PARTICIPANTS_PATHS.PARTICIPANTS_CANCELLATION_REASON,
      responseFormater: (data) => data.filter((el) => el.value !== ''),
      formItemChangeHandler: (e, { newValue = {} }) => {
        setValues({
          ...values,
          cancellationReason: newValue
        });
      }
    },
    ['result']: {
      path: PARTICIPANTS_PATHS.PARTICIPANT_RESULTS,
      responseFormater: (data) => data.filter((el) => el.value !== ''),
      formItemChangeHandler: (e, { newValue = {} }) => {
        setValues({
          ...values,
          result: newValue
        });
      }
    },
    ['personDto.gender']: {
      path: PERSONS_PATHS.GENDERS,
      formItemChangeHandler: (e, { newValue = {} }) => {
        setValues({
          ...values,
          personDto: { ...values.personDto, gender: newValue }
        });
      }
    },
    ['emailDtos']: {
      ['type.id']: {
        options: emailTypes
      }
    },
    ['phoneDtos']: {
      ['type.id']: {
        options: phoneTypes
      }
    },
    ['addressDtos']: {
      ['type.id']: {
        options: addressTypes
      }
    }
  });
};

export default getFieldsConfig;
