import React, { useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate, useSearch } from 'react-location';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';
import { omit } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { TAB_KEYS } from '@common/Constants';
import CreateFooter from '@components/CreateFooter/CreateFooter';
import CreateForm from '@components/CreateForm';
import Header from '@components/Header/Header';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { businessPartnersPath, contactsPath, locationsPath } from '@navigation/routes/Routes';
import { createContact } from '@pages/Contacts/contactsSlice';
import { initialValues } from '@pages/Contacts/util/initialValues';
import createContactSchema from '@pages/Contacts/util/schemas/createContactSchema';

import getFieldsConfig, { mapPropsToAddress } from './getFieldsConfig';

const CreateContact = ({ organizationPerson = null }) => {
  const {
    data: {
      phoneTypes: { data: phoneTypes },
      emailTypes: { data: emailTypes },
      addressTypes: { data: addressTypes }
    }
  } = useMatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSearch();

  const navigatedFromTab = search?.locationId
    ? 'location'
    : search?.businessPartnerId
    ? 'businessPartner'
    : '';

  const onCancelClick = () => {
    navigatedFromTab === 'location' || navigatedFromTab === 'businessPartner'
      ? navigate({
          search: (previousUrlParams) => {
            const newParams = {
              ...omit(previousUrlParams, 'businessPartnerId', 'locationId', 'mode')
            };
            return { ...newParams, activeTab: TAB_KEYS.CONTACT };
          }
        })
      : navigate({
          to: contactsPath,
          search: (previousUrlParams) => {
            const newParams = { ...omit(previousUrlParams, 'businessPartnerId', 'locationId') };
            return { ...newParams, activeTab: TAB_KEYS.OVERVIEW };
          }
        });
  };

  const onSubmit = (contact, { setSubmitting }) => {
    const isContactTab = navigatedFromTab === '';
    const isBusinessPartnerTab = navigatedFromTab === 'businessPartner';
    const isLocationTab = navigatedFromTab === 'location';

    dispatch(createContact({ contact, isBusinessPartnerTab, isLocationTab }))
      .unwrap()
      .then(() => {
        navigate({
          to: isLocationTab
            ? locationsPath
            : isBusinessPartnerTab
            ? businessPartnersPath
            : contactsPath,
          search: (previousUrlParams) => {
            const newParams = {
              ...omit(previousUrlParams, 'businessPartnerId', 'locationId', 'mode')
            };

            return {
              ...newParams,
              activeTab: isContactTab ? TAB_KEYS.OVERVIEW : TAB_KEYS.CONTACT
            };
          }
        });
      })
      .finally(() => setSubmitting(false));
  };

  const memoizedInitialValues = useMemo(() => {
    return {
      ...initialValues,
      organizationPerson: navigatedFromTab
        ? {
            id: organizationPerson?.organizationPerson?.id,
            name: organizationPerson?.organizationPerson?.name
          }
        : initialValues.organizationPerson
    };
  }, [navigatedFromTab, organizationPerson]);

  return (
    <Box>
      {!search.businessPartnerId && !search.locationId && (
        <Header>{Localize.get('ContactsTile.NewContact')}</Header>
      )}

      <Formik
        enableReinitialize
        validateOnMount
        initialValues={memoizedInitialValues}
        onSubmit={onSubmit}
        validationSchema={createContactSchema}
      >
        {({ isSubmitting, values, handleSubmit, isValid, setValues, setFieldValue }) => (
          <>
            {!search.businessPartnerId && !search.locationId ? (
              <>
                <CreateForm
                  values={values}
                  mapPropsToAddress={mapPropsToAddress}
                  fieldsConfig={getFieldsConfig(
                    setFieldValue,
                    phoneTypes,
                    emailTypes,
                    addressTypes,
                    values,
                    setValues,
                    navigatedFromTab
                  )}
                />
                <CreateFooter>
                  <Button
                    data-test-id="cancel-btn"
                    variant="outlined"
                    onClick={onCancelClick}
                    color="error"
                    sx={{ mr: 1 }}
                    startIcon={<CancelIcon />}
                  >
                    {Localize.get('Buttons.Cancel')}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button
                    data-test-id="save-btn"
                    variant="outlined"
                    disabled={isSubmitting || !isValid}
                    onClick={handleSubmit}
                    startIcon={<SaveIcon />}
                  >
                    {Localize.get('Buttons.Save')}
                  </Button>
                </CreateFooter>
              </>
            ) : (
              <>
                <CreateForm
                  values={values}
                  mapPropsToAddress={mapPropsToAddress}
                  fieldsConfig={getFieldsConfig(
                    setFieldValue,
                    phoneTypes,
                    emailTypes,
                    addressTypes,
                    values,
                    setValues,
                    navigatedFromTab
                  )}
                />
                <InlineCreateHeaderContainer>
                  <Button
                    data-test-id="cancel-btn"
                    variant="outlined"
                    onClick={onCancelClick}
                    color="error"
                    sx={{ mr: 1 }}
                    startIcon={<CancelIcon />}
                  >
                    {Localize.get('Buttons.Cancel')}
                  </Button>
                  <Button
                    data-test-id="save-btn"
                    variant="outlined"
                    disabled={isSubmitting || !isValid}
                    onClick={handleSubmit}
                    startIcon={<SaveIcon />}
                  >
                    {Localize.get('Buttons.Save')}
                  </Button>
                </InlineCreateHeaderContainer>
              </>
            )}
          </>
        )}
      </Formik>
    </Box>
  );
};

export default CreateContact;
