import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

const iltSessionFilters = [
  {
    label: 'IltSession.SeminarNumber',
    id: 'seminarNumber',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'IltSession.MaximumSeats',
    id: 'maxOccupancy',
    type: FILTER_TYPES.number,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' }
  },
  {
    label: 'IltSession.MinimumSeats',
    id: 'minOccupancy',
    type: FILTER_TYPES.number,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' }
  },
  {
    label: 'IltSession.SessionType',
    id: 'type',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: [
      { label: 'IltSession.Classroom', key: 'IltSession.Classroom', translateKey: true },
      { label: 'IltSession.Webinar', key: 'IltSession.Webinar', translateKey: true }
    ]
  },
  {
    label: 'Labels.Status',
    id: 'status',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: [
      {
        label: 'Event.Statuses.Open',
        key: 'OPEN'
      },
      {
        label: 'Event.Statuses.InProgress',
        key: 'INPR'
      },
      {
        label: 'Event.Statuses.Completed',
        key: 'COMP'
      },
      {
        label: 'Event.Statuses.Cancelled',
        key: 'CANC'
      }
    ]
  },
  {
    label: 'IltSession.WaitList',
    id: 'haswaitinglist',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'IltSession.PublishInCatalog',
    id: 'ismeapublished',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  }
  // {
  //   label: 'Labels.DateFrom',
  //   id: 'dateFrom',
  //   type: FILTER_TYPES.date,
  //   operator: { key: FILTER_KEYS.IsOnOrBefore, label: 'Tables.FilterValues.IsOnOrBefore' }
  // },
  // {
  //   label: 'Labels.DateTo',
  //   id: 'dateTo',
  //   type: FILTER_TYPES.date,
  //   operator: { key: FILTER_KEYS.IsOnOrAfter, label: 'Tables.FilterValues.IsOnOrAfter' }
  // }

  // {
  //   label: 'IltSession.RegistrationPeriod',
  //   id: 'registrationPeriod',
  //   type: FILTER_TYPES.date,
  //   operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' }
  // },
  // {
  //   label: 'IltSession.CancellationDeadline',
  //   id: 'cancellationDeadline',
  //   type: FILTER_TYPES.date,
  //   operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' }
  // },
];

export default iltSessionFilters;
