import moment from 'moment';

export default {
  name: '',
  minSeats: 0,
  maxSeats: 0,
  dayDate: moment(),
  startTime: '',
  endTime: '',
  details: '',
  meetingRoomId: null,
  venueId: null,
  instructorIds: [],
  webinarUrl: '',
  iltModule: '',
  startDateTime: '',
  endDateTime: '',
  hasReminder: false,
  reminderValue: 1,
  metricTypeId: 1
};
