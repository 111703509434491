import React, { useMemo } from 'react';
import Localize from 'react-intl-universal';

import RightSidebar from '@components/RightSidebar';
import { CLASSROOM_TYPE_ID } from '@pages/IltSession/iltSessionSlice';
import useSessionDaySidebarSchema from '@pages/IltSession/util/schemas/useSessionDaySidebarSchema';
import sessionDayInitialValues from '@pages/IltSession/util/sessionDayInitialValues';

import sidebarSessionDayClassroom from '../util/fields/sidebarSessionDayClassroom';
import sidebarSessionDayWebinar from '../util/fields/sidebarSessionDayWebinar';

const Sidebar = ({
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {},
  data = null,
  sessionTypeId = ''
}) => {
  const sidebarSchema = useSessionDaySidebarSchema();

  const sessionDayMetrics = useMemo(
    () => [
      { value: Localize.get('MetricTypes.Minutes'), id: 1 },
      { value: Localize.get('MetricTypes.Hours'), id: 2 },
      { value: Localize.get('MetricTypes.Days'), id: 3 }
    ],
    []
  );

  const extendedConfig = (setFieldValue, values) => {
    return {
      ['instructors']: {
        tokenValue:
          values?.instructors?.map((instructors) => ({
            title: `${instructors.firstName}`,
            id: instructors.id
          })) ?? [],
        onIconClick: (id) => {
          setFieldValue(
            'instructors',
            values.instructors.filter((item) => item.id !== id)
          );
        },
        selectItem: (value = []) => {
          if (!value?.length) {
            setFieldValue('instructors', []);
            return;
          }

          setFieldValue('instructors', value);
        },
        selectionModel: values.instructors
      },
      ['course']: {
        tokenValue: values?.dayScheduleDto?.iltSessionDto?.course?.name
          ? [
              {
                title: `${values.dayScheduleDto.iltSessionDto.course.name} `
              }
            ]
          : undefined
      },
      ['module']: {
        tokenValue: values?.dayScheduleDto?.iltSessionDto?.module?.name
          ? [
              {
                title: `${values?.dayScheduleDto.iltSessionDto.module?.name}`
              }
            ]
          : undefined
      },
      ['hasReminder']: {
        value: values?.hasReminder || false,
        onChange: (event) => {
          const { name, checked } = event.target;
          setFieldValue(name, checked);
          if (checked) {
            setFieldValue('reminderValue', sessionDayInitialValues.reminderValue);
            setFieldValue('metricTypeId', sessionDayInitialValues.metricTypeId);
          }
        },
        tooltip: {
          title: Localize.get('IltSession.Tooltips.SendRemindersBeforeSession')
        }
      },
      ['reminderValue']: {
        isVisible: values?.hasReminder
      },
      ['webinarUrl']: {
        value: values?.webinarUrl || ''
      },
      ['metricTypeId']: {
        isVisible: values?.hasReminder,
        options: sessionDayMetrics,
        value: values?.metricTypeId || ''
      }
    };
  };

  return (
    <RightSidebar
      config={{
        title: Localize.get('Sessions.Details'),
        fieldsConfig:
          sessionTypeId === CLASSROOM_TYPE_ID
            ? sidebarSessionDayClassroom
            : sidebarSessionDayWebinar
      }}
      validationSchema={sidebarSchema}
      extendedConfig={extendedConfig}
      data={data}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      isDeleteDisabled={true}
    />
  );
};

export default Sidebar;
