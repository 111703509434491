import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_DIRECTION } from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { ORG_STRUCTURE_PATHS } from '@common/network/ApiPaths';
import { MASTER_LIST_PAGE_SIZE } from '@config/network';
import { deleteByPath, postByPathAndData, putByPathAndData } from '@services/BaseApi';

import { SORT_DATA } from './util/sortConfig';

export const initialState = {
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.DESCENDING,
    page: 0,
    size: MASTER_LIST_PAGE_SIZE
  },
  //SHOULD be changed to 0 when servise would be ready
  totalPages: 1,
  totalElements: 0,
  details: null,
  isLoading: true,
  isDetailsLoading: false
};

export const ORG_STRUCTURE_SLICE = 'orgStructure';

export const fetchStructure = createAsyncThunk(
  `${ORG_STRUCTURE_SLICE}/fetchAll`,
  () =>
    //MOCK service response
    Promise.resolve({
      content: [
        { id: '13', name: 'Contrimo Belgarde', parentUnitId: '22', parentUnitName: 'Contrimo' },
        { id: '14', name: 'TEM Belgade', parentUnitId: '25', parentUnitName: 'Tem' }
      ]
    })
  //WILL BE uncommented when servise would be ready
  // getByPathAndParams({
  //   path: ORG_STRUCTURE_PATHS.GET,
  //   params: filter
  // })
  // .then((response) => response.data)
  // .catch((error) => error)
);

export const fetchStructureDetails = createAsyncThunk(
  `${ORG_STRUCTURE_SLICE}/fetchDetails`,
  (selectedId) =>
    //MOCK service response
    selectedId === '13'
      ? Promise.resolve({
          name: 'Contrimo Belgarde',
          parentUnitName: 'Contrimo',
          associatedBusinessPartner: 'Kraftwerk Berlin'
        })
      : Promise.resolve({
          name: 'TEM Belgrade',
          parentUnitName: 'TEM',
          associatedBusinessPartner: 'Siemens Berlin'
        })
  //WILL BE uncommented when servise would be ready
  //   getByPathAndParams({
  //     path: ORG_STRUCTURE_PATHS.GET_DETAILS,
  //     pathVariables: { id: selectedId }
  //   })
  //     .then((response) => response.data)
  //     .catch((error) => error),
  // {
  //   condition: (selectedId) => Boolean(selectedId),
  //   dispatchConditionRejection: true
  // }
);

export const CreateOrgStructure = createAsyncThunk(
  `${ORG_STRUCTURE_SLICE}/create`,
  (postData, thunkAPI) =>
    postByPathAndData({
      path: ORG_STRUCTURE_PATHS.POST,
      data: postData
    })
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error.response))
);

export const deleteOrgStructure = createAsyncThunk(
  `${ORG_STRUCTURE_SLICE}/delete`,
  (id, thunkAPI) =>
    deleteByPath({
      path: ORG_STRUCTURE_PATHS.DELETE,
      pathVariables: { id }
    })
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error.response))
);

export const saveOrgStructure = createAsyncThunk(
  `${ORG_STRUCTURE_SLICE}/save`,
  (postData, thunkAPI) =>
    putByPathAndData({
      path: ORG_STRUCTURE_PATHS.PUT,
      data: postData,
      pathVariables: { id: postData.id }
    })
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error.response))
);

export const orgStructureSlice = createSlice({
  name: ORG_STRUCTURE_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    deleteSuccess: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.selectedId = [...state.data.filter((item) => item.id !== action.payload.id)];
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchStructure.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content || state.data;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.selectedId = payload?.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber || 0;
        state.filter.size = payload?.pageable?.pageSize || state.filter.size;
      })
      .addCase(fetchStructure.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStructure.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      // Get Details
      .addCase(fetchStructureDetails.fulfilled, (state, action) => {
        state.details = action.payload;
        state.isDetailsLoading = false;
      })
      .addCase(fetchStructureDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchStructureDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      // Create
      .addCase(CreateOrgStructure.fulfilled, (state) => {
        state.filter.page = 0;
      })
      // Update
      .addCase(saveOrgStructure.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveOrgStructure.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      // Update
      .addCase(saveOrgStructure.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg) : item
        );
        state.details = deepMerge(state.details, action.meta.arg);
      })
      // Delete
      .addCase(deleteOrgStructure.fulfilled, (state, action) => {
        state.totalElements -= 1;
        state.isLoading = false;
        state.details = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      })
      .addCase(deleteOrgStructure.rejected, (state, { payload }) => {
        alert(`${payload.data.message}`);
      });
  }
});

export const selectId = (state) => state.ORG_STRUCTURE_SLICE.selectedId;
export const selectList = (state) => state.ORG_STRUCTURE_SLICE.data;
export const selectFilter = (state) => state.ORG_STRUCTURE_SLICE.filter;
export const selectTotalElements = (state) => state.ORG_STRUCTURE_SLICE.totalElements;
export const selectTotalPages = (state) => state.ORG_STRUCTURE_SLICE.totalPages;
export const selectDetails = (state) => state.ORG_STRUCTURE_SLICE.details;
export const selectIsLoading = (state) => state.ORG_STRUCTURE_SLICE.isLoading;
export const selectIsDetailsLoading = (state) => state.ORG_STRUCTURE_SLICE.isDetailsLoading;

const { actions, reducer } = orgStructureSlice;

export const {
  setError,
  setLoading,
  setSelectedId,
  setConfirmationDialog,
  deleteSuccess,
  setFilterParams,
  resetPage,
  resetState,
  setDetails
} = actions;

export default reducer;
