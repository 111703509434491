import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import { ACTION_MODES } from '@common/Constants';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import RightSidebarFooter from '@components/RightSidebarFooter';
import RightSidebarForm from '@components/RightSidebarForm';
import RightSidebarHeader from '@components/RightSidebarHeader';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';

import {
  closeSidebar,
  selectIsOpen,
  selectMode,
  setMode,
  resetState,
  selectSidebarConfig
} from './rightSidebarSlice';

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? theme.sizes.rightSidebar : '0',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: theme.sizes.rightSidebar,
    boxSizing: 'border-box',
    top: theme.sizes.appHeader
  }
}));

const RightSidebar = ({
  config = [],
  data = {},
  validationSchema = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {},
  extendedConfig = () => {},
  mapPropsToAddress = null,
  isDeleteDisabled = false
}) => {
  const altConfig = useSelector(selectSidebarConfig);

  return !altConfig ? (
    <SidebarDrawer
      config={config}
      data={data}
      validationSchema={validationSchema}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      extendedConfig={extendedConfig}
      mapPropsToAddress={mapPropsToAddress}
      isDeleteDisabled={isDeleteDisabled}
    />
  ) : (
    <SidebarDrawer {...altConfig} />
  );
};

const SidebarDrawer = ({
  config = {},
  data = {},
  validationSchema = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {},
  extendedConfig = () => {},
  mapPropsToAddress = null,
  isDeleteDisabled = false
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);
  const mode = useSelector(selectMode);

  const onSubmit = (values, { setSubmitting }) => {
    onSave(values)
      .then(() => {
        dispatch(setMode('read'));
      })
      .catch((errObj) => {
        setSubmitting(false);
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(
              errObj.rejectedValueOrSerializedError,
              errObj.entityType,
              ACTION_MODES.Edit
            ),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      });
  };

  // Closes sidebar when its open and data is not present (case when entity is deleted)
  useEffect(() => {
    if (data === null && isOpen) {
      dispatch(closeSidebar());
    }
  }, [data]);

  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const onClose = () => {
    if (mode === 'read') {
      dispatch(closeSidebar());
      return;
    }

    dispatch(
      openConfirmDialog({
        confirmIcon: 'done',
        confirmColor: 'primary',
        description: Localize.get('ConfirmationMessages.UnsavedData')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Confirm) {
          dispatch(closeSidebar());
          dispatch(setMode('read'));
        }
      });
  };

  return (
    <StyledDrawer onClose={onClose} variant="temporary" anchor="right" open={isOpen}>
      {isDetailsLoading ? (
        <CircularProgress size="2rem" sx={{ mt: '50%', ml: '45%' }} disableShrink />
      ) : config?.fieldsConfig?.length && data ? (
        <Box display="flex" flexDirection="column">
          <RightSidebarHeader onClose={onClose} headerTitle={config?.title} />
          <Formik
            enableReinitialize
            initialValues={data}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              isSubmitting,
              isValid,
              dirty,
              resetForm,
              handleSubmit,
              handleChange,
              setFieldValue,
              setFieldTouched,
              values,
              setValues
            }) => {
              return (
                <>
                  <RightSidebarForm
                    mapPropsToAddress={mapPropsToAddress}
                    isSubmitting={isSubmitting}
                    values={values}
                    handleChange={handleChange}
                    fieldsConfig={
                      !extendedConfig
                        ? config?.fieldsConfig
                        : mapPropsToComponent(
                            config?.fieldsConfig,
                            extendedConfig(setFieldValue, values, setValues, setFieldTouched)
                          )
                    }
                  />
                  <RightSidebarFooter
                    resetForm={resetForm}
                    handleSubmit={handleSubmit}
                    handleDelete={onDelete}
                    isSaveDisabled={isSubmitting || !dirty || !isValid}
                    isDeleteDisabled={isDeleteDisabled}
                  />
                </>
              );
            }}
          </Formik>
        </Box>
      ) : null}
    </StyledDrawer>
  );
};

export default RightSidebar;
