export const waitingListStatuses = [
  {
    id: '3',
    icon: 'schedule',
    label: 'ParticipantsTile.Statuses.Reserved'
  },
  {
    id: '6',
    icon: 'check',
    label: 'ParticipantsTile.Statuses.Registered'
  },
  {
    id: '8',
    icon: 'cancel',
    label: 'IltSession.WaitingCancelled'
  }
];

export default waitingListStatuses;
