import Localize from 'react-intl-universal';
//import { useMatch, useNavigate } from 'react-location';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import bookingDark from '@assets/images/booking-dark.png';
import booking from '@assets/images/booking.png';
//import { replaceAll } from '@common/helpers/string';
import { useTheme } from '@emotion/react';
//import { eventsHotelContingentPath } from '@navigation/routes/Routes';

const HotelBookingCard = () => {
  //const { params } = useMatch();
  //const navigate = useNavigate();
  const theme = useTheme();

  // const onCardClick = () => {
  //   const replaceMap = { ':eventId': params?.eventId, ':id': params.id };
  //   const path = replaceAll(eventsHotelContingentPath, replaceMap);

  //   navigate({ to: path, replace: false });
  // };

  return (
    <Card sx={{ width: '45vh', minHeight: '30vh', cursor: 'pointer' }}>
      <img
        alt="card"
        src={theme.palette.mode === 'light' ? booking : bookingDark}
        style={{ width: '100%' }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {Localize.get('IltSession.ManageBookings')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {Localize.get('Events.ManageBookingsDescription')}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HotelBookingCard;
