import Localize from 'react-intl-universal';

import * as yup from 'yup';

export const pricesSchema = () =>
  yup.object().shape({
    price: yup
      .number()
      .typeError(Localize.get('ValidationMessages.PriceRequired'))
      .min(0, Localize.get('ValidationMessages.PositiveNumber'))
      .required(Localize.get('ValidationMessages.PriceRequired'))
  });
