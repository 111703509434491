import React from 'react';
import Localize from 'react-intl-universal';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Header from '@components/Header';

const RightSidebarHeader = ({ headerTitle = '', onClose = () => {} }) => (
  <Header display="flex" flexDirection="row">
    <Typography
      data-test-id="sidebar-header-title"
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        fontWeight: 600
      }}
    >
      {headerTitle}
    </Typography>

    <Box display="flex" flexGrow={1} flexDirection="row" justifyContent="flex-end">
      <Tooltip title={Localize.get('Labels.CloseSidebar')}>
        <Button
          data-test-id="close-button"
          size="small"
          sx={{ mr: 1 }}
          variant="outlined"
          aria-label="close"
          onClick={onClose}
        >
          <Icon>close</Icon>
        </Button>
      </Tooltip>
    </Box>
  </Header>
);

export default RightSidebarHeader;
