export default [
  {
    groupName: 'ResourcesTile.Price',
    fields: [
      {
        label: 'Labels.PriceType',
        name: 'type.value',
        required: true,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'ResourcesTile.Price',
        name: 'price',
        required: true,
        disabled: false,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'ResourcesTile.Currency',
        name: 'currency.id',
        required: true,
        disabled: true,
        component: 'select',
        maxLength: 30
      }
    ]
  }
];
