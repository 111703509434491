import { Navigate } from 'react-location';

import { SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import MeetingRooms, { CreateMeetingRoom } from '@pages/MeetingRooms';
import { getByPathAndParams } from '@services/BaseApi';

import { meetingRoomsPath, newPath, rootPath } from '../Routes';

const MeetingRoomRoute = (routeCache) => ({
  path: meetingRoomsPath,
  children: [
    {
      path: rootPath,
      element: <MeetingRooms />,
      loader: routeCache.createLoader(
        () => ({
          config: getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.MEETING_ROOM }
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: newPath,
      element: <CreateMeetingRoom />
    },
    {
      element: <Navigate to={meetingRoomsPath} />
    }
  ]
});

export default MeetingRoomRoute;
