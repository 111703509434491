import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import SearchField from '@components/SearchField';

const StyledControlsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  width: '100%',
  paddingTop: '0.5rem',
  height: theme.sizes.masterListToolbar
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: theme.spacing(2),
    top: theme.spacing(1),
    border: `2px solid ${theme.palette.background.paper}`
  }
}));

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVisible'
})(({ isVisible }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  width: '100%',
  opacity: isVisible ? 1 : 0,
  pointerEvents: isVisible ? 'auto' : 'none'
}));

const MasterListToolbar = ({
  isDisabled = false,
  isSortActive = false,
  isFilterActive = false,
  onSortClick = () => {},
  onFilterClick = () => {},
  searchProps = null
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (searchProps?.isVisible !== null && searchProps?.isVisible !== undefined) {
      setIsVisible(searchProps?.isVisible);
    }
  }, [searchProps?.isVisible]);

  return (
    <StyledControlsContainer>
      {!isVisible && (
        <SearchField {...searchProps} isVisible={!isVisible} setIsVisible={setIsVisible} />
      )}

      {isVisible && (
        <>
          <Container isVisible={isVisible}>
            <Tooltip title={Localize.get('Labels.Search')}>
              {/* div needed since button can be disabled and Tooltip will throw an error. Do not remove. */}
              <div>
                <Button
                  disabled={isDisabled}
                  data-test-id="searchButton"
                  size="small"
                  onClick={() => setIsVisible(false)}
                  aria-label={Localize.get('Labels.Search')}
                  variant="outlined"
                  sx={{ mr: 1, ml: 1 }}
                >
                  <SearchIcon />
                </Button>
              </div>
            </Tooltip>

            <StyledBadge
              data-test-id="masterListBadge"
              color="success"
              badgeContent="on"
              invisible={!isSortActive}
            >
              <Tooltip title={Localize.get('Labels.Sort')}>
                {/* div needed since button can be disabled and Tooltip will throw an error. Do not remove. */}
                <div>
                  <Button
                    disabled={isDisabled}
                    variant="outlined"
                    data-test-id="sortButton"
                    onClick={onSortClick}
                    size="small"
                    aria-label="sort"
                    sx={{ mr: 1 }}
                  >
                    <ImportExportOutlinedIcon />
                  </Button>
                </div>
              </Tooltip>
            </StyledBadge>

            <StyledBadge
              data-test-id="masterListBadge"
              color="success"
              badgeContent="on"
              invisible={!isFilterActive}
            >
              <Tooltip title={Localize.get('Labels.Filter')}>
                {/* div needed since button can be disabled and Tooltip will throw an error. Do not remove. */}
                <div>
                  <Button
                    disableFocusRipple
                    data-test-id="filterButton"
                    size="small"
                    onClick={onFilterClick}
                    aria-label="filter"
                    variant="outlined"
                    disabled={isDisabled && !isFilterActive}
                    sx={{ mr: 1 }}
                  >
                    <FilterListOutlinedIcon />
                  </Button>
                </div>
              </Tooltip>
            </StyledBadge>
          </Container>
        </>
      )}
    </StyledControlsContainer>
  );
};

export default MasterListToolbar;
