import React, { useState, useEffect, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import {
  FILTER_ACTIONS,
  openFilterDialog,
  resetFilterState,
  scrubFiltersForBE,
  selectIsActive
} from '@components/FilterDialog/filtersSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import { contactsNewPath } from '@navigation/routes/Routes';

import ContactsDetails from './components/Details/ContactsDetails';
import Sidebar from './components/Sidebar';
import ContactsTabs from './components/Tabs/ContactsTabs';
import {
  setFilterParams,
  setLoading,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectDetails,
  selectList,
  setSelectedId,
  selectId,
  selectIsDetailsLoading,
  initialState,
  fetchContacts,
  fetchContactsDetails,
  saveContact,
  resetState,
  selectIsLoading,
  setDetails,
  deleteContact
} from './contactsSlice';
import { contactsFilters } from './util/fields/filter';
import { SORT_DATA } from './util/sortConfig';
import { getCountriesOptions } from './util/util';

const Contacts = () => {
  const {
    data: {
      countries: { data: countries }
    }
  } = useMatch();
  const options = countries.map((el) => ({
    label: el.value,
    key: el.value
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedId = useSelector(selectId);
  const filter = useSelector(selectFilter);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);

  const isDetailsLoading = useSelector(selectIsDetailsLoading);
  const isLoading = useSelector(selectIsLoading);
  const isFilterActive = useSelector(selectIsActive);

  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);

  // Get List of entities on sort change
  useEffect(
    () => dispatch(fetchContacts(filter)),
    [filter.sortBy, filter.sortDirection, filter.page]
  );

  // Get Details when selectedId changes
  useEffect(() => {
    dispatch(fetchContactsDetails(selectedId));
  }, [selectedId]);

  // Get initial List of entities on search change
  useDebounce(() => dispatch(fetchContacts(filter)), SEARCH_INPUT_DELAY, [filter.search]);

  const onEditSave = (postData) => {
    return dispatch(saveContact(postData))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.CONTACT
        });
      });
  };

  useEffect(() => {
    return () => {
      dispatch(resetFilterState());
      dispatch(resetState());
    };
  }, []);
  const onFilterClick = useCallback(() => {
    dispatch(
      openFilterDialog({
        title: `${Localize.get('Launchpad.Contacts')} ${Localize.get('Labels.Filter')}`,
        config: contactsFilters.map((filter) => ({
          ...filter,
          label: Localize.get(filter.label),
          operator: { label: Localize.get(filter.operator.label), key: filter.operator.key },
          options: getCountriesOptions(filter.id === 'country' ? options : filter.options)
        }))
      })
    ).then(({ payload }) => {
      if (payload.action === FILTER_ACTIONS.Cancel) {
        return;
      }

      if (payload.action === FILTER_ACTIONS.Reset) {
        dispatch(fetchContacts(filter));
        return;
      }

      dispatch(
        fetchContacts({
          ...filter,
          filters: { advancedFilters: scrubFiltersForBE(payload.filters) }
        })
      );
    });
  }, [details?.type?.value, filter?.sortBy, filter?.sortDirection, filter?.page]);

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('ContactsTile.Contact')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteContact(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  // Render Master List Item
  const renderMasterListItem = useCallback(
    ({ id, person }) => {
      return (
        <MasterListItem
          key={id}
          id={id}
          heading={{
            left: `${person?.firstName} ${person?.lastName}`,
            right: id
          }}
          selectedId={selectedId}
          onItemSelect={(payload) => dispatch(setSelectedId(payload))}
        />
      );
    },
    [selectedId]
  );

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'ContactsTile.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isFilterActive={isFilterActive}
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          onFilterClick={onFilterClick}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />

        <MasterList isLoading={isLoading} data={data} renderMasterItem={renderMasterListItem} />
        <MasterListFooter
          onAddClick={() => navigate({ to: contactsNewPath, replace: false })}
          onPageChange={(e, page) => dispatch(fetchContacts({ ...filter, page: page - 1 }))}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          open={isOpenDialogSort}
          onCancel={() => setIsOpenDialogSort(false)}
          sortState={filter}
          fields={SORT_DATA}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <ContactsDetails details={details} entityId={selectedId}>
          <ContactsTabs
            entityId={selectedId}
            entityType={EntityTypes.CONTACT}
            details={details}
            setDetails={setDetails}
          />
        </ContactsDetails>
      </RightContainer>

      <Sidebar
        details={details}
        isDetailsLoading={isDetailsLoading}
        onSave={onEditSave}
        onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default Contacts;
