const SORT_DATA = [
  { name: 'id', text: 'Locations.Id' },
  { name: 'name', text: 'Locations.Name' },
  { name: 'city', text: 'Labels.City' },
  { name: 'country', text: 'Labels.Country' }
];

const SORT_DATA_HOTELS = [
  { name: 'id', text: 'Locations.Id' },
  { name: 'name', text: 'Hotel.Name' },
  { name: 'city', text: 'Labels.City' },
  { name: 'country', text: 'Labels.Country' }
];

export { SORT_DATA, SORT_DATA_HOTELS };
