import moment from 'moment';

export default {
  type: 1,
  subject: '',
  startDateTime: new Date(),
  endDateTime: moment(new Date()).add(30, 'm').toDate(),
  status: 1,
  priority: 3,
  appointmentDto: {
    appointmentCategory: null,
    location: ''
  },
  taskDto: {
    processor: { id: '', firstName: '', lastName: '' },
    completion: 0,
    taskCategory: null
  },
  owner: {
    id: '',
    firstName: '',
    lastName: ''
  },
  account: null,
  contact: null
};
