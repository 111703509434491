import mapPropsToComponent from '@common/helpers/mapPropsToComponent';

import fieldsConfig from './fieldsConfig';

export const getFieldsConfig = (roomTypes) => {
  return mapPropsToComponent(fieldsConfig, {
    ['roomType']: {
      options: roomTypes
    }
  });
};

export default getFieldsConfig;
