import moment from 'moment';

export default {
  subject: '',
  startDateTime: new Date(),
  endDateTime: moment(new Date()).add(30, 'm').toDate(),
  statusId: 1,
  priorityId: 3,
  taskDto: {
    completion: 0,
    processor: null,
    taskCategory: '',
    eventId: null
  },
  owner: null,
  account: null,
  contact: null
};
