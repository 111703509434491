import React from 'react';
import Localize from 'react-intl-universal';

import { get } from 'lodash';
import moment from 'moment';

import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { checkLanguage } from '@common/helpers/languages';
import percentage from '@common/helpers/numbers/percentage';
import EventStatus, { EVENT_STATUS_MAP } from '@components/EventStatus';
import { DATE_FORMAT } from '@config/inputs';

moment.locale(checkLanguage());

const COLOR_OPACITY = 0.6;

const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'progressColor'
})(({ theme, progressColor }) => ({
  width: '100%',
  height: 30,
  borderRadius: 5,
  border: `1px solid ${theme.palette.grey[200]}`,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: alpha(theme.palette.background.paper, COLOR_OPACITY)
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: alpha(get(theme.palette, progressColor), COLOR_OPACITY)
  }
}));

const LinearProgressWithLabel = ({
  value = 0,
  totalValue = 0,
  progressColor = 'primary.main',
  currentValue = 0
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '75%', mr: 1 }}>
        <BorderLinearProgress value={value} variant="determinate" progressColor={progressColor} />
      </Box>
      <Box sx={{ minWidth: 60 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          currentValue
        )} of ${totalValue}`}</Typography>
      </Box>
    </Box>
  );
};

const BasicInfo = ({ data = null }) => {
  const minParticipants = data?.minParticipants;
  const maxParticipants = data?.maxParticipants;
  const currentParticipants = data?.totalAcceptedParticipants;
  const percentageMin = percentage(currentParticipants, minParticipants);

  return (
    <Box sx={{ minHeight: '30vh', width: '45vh' }}>
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                data-test-id="labelStatus"
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Labels.Status')}:
              </Typography>
              <Tooltip
                title={Localize.get(EVENT_STATUS_MAP[data?.status?.id])}
                placement="top"
                arrow
              >
                <Typography
                  data-test-id="valueStatus"
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('Labels.Status').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  <EventStatus status={data?.status?.id}></EventStatus>
                </Typography>
              </Tooltip>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                data-test-id="labelStartDateTime"
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Event.StartDateTime')}:
              </Typography>
              <Typography
                data-test-id="valueStartDateTime"
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('Event.StartDateTime').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {moment(get(data, 'startDateTime')).format(DATE_FORMAT)}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                data-test-id="labelEndDateTime"
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Event.EndDateTime')}:
              </Typography>
              <Typography
                data-test-id="valueEndDateTime"
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('Event.EndDateTime').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {moment(get(data, 'endDateTime')).format(DATE_FORMAT)}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <Typography sx={{ mr: 1 }} component="span" variant="body2" color="text.primary">
          {Localize.get('Events.MinParticipants')}:
        </Typography>
      </ListItem>
      <ListItem>
        <Box data-test-id="minParticipantsProgressBar" sx={{ width: '100%' }}>
          <LinearProgressWithLabel
            progressColor="success.main"
            value={percentageMin > 100 ? 100 : percentageMin}
            currentValue={currentParticipants}
            totalValue={minParticipants}
          />
        </Box>
      </ListItem>
      <ListItem>
        <Typography sx={{ mr: 1 }} component="span" variant="body2" color="text.primary">
          {Localize.get('Events.MaxParticipants')}:
        </Typography>
      </ListItem>
      <ListItem>
        <Box data-test-id="maxParticipantsProgressBar" sx={{ width: '100%' }}>
          <LinearProgressWithLabel
            progressColor="error.main"
            value={percentage(currentParticipants, maxParticipants)}
            currentValue={currentParticipants}
            totalValue={maxParticipants}
          />
        </Box>
      </ListItem>
    </Box>
  );
};

export default BasicInfo;
