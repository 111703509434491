import moment from 'moment';

export const SORT_DIRECTION = {
  ASCENDING: 'asc',
  DESCENDING: 'desc'
};

export const TAB_KEYS = {
  ATTACHMENT: 'attachment',
  NOTE: 'note',
  SESSION: 'session',
  PRICE: 'price',
  EMAIL: 'email',
  CONTACT: 'contact',
  APPOINTMENT: 'appointment',
  TASK: 'task',
  ACCOMMODATION: 'accommodation',
  BOOKING_CONTINGENT: 'hotel',
  CONTINGENT: 'hotel',
  RESOURCE: 'resource',
  PARTICIPANTS: 'participant',
  SESSION_ROOMS: 'meeting_room',
  SESSION_DAY: 'session_days',
  HOTEL: 'hotel',
  HOTEL_BOOKING: 'hotel_bookings',
  MEETING_ROOM: 'meeting_room',
  OVERVIEW: 'overview',
  PARTICIPATION: 'participations',
  WAITING_LIST: 'waiting_list',
  TEAM: 'team'
};

export const dateInitFormats = {
  birthDate: 'YYYY-MM-DD',
  arrivalDate: 'YYYY-MM-DD',
  departureDate: 'YYYY-MM-DD',
  basicDate: 'YYYY-MM-DD',
  dateTime: 'YYYY-MM-DDTHH:mm',
  dateTimeWithSeconds: 'YYYY-MM-DDTHH:mm:ss',
  time: 'HH:mm',
  monthDay: 'DD.MM'
};

export const dateDetailsFormats = {
  startDateTime: 'YYYY-MM-DD HH-mm',
  endDateTime: 'YYYY-MM-DD HH-mm'
};

export const dateFormatTime = {
  dateTime: 'YYYY-MM-DD HH:mm'
};

export const ACTION_MODES = {
  Create: 'create',
  Edit: 'edit',
  Delete: 'delete',
  Upload: 'upload',
  Add: 'add',
  Removed: 'removed'
};

export const PERSON_TYPES = {
  PARTICIPANT: 'participant'
};

export const BUSINESS_PARTNER_TYPE = {
  ACCOUNT: 2,
  INDIVIDUAL_CUSTOMER: 1
};

export const NAVIGATE_TOASTER_TIMEOUT = 300;

export const DATE_FORMAT_IN_RANGE = 'YYYY-MM-DDThh:mm';

export const CAL_BAR_STATE_FORMAT = 'YYYY-MM-DD';

export const DATE_PICKER_MIN_BIRTH_DATE = moment(new Date(1900, 1, 1)).toDate();

export const WORK_EMAIL_TYPE_ID = 2;

export const MOBILE_PHONE_TYPE_ID = 1;

// Since setValues is async, we need this timeout to focus field without getting validation error
export const FOCUS_FIELD_TIMEOUT = 200;

// Sets default sorting order for tables. Default is ['asc', 'desc', null]
export const SORTING_ORDER = [SORT_DIRECTION.ASCENDING, SORT_DIRECTION.DESCENDING];

const API = {
  API_URL: process.env.REACT_APP_API_URL,
  TEM_APP_URL: process.env.REACT_APP_TEM_APP_URL,
  TEM_APP_O_DATA: process.env.REACT_APP_TEM_APP_O_DATA,
  SAML_API_URL: process.env.REACT_APP_SAML_API_URL
};

export { API };
