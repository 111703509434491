import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import CreateFooter from '@components/CreateFooter';
import CreateForm from '@components/CreateForm';
import Header from '@components/Header';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { eventsPath } from '@navigation/routes/Routes';
import { createEvent } from '@pages/Events/eventsSlice';
import initialValues from '@pages/Events/util/initialValues';
import useCreateSchema from '@pages/Events/util/schemas/useCreateSchema';

import { getFieldsConfig } from './getFieldsConfig';

const CreateEvent = () => {
  const {
    data: {
      type: { data: type },
      status: { data: status },
      nativeLanguage: { data: nativeLanguage }
    }
  } = useMatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventsValidationSchema = useCreateSchema();

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createEvent(values))
      .unwrap()
      .then(() =>
        navigate({
          to: eventsPath,
          search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
          replace: false
        })
      )
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'EVENT', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const onCancel = () =>
    navigate({ to: eventsPath, search: () => ({ activeTab: TAB_KEYS.OVERVIEW }), replace: true });

  return (
    <>
      <Header>{Localize.get('Events.NewEvent')}</Header>

      <Formik
        validateOnMount
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={eventsValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, handleSubmit, isValid, setFieldValue, values, setValues }) => (
          <>
            <CreateForm
              values={values}
              fieldsConfig={getFieldsConfig(
                setFieldValue,
                values,
                setValues,
                nativeLanguage,
                type,
                status
              )}
            />
            <CreateFooter>
              <Button
                data-test-id="cancel-btn"
                variant="outlined"
                onClick={onCancel}
                color="error"
                startIcon={<CancelIcon />}
              >
                {Localize.get('Buttons.Cancel')}
              </Button>

              <Button
                data-test-id="save-btn"
                variant="outlined"
                disabled={isSubmitting || !isValid}
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
              >
                {Localize.get('Buttons.Save')}
              </Button>
            </CreateFooter>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateEvent;
