import { useState, useEffect } from 'react';

import { SEARCH_INPUT_DELAY } from '@config/inputs';
import { TABLE_OPTIONS } from '@config/network';
import useFetching from '@hooks/fetching/fetching';
import useDebounce from '@hooks/handlers/useDebounce';
import { getByPathAndParams } from '@services/BaseApi';

const useDialogSearch = (path, initQueryState) => {
  const [queryState, setQueryState] = useState({ ...initQueryState });
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(TABLE_OPTIONS.DIALOG_PAGE_SIZE_OPTIONS[0]);

  const [data, loadingData, reFetchList, , pagination] = useFetching(
    getByPathAndParams.bind(null, {
      path,
      params: {
        ...queryState,
        page: activePage,
        size: pageSize
      }
    })
  );

  const itemHandler = (value) => {
    setActivePage(0);
    if (value?.items?.length) {
      let item = value.items[0];

      setQueryState({ ...queryState, [item?.columnField]: item.value ?? '' });
    }
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  useDebounce(() => reFetchList(), SEARCH_INPUT_DELAY, [queryState, activePage]);

  useEffect(() => {
    reFetchList();
  }, [pageSize]);

  return {
    data,
    loadingData,
    setActivePage,
    pagination,
    activePage,
    itemHandler,
    changePageSize,
    pageSize,
    queryState
  };
};

export default useDialogSearch;
