import Localize from 'react-intl-universal';

import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { copyObjectWithoutRef, isDate } from '@common/helpers/helpers';
import scrubDtos from '@common/helpers/scrubDtos';

export const formatContactForSave = (newValues) => {
  const contactForSave = copyObjectWithoutRef(newValues);
  contactForSave.contactRelationships = [
    {
      contactId: 0,
      businessPartnerRoleId: 0,
      main: true
    }
  ];

  contactForSave.addressDtos = scrubDtos(contactForSave, 'addressDtos');
  contactForSave.emailDtos = scrubDtos(contactForSave, 'emailDtos');
  contactForSave.phoneDtos = scrubDtos(contactForSave, 'phoneDtos');
  contactForSave.organizationPerson = {
    id: newValues?.organizationPerson?.organizationPersonId || newValues?.organizationPerson?.id
  };

  contactForSave.person.birthDate = isDate(contactForSave.person.birthDate)
    ? dateToFormat(contactForSave.person.birthDate, dateInitFormats.birthDate)
    : null;
  return contactForSave;
};

export const formatDetailsResponse = (res) => {
  return {
    ...res,
    person: {
      ...res.person,
      title: res?.person?.title ?? { id: 0, value: '' },
      birthDate: res?.person?.birthDate ?? null
    }
  };
};

export const getCountriesOptions = (myOptions) => {
  return myOptions?.map((option) => {
    if (option?.label) {
      return {
        ...option,
        key: option?.translateKey ? Localize.get(option.key) : option.key,
        label: option?.translateKey ? Localize.get(option.label) : option.label
      };
    }
    return Localize.get(option);
  });
};
