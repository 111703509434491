import moment from 'moment';

import { CLASSROOM_TYPE_ID } from '../iltSessionSlice';

export default {
  seminarNumber: '',
  name: '',
  maxSeats: '',
  course: null,
  minSeats: '',
  iltModule: null,
  status: 1,
  type: CLASSROOM_TYPE_ID,
  startDateTime: moment(),
  endDateTime: moment().add(30, 'm'),
  venueDtos: [],
  isMeaPublished: false,
  hasWaitingList: false,
  hasAutomaticWaitingList: false,
  hasAutomaticCancellation: false,
  sessionDayDto: {
    name: '',
    dayDate: moment(),
    startTime: null,
    endTime: null,
    meetingRoomId: null,
    instructor: [],
    details: '',
    webinarUrl: '',
    hasReminder: false,
    reminderValue: 1,
    metricTypeId: 1
  },
  enableHotelRequest: false,
  hasRegistrationPeriod: false,
  registrationBeforeStart: 1,
  registrationBeforeStartMetricType: { id: 1 },
  hasCancellationDeadline: false,
  cancellationBeforeStart: 1,
  cancellationBeforeStartMetricType: { id: 1 },
  automaticCancellationBeforeStart: 1,
  automaticCancellationBeforeStartMetricType: { id: 1 }
};
