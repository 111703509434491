import React, { useState } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useMatch } from 'react-location';
import { useDispatch } from 'react-redux';

import { Form, Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import { ACTION_MODES, dateInitFormats, TAB_KEYS } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import { ACTIVITIES_PATHS } from '@common/network/ApiPaths';
import CreateFooter from '@components/CreateFooter';
import FormContainer from '@components/FormContainer';
import Header from '@components/Header';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { activitiesPath } from '@navigation/routes/Routes';
import { createActivity } from '@pages/Activities/activitiesSlice';
import appointment from '@pages/Activities/util/fields/appointment';
import create from '@pages/Activities/util/fields/create';
import task from '@pages/Activities/util/fields/task';
import initialValues from '@pages/Activities/util/initialValues';
import useCreateSchema from '@pages/Activities/util/schemas/useCreateSchema';
import { ACTIVITIES_STEPS } from '@pages/Activities/util/stepsConfig';

import ActivitiesForm from './steps/ActivitiesForm';
import ActivitiesType from './steps/ActivitiesType';
import AppointmentForm from './steps/AppointmentForm';
import TaskForm from './steps/TaskForm';

const CreateActivities = () => {
  const {
    data: {
      activityPriorities: { data: activityPriorities },
      activityStatuses: { data: activityStatuses },
      activityTaskCategory: { data: activityTaskCategory },
      activityAppointmentCategory: { data: activityAppointmentCategory },
      type: { data: type }
    }
  } = useMatch();
  const navigate = useNavigate();
  const [activitiesValidationSchema] = useCreateSchema();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createActivity(values))
      .unwrap()
      .then(() =>
        navigate({
          to: activitiesPath,
          search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
          replace: false
        })
      )
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'ACTIVITY', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const onCancel = () =>
    navigate({
      to: activitiesPath,
      search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
      replace: false
    });

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Header>{Localize.get('Activities.NewActivity')}</Header>
      <Stepper sx={{ p: '2rem' }} activeStep={activeStep}>
        {ACTIVITIES_STEPS.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{Localize.get(label)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Typography variant="h5" sx={{ textAlign: 'center', color: 'primary.main' }}>
        {Localize.get('Labels.Step')} {activeStep + 1}
      </Typography>

      <Box sx={{ width: '100%' }}>
        <Formik
          initialErrors
          validateOnMount
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={activitiesValidationSchema}
        >
          {({ handleChange, handleSubmit, values, isValid, setFieldValue }) => (
            <FormContainer>
              <Form>
                {/* 1st step Activities Form */}
                {activeStep === 0 && (
                  <ActivitiesForm
                    fieldsConfig={mapPropsToComponent(create, {
                      ['status']: {
                        path: ACTIVITIES_PATHS.ACTIVITIES_STATUSES,
                        options: activityStatuses
                      },
                      ['priority']: {
                        path: ACTIVITIES_PATHS.ACTIVITIES_PRIORITY,
                        options: activityPriorities
                      },
                      ['startDateTime']: {
                        ampm: false,
                        onChange: (value) =>
                          setFieldValue(
                            'startDateTime',
                            dateToFormat(value, dateInitFormats.dateTime)
                          )
                      },
                      ['endDateTime']: {
                        ampm: false,
                        onChange: (value) =>
                          setFieldValue(
                            'endDateTime',
                            dateToFormat(value, dateInitFormats.dateTime)
                          )
                      },
                      ['owner.id']: {
                        tokenValue:
                          values?.owner?.firstName && values.owner?.lastName
                            ? [{ title: `${values.owner.firstName} ${values.owner.lastName}` }]
                            : undefined,
                        onIconClick: (e) => {
                          if (e?.target?.name === 'delete')
                            setFieldValue('owner', initialValues.owner);
                        },
                        selectItem: (value) => {
                          setFieldValue('owner', { ...value, id: value?.personTypeId });
                        }
                      },
                      ['contact']: {
                        tokenValue:
                          values?.contact?.firstName && values.contact?.lastName
                            ? [{ title: `${values.contact.firstName} ${values.contact.lastName}` }]
                            : undefined,
                        onIconClick: (e) => {
                          if (e?.target?.name === 'delete')
                            setFieldValue('contact', initialValues.contact);
                        },
                        selectItem: (value) => {
                          setFieldValue('contact', { ...value, id: value?.personTypeId });
                        }
                      },
                      ['account']: {
                        tokenValue:
                          values?.account?.id && values.account?.name
                            ? [
                                {
                                  title: `${values.account.name} `
                                }
                              ]
                            : undefined,
                        onIconClick: (e) => {
                          if (e?.target?.name === 'delete')
                            setFieldValue('account', initialValues.account);
                        },
                        selectItem: (value) => {
                          setFieldValue('account', {
                            ...value
                          });
                        }
                      }
                    })}
                  />
                )}
                {/* 2nd step Activities Type */}
                {activeStep === 1 && (
                  <ActivitiesType
                    values={values}
                    handleChange={(values) => handleChange(values)}
                    options={type}
                    path={ACTIVITIES_PATHS.ACTIVITIES_TYPES}
                  />
                )}
                {/* 3nd step Activities Type */}
                {activeStep === 2 && values?.type === 2 && (
                  <TaskForm
                    fieldsConfig={mapPropsToComponent(task, {
                      ['taskDto.taskCategory']: {
                        formItemChangeHandler: (e, data) => {
                          setFieldValue('taskDto.taskCategory', data.newValue);
                        },
                        path: ACTIVITIES_PATHS.ACTIVITIES_TASK_CATEGORIES,
                        options: activityTaskCategory
                      },
                      ['taskDto.completion']: {
                        onChange: (e) => {
                          setFieldValue('taskDto.completion', e.target.value);
                        }
                      },
                      ['taskDto.processor']: {
                        tokenValue:
                          values?.taskDto.processor?.firstName && values.taskDto.processor?.lastName
                            ? [
                                {
                                  title: `${values.taskDto.processor.firstName} ${values.taskDto.processor.lastName} `
                                }
                              ]
                            : undefined,
                        onIconClick: (e) => {
                          if (e?.target?.name === 'delete')
                            setFieldValue('taskDto.processor', initialValues.taskDto.processor);
                        },
                        selectItem: (value) => {
                          setFieldValue('taskDto.processor', value);
                        }
                      }
                    })}
                  />
                )}
                {/* 3nd step Activities Type */}
                {activeStep === 2 && values?.type === 1 && (
                  <AppointmentForm
                    fieldsConfig={mapPropsToComponent(appointment, {
                      ['appointmentDto.appointmentCategory']: {
                        formItemChangeHandler: (e, data) => {
                          setFieldValue('appointmentDto.appointmentCategory', data.newValue);
                        },
                        path: ACTIVITIES_PATHS.ACTIVITIES_APPOINTMENTS_CATEGORIES,
                        options: activityAppointmentCategory
                      }
                    })}
                  />
                )}
              </Form>
              <CreateFooter>
                <>
                  <Button
                    data-test-id="cancel-btn"
                    variant="outlined"
                    startIcon={<CancelIcon />}
                    color="error"
                    onClick={onCancel}
                    sx={{ mr: 1 }}
                  >
                    {Localize.get('Buttons.Cancel')}
                  </Button>
                  <Button
                    data-test-id="back-btn"
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                    sx={{ mr: 1 }}
                    startIcon={<NavigateBeforeIcon />}
                  >
                    {Localize.get('Buttons.Back')}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  {activeStep === ACTIVITIES_STEPS.length - 1 ? (
                    <Button
                      data-test-id="save-btn"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      disabled={!isValid}
                      onClick={handleSubmit}
                    >
                      {Localize.get('Buttons.Save')}
                    </Button>
                  ) : (
                    <Button
                      data-test-id="next-btn"
                      endIcon={<NavigateNextIcon />}
                      variant="outlined"
                      disabled={!isValid}
                      onClick={handleNext}
                    >
                      {Localize.get('Buttons.Next')}
                    </Button>
                  )}
                </>
              </CreateFooter>
            </FormContainer>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default CreateActivities;
