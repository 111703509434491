import NotFound from '@pages/NotFound';

import { catchPath } from '../Routes';

const NotFoundRoute = () => ({
  path: catchPath,
  element: <NotFound />
});

export default NotFoundRoute;
