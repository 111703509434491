import React, { useCallback, memo, Fragment } from 'react';
import Localize from 'react-intl-universal';

import { Field, FieldArray, Form } from 'formik';
import { isObject } from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';

import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import DatePicker from '@components/DatePicker';
import SelectInput from '@components/SelectInput';
import TextInput from '@components/TextInput';

import { FILTER_KEYS, FILTER_OPERATORS, LOGICAL_OPERATORS } from '../config/operators';
const MAX_FILTER_ROWS = 5;

const FormArray = ({ config = [], values = {}, setFieldValue = () => {} }) => {
  const getOperatorOptionsByType = (index) => {
    const field = config.find((field) => {
      const type = values.filters[index].type;
      return field.type === type;
    });

    return field
      ? FILTER_OPERATORS[field.type].map((item) => ({ ...item, label: Localize.get(item.label) }))
      : [];
  };

  const renderFieldByType = useCallback(({ type = '', options = [], operator = null }, index) => {
    // Don't render for boolean operators
    if (operator?.key === FILTER_KEYS.IsEmpty || operator?.key === FILTER_KEYS.IsNotEmpty) {
      return null;
    }

    switch (type) {
      case 'boolean':
        return (
          <Field
            required
            label={Localize.get('Tables.Value')}
            name={`filters.${index}.value`}
            component={SelectInput}
            options={options}
          />
        );

      case 'number':
        return (
          <Field
            type="number"
            required
            label={Localize.get('Tables.Value')}
            name={`filters.${index}.value`}
            component={TextInput}
          />
        );

      case 'select':
        return (
          <Field
            required
            label={Localize.get('Tables.Value')}
            name={`filters.${index}.value`}
            accessors={{ valueAccessor: 'key', textAccessor: 'label' }}
            component={SelectInput}
            options={options}
          />
        );

      case 'date':
        return (
          <Field
            required
            label={Localize.get('Tables.Value')}
            name={`filters.${index}.value`}
            onChange={(newValue) => {
              setFieldValue(
                `filters.${index}.value`,
                new Date(dateToFormat(newValue, dateInitFormats.basicDate))
              );
            }}
            component={DatePicker}
          />
        );

      default:
        return (
          <Field
            required
            label={Localize.get('Tables.Value')}
            name={`filters.${index}.value`}
            component={TextInput}
          />
        );
    }
  }, []);

  const onFieldChange = useCallback((e, index) => {
    const field = config.find((field) => field.id === e.target.value);

    setFieldValue(`filters.${index}.id`, e.target.value);
    setFieldValue(`filters.${index}.type`, field.type);
    setFieldValue(`filters.${index}.operator`, field.operator);
    setFieldValue(`filters.${index}.options`, field?.options || null);

    setFilterInitialValue(field, index);
  }, []);

  const isFirstFilter = (index) => index === 0;

  const getLogicalOperators = LOGICAL_OPERATORS.map((operator) => ({
    ...operator,
    label: Localize.get(operator.label)
  }));

  const setFilterInitialValue = (field, index) => {
    switch (field.type) {
      case 'boolean':
      case 'select': {
        setFieldValue(
          `filters.${index}.value`,
          isObject(field.options[0]) ? field.options[0].key : field.options[0]
        );
        break;
      }

      case 'date': {
        setFieldValue(`filters.${index}.value`, null);
        break;
      }

      case 'number': {
        setFieldValue(`filters.${index}.value`, 0);
        break;
      }

      default:
        setFieldValue(`filters.${index}.value`, '');
    }
  };

  return (
    <Form>
      <FieldArray name="filters">
        {({ remove, push }) => (
          <Box>
            {values.filters.length > 0 &&
              values.filters.map((field, index) => (
                <Fragment key={index}>
                  <Box
                    sx={{ mb: 2, mr: 1 }}
                    key={index}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    {!isFirstFilter(index) && (
                      <>
                        <IconButton
                          disableFocusRipple
                          onClick={() => remove(index)}
                          color="error"
                          edge="start"
                        >
                          <CloseIcon />
                        </IconButton>
                        <FormControl sx={{ minWidth: 100 }} size="small">
                          <Field
                            disabled
                            value={Localize.get('Labels.And')}
                            name={`filters.${index}.logical`}
                            accessors={{ valueAccessor: 'key', textAccessor: 'label' }}
                            options={getLogicalOperators}
                            component={SelectInput}
                          />
                        </FormControl>
                      </>
                    )}

                    <FormControl
                      sx={{ ...(!isFirstFilter(index) && { ml: 1 }) }}
                      size="small"
                      fullWidth
                    >
                      <Field
                        label="Field"
                        name={`filters.${index}.id`}
                        options={config}
                        accessors={{ valueAccessor: 'id', textAccessor: 'label' }}
                        component={SelectInput}
                        onChange={(e) => onFieldChange(e, index)}
                      />
                    </FormControl>

                    <FormControl sx={{ ml: 1 }} size="small" fullWidth>
                      <Field
                        label="Operator"
                        name={`filters.${index}.operator.key`}
                        options={getOperatorOptionsByType(index)}
                        accessors={{ valueAccessor: 'key', textAccessor: 'label' }}
                        component={SelectInput}
                      />
                    </FormControl>

                    <FormControl sx={{ ml: 1 }} size="small" fullWidth>
                      {renderFieldByType(field, index)}
                    </FormControl>
                  </Box>
                </Fragment>
              ))}
            <Button
              disabled={values?.filters.length === MAX_FILTER_ROWS || config.length === 1}
              type="button"
              sx={{ mt: 2 }}
              disableFocusRipple
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => push({ ...config[0], value: '' })}
            >
              {Localize.get('Buttons.AddFilter')}
            </Button>
          </Box>
        )}
      </FieldArray>
    </Form>
  );
};

export default memo(FormArray);
