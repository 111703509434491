import { useNavigate } from 'react-location';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

import { iltSessionsPath } from '@navigation/routes/Routes';

const useStyles = makeStyles((theme) => ({
  sessionRow: {
    '& > *': { borderBottom: 'unset' },
    display: 'table',
    width: '100%',
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]}`,
      cursor: 'pointer'
    }
  }
}));

function SessionRow({ session = {} }) {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Table>
      <TableBody>
        <TableRow
          key={module.id}
          className={classes.sessionRow}
          onClick={() =>
            navigate({ to: iltSessionsPath, replace: false, search: { externalId: session.id } })
          }
        >
          <TableCell align="left" sx={{ width: '16%' }}>
            {session.name}
          </TableCell>
          <TableCell align="left" sx={{ width: '16%' }}>
            {session.type}
          </TableCell>
          <TableCell align="left" sx={{ width: '16%' }}>
            {session.instructor}
          </TableCell>
          <TableCell align="left" sx={{ width: '16%' }}>
            {session.location}
          </TableCell>
          <TableCell align="left" sx={{ width: '16%' }}>
            {session.startDate}
          </TableCell>
          <TableCell align="left" sx={{ width: '16%' }}>
            {session.endDate}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default SessionRow;
