import React, { Fragment } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';

import { get } from 'lodash';
import moment from 'moment';

import { Box, Link, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { isDateStringValid, isStringDateChecker } from '@common/helpers/dates';
import { getMultiple } from '@common/helpers/getMultiple';
import { checkLanguage } from '@common/helpers/languages';
import Skeleton from '@components/CustomSkeleton/CustomSkeleton';
import NoData from '@components/NoData/NoData';
import TabContainer from '@components/TabContainer/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import { DATE_FORMAT } from '@config/inputs';

moment.locale(checkLanguage());

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    minHeight: '3rem'
  },
  content: {
    overflow: 'hidden',
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: `calc(100vh - (${theme.sizes.tabsHeight} + ${theme.sizes.subheader} + ${theme.sizes.appHeader} + 2rem))`
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`
    }
  }
}));

const Overview = ({ isLoading = false, data = null, config = null, invite = null }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Labels.Overview')}
          </Typography>
        </div>
        <TabContainer>
          {isLoading ? (
            <Box display="flex" flexDirection="column">
              <Skeleton
                skeletonCount={config.skeletonCount}
                skeletonProps={{
                  variant: 'rectangular',
                  height: '40px',
                  width: '400px',
                  sx: { mb: 1 }
                }}
              />
            </Box>
          ) : data && config ? (
            <Paper sx={{ height: '400px', width: config.width || '400px' }}>
              <Fragment>
                {/* Header, Icon, Header Title */}
                {config?.header?.title ? (
                  <>
                    <ListItem>
                      {config?.header?.icon && (
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: 'accent.main' }}>
                            <Icon>{config.header.icon}</Icon>
                          </Avatar>
                        </ListItemAvatar>
                      )}
                      <ListItemText
                        data-test-id="overview-id"
                        primary={Localize.get(config?.header.title).d(config?.header.title)}
                        secondary={
                          getMultiple(data, config?.header.subTitle)
                            ? getMultiple(data, config?.header.subTitle, '').join(' ')
                            : Localize.get(config?.header.subTitle)
                        }
                      />
                    </ListItem>
                    <Divider />
                  </>
                ) : null}

                {/* Fields  */}
                {config?.details?.map((item, index) => (
                  <Fragment key={index}>
                    <ListItem>
                      <ListItemText
                        data-test-id={`overviewField-${item.name}`}
                        sx={{ whiteSpace: 'nowrap' }}
                        primary={
                          <>
                            <Typography
                              data-test-id={`overviewFieldLabel-${item.name}`}
                              sx={{ display: 'inline-block', mr: 1 }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {Localize.get(item.label)}:
                            </Typography>
                            {isStringDateChecker(data, item.name) ? (
                              <Tooltip
                                title={get(data, item.name, item?.defaultValue)}
                                placement="top"
                                arrow
                              >
                                <Typography
                                  data-test-id={`overviewFieldValue-${item.name}`}
                                  noWrap={true}
                                  component="span"
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{
                                    width: `calc(100% - ${Localize.get(item.label).length * 8}px)`,
                                    display: 'inline-block',
                                    verticalAlign: 'middle'
                                  }}
                                >
                                  {isDateStringValid(get(data, item.name, item?.defaultValue))
                                    ? moment(get(data, item.name, item?.defaultValue)).format(
                                        DATE_FORMAT
                                      )
                                    : get(data, item.name, item?.defaultValue)}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography
                                data-test-id={`overviewFieldEmpty-${item.name}`}
                                noWrap={true}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  width: `calc(100% - ${Localize.get(item.label).length * 8}px)`,
                                  display: 'inline-block',
                                  verticalAlign: 'middle'
                                }}
                              >
                                {isDateStringValid(get(data, item.name, item?.defaultValue))
                                  ? moment(get(data, item.name, item?.defaultValue)).format(
                                      DATE_FORMAT
                                    )
                                  : get(data, item.name, item?.defaultValue)}
                              </Typography>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </Fragment>
            </Paper>
          ) : (
            <NoData
              titleText={Localize.get('NoDataMessage.TitleTextOverview')}
              subtitleText={
                <Link
                  onClick={() => navigate({ to: invite?.link, replace: false })}
                  sx={{
                    cursor: 'pointer',
                    fontSize: '16px'
                  }}
                >
                  {invite?.text}
                </Link>
              }
            />
          )}
        </TabContainer>
      </TabContent>
    </div>
  );
};

export default Overview;
