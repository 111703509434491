import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';

import fieldsCreateTask from './fieldsCreateTask';
import tasksInitialValues from './tasksInitialValues';

export const getFieldsConfig = (
  setFieldValue,
  values,
  activityStatuses,
  activityPriorities,
  activityTaskCategories
) => {
  return mapPropsToComponent(fieldsCreateTask, {
    ['account']: {
      tokenValue:
        values?.account?.id && values?.account?.name
          ? [
              {
                title: `${values.account.name} `
              }
            ]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') setFieldValue('account', tasksInitialValues.account);
      },
      selectItem: (value) => {
        setFieldValue('account', {
          ...value
        });
      }
    },
    ['taskDto.processor']: {
      tokenValue:
        values?.taskDto?.processor?.firstName && values.taskDto?.processor?.lastName
          ? [
              {
                title: `${values.taskDto.processor.firstName} ${values.taskDto.processor.lastName} `
              }
            ]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete')
          setFieldValue('taskDto.processor', tasksInitialValues.taskDto.processor);
      },
      selectItem: (value) => {
        setFieldValue('taskDto.processor', value);
      }
    },
    ['owner']: {
      tokenValue:
        values?.owner?.firstName && values?.owner?.lastName
          ? [{ title: `${values.owner.firstName} ${values.owner.lastName}` }]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') setFieldValue('owner', tasksInitialValues.owner);
      },
      selectItem: (value) => {
        setFieldValue('owner', { ...value, id: value?.personTypeId });
      }
    },
    ['statusId']: {
      options: activityStatuses
    },
    ['priorityId']: {
      options: activityPriorities
    },
    ['taskDto.taskCategory']: {
      options: activityTaskCategories
    },
    ['startDateTime']: {
      ampm: false,
      onChange: (value) =>
        setFieldValue('startDateTime', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['endDateTime']: {
      ampm: false,
      onChange: (value) =>
        setFieldValue('endDateTime', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['contact']: {
      tokenValue:
        values?.contact?.firstName && values?.contact?.lastName
          ? [{ title: `${values.contact.firstName} ${values.contact.lastName}` }]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') setFieldValue('contact', tasksInitialValues.contact);
      },
      selectItem: (value) => {
        setFieldValue('contact', { ...value, id: value?.personTypeId });
      }
    },
    ['taskDto.completion']: {
      onChange: (e) => {
        setFieldValue('taskDto.completion', e.target.value);
      }
    }
  });
};

export default getFieldsConfig;
