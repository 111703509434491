import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

const allFilters = [
  {
    label: 'Personal.Number',
    id: 'personnelNumber',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Personal.CostCenter',
    id: 'costCentre',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.CompanyName',
    id: 'companyName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Employees.FirstName',
    id: 'firstName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Employees.LastName',
    id: 'lastName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Participants.Title.Title',
    id: 'title',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: [
      { label: 'Participants.Title.Mr', key: 'Participants.Title.Mr', translateKey: true },
      { label: 'Participants.Title.Ms', key: 'Participants.Title.Ms', translateKey: true },
      { label: 'Participants.Title.Mrs', key: 'Participants.Title.Mrs', translateKey: true }
    ]
  },
  {
    label: 'Participants.Country',
    id: 'country',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: []
  },
  {
    label: 'Participants.City',
    id: 'city',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'ContactsTile.PostalCode',
    id: 'postalCode',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  }
];

export default allFilters;
