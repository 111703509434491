import { isNil } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  NAVIGATE_TOASTER_TIMEOUT,
  SORT_DIRECTION,
  TAB_KEYS
} from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { PARTICIPANTS_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { scrubFiltersForBE } from '@components/FilterDialog/filtersSlice';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { MASTER_LIST_PAGE_SIZE } from '@config/network';
import { fetchParticipants as fetchEventParticipants } from '@pages/Events/components/Tabs/ParticipantsTable/participantTableSlice';
import { setDetails as setEventDetails } from '@pages/Events/eventsSlice';
import {
  deleteByPath,
  getByPathAndParams,
  postByPathAndData,
  putByPathAndData
} from '@services/BaseApi';

import {
  formatParticipantForSave,
  formatParticipantForDetails,
  formatParticipantForEdit
} from './utils/helpers/Util';
import { SORT_DATA } from './utils/sortConfig';

export const initialState = {
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.DESCENDING,
    page: 0,
    size: MASTER_LIST_PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  details: null,
  isLoading: false,
  isDetailsLoading: false,
  isConfirmDialogOpen: false,
  occupancy: 0
};

export const PARTICIPANTS_SLICE = 'participants';

export const fetchParticipants = createAsyncThunk(
  `${PARTICIPANTS_SLICE}/fetchMasterList`,
  (filter, { getState }) => {
    const { isActive, filter: advancedFilters } = getState()?.FILTER_DIALOG_SLICE;

    return getByPathAndParams({
      path: PARTICIPANTS_PATHS.GET_MASTER_LIST,
      params: isActive
        ? {
            filters: { advancedFilters: scrubFiltersForBE(advancedFilters, false) },
            ...filter
          }
        : { ...filter }
    })
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const fetchParticipantDetails = createAsyncThunk(
  `${PARTICIPANTS_SLICE}/fetchDetails`,
  (selectedId) =>
    getByPathAndParams({
      path: PARTICIPANTS_PATHS.GET_DETAILS,
      pathVariables: { id: selectedId }
    })
      .then((response) => {
        let res = response.data;

        return formatParticipantForDetails(res);
      })
      .catch((error) => error),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const createParticipant = createAsyncThunk(
  `${PARTICIPANTS_SLICE}/create`,
  ({ data, isNavigatedFromParticipantTabInEvents }, { dispatch, rejectWithValue, getState }) => {
    let participantForSave = formatParticipantForSave(data, {});
    const { selectedId } = getState()?.EVENTS_SLICE;
    const { filter } = getState()?.EVENTS_PARTICIPANT_SLICE;

    return postByPathAndData({
      path: PARTICIPANTS_PATHS.POST,
      data: participantForSave
    })
      .then(async (response) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.PARTICIPANT, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);

        if (isNavigatedFromParticipantTabInEvents) {
          const { totalElements = 0 } = await dispatch(
            fetchEventParticipants({ entityId: selectedId, filter })
          ).unwrap();
          dispatch(setEventDetails({ counts: { [TAB_KEYS.PARTICIPANTS]: totalElements } }));
        }
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const deleteParticipant = createAsyncThunk(`${PARTICIPANTS_SLICE}/delete`, (id, thunkAPI) =>
  deleteByPath({
    path: PARTICIPANTS_PATHS.DELETE,
    pathVariables: { id }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
      return response.data;
    })
    .catch((error) => {
      thunkAPI.dispatch(
        showSnackbar({
          message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
          severity: SnackbarSeverityTypes.ERROR
        })
      );

      return error.response;
    })
);

export const saveParticipant = createAsyncThunk(`${PARTICIPANTS_SLICE}/save`, (newData, thunkAPI) =>
  putByPathAndData({
    path: PARTICIPANTS_PATHS.PUT,
    data: formatParticipantForEdit(newData),
    pathVariables: { id: newData.id }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PARTICIPANT, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
      return response.data;
    })
    .catch((error) => thunkAPI.rejectWithValue(error.response))
);

export const participantsSlice = createSlice({
  name: PARTICIPANTS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setConfirmationDialog: (state, action) => {
      state.isConfirmDialogOpen = action.payload;
    },
    setOccupancy: (state, action) => {
      state.occupancy = action.payload;
    },
    setDetails: (state, { payload }) => {
      state.details = formatParticipantForDetails(deepMerge(state.details || {}, payload));
    },
    deleteSuccess: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.selectedId = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.isConfirmDialogOpen = false;
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: 0 };
    },
    resetPage: (state) => {
      state.filter.page = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParticipants.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content || state.data;
        state.totalPages = payload?.totalPages ?? state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.selectedId = payload?.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
      })
      .addCase(fetchParticipants.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchParticipants.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(fetchParticipantDetails.fulfilled, (state, { payload }) => {
        state.details = payload;
        state.isDetailsLoading = false;
      })
      .addCase(fetchParticipantDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchParticipantDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      .addCase(deleteParticipant.fulfilled, (state, action) => {
        state.isConfirmDialogOpen = false;
        state.totalElements -= 1;
        state.isLoading = false;
        state.details = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      })
      .addCase(createParticipant.fulfilled, (state, { payload }) => {
        state.filter.page = 0;
        state.selectedId = payload?.id;
        state.isLoading = false;
      })
      .addCase(createParticipant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveParticipant.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) => {
          return item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg) : item;
        });
        state.details = deepMerge(state.details, action.meta.arg);
      })
      .addCase(saveParticipant.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveParticipant.rejected, (state) => {
        state.isDetailsLoading = false;
      });
  }
});

export const selectId = (state) => state.PARTICIPANTS_SLICE.selectedId;
export const selectList = (state) => state.PARTICIPANTS_SLICE.data;
export const selectFilter = (state) => state.PARTICIPANTS_SLICE.filter;
export const selectTotalElements = (state) => state.PARTICIPANTS_SLICE.totalElements;
export const selectTotalPages = (state) => state.PARTICIPANTS_SLICE.totalPages;
export const selectDetails = (state) => state.PARTICIPANTS_SLICE.details;
export const selectIsConfirmDialogOpen = (state) => state.PARTICIPANTS_SLICE.isConfirmDialogOpen;
export const selectIsLoading = (state) => state.PARTICIPANTS_SLICE.isLoading;
export const selectIsDetailsLoading = (state) => state.PARTICIPANTS_SLICE.isDetailsLoading;
export const selectOccupancy = (state) => state.PARTICIPANTS_SLICE.occupancy;

const { actions, reducer } = participantsSlice;

export const {
  setError,
  setLoading,
  setSelectedId,
  setConfirmationDialog,
  deleteSuccess,
  setFilterParams,
  resetPage,
  resetState,
  setDetails,
  setOccupancy
} = actions;

export default reducer;
