import { get } from 'lodash';
import moment from 'moment';

import { dateInitFormats } from '@common/Constants';

import { isDate } from '../helpers';

const formatToDate = (providedDate) => {
  if (!providedDate) return null;
  if (providedDate) {
    let newMomentDate = moment(providedDate);
    if (newMomentDate.isValid()) {
      return newMomentDate.utc().toDate();
    }
    return new Date();
  }
  return new Date();
};

const dateToFormat = (providedDate, outputFormat = 'YYYY-MM-DD HH-mm', lang = 'en') => {
  if (!providedDate) return null;
  return moment(providedDate).locale(lang).format(outputFormat);
};

const formatToFormat = (providedDate, inputFormat, outputFormat) => {
  if (!providedDate) return null;
  return moment(providedDate, inputFormat).format(outputFormat);
};

const combineDateAndTime = (date, time) => {
  const fDate = moment(date).format(dateInitFormats.basicDate);
  const fTime = moment(time).format(dateInitFormats.time);

  return moment(`${fDate} ${fTime}`, dateInitFormats.dateTime).toDate();
};

const calculateDayDifference = (firstDate, secondDate) => {
  let firstIsValid = isDate(firstDate);
  let secondIsValid = isDate(secondDate);
  if (firstIsValid && secondIsValid) {
    const a = moment(firstDate);
    const b = moment(secondDate);
    return b.diff(a, 'days');
  }
  return 0;
};

function enumerateDaysBetweenDates(startDate, endDate) {
  let date = [];
  while (moment(startDate).add(1, 'd') <= moment(endDate)) {
    date.push(moment(startDate).format('YYYY-MM-DD'));
    // eslint-disable-next-line no-param-reassign
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
  }
  return date;
}

const startOfMonthToMoment = (date) => {
  return moment(date).startOf('month');
};

const endOfMonthToMoment = (date) => {
  return moment(date).endOf('month');
};

const getStartOfMonthFormated = (date, outputFormat) => {
  let startOfMonth = startOfMonthToMoment(date);
  return startOfMonth.format(outputFormat);
};

const getEndOfMonthFormated = (date, outputFormat) => {
  let endOfMonth = endOfMonthToMoment(date);
  return endOfMonth.format(outputFormat);
};

const momentFormatValidation = (momentDate, inputFormat) => {
  return moment(momentDate, inputFormat, true).isValid();
};

function isDateStringValid(value) {
  // Validate that value is in the format YYYY-MM-DDTHH:MM:SSZ.
  if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value)) {
    return false;
  }

  // Validate that the values are also correct date values
  var d = new Date(value);

  return !isNaN(d.getTime());
}

const isStringDateChecker = (data, path) => {
  const dateFormatsInUse = Object.values(dateInitFormats);
  let isValueString = get(data, path);
  let isValueDate = moment(isValueString, dateFormatsInUse, true);

  if (isValueDate.isValid()) {
    return false;
  }
  if (typeof isValueString === 'string') {
    return true;
  }

  return false;
};

const isValidDate = (value) => !new Date(value).toString().includes('Invalid Date');

export {
  formatToFormat,
  dateToFormat,
  formatToDate,
  calculateDayDifference,
  startOfMonthToMoment,
  endOfMonthToMoment,
  getStartOfMonthFormated,
  getEndOfMonthFormated,
  momentFormatValidation,
  isDateStringValid,
  combineDateAndTime,
  isStringDateChecker,
  enumerateDaysBetweenDates,
  isValidDate
};
