import {
  activitiesPath,
  businessPartnersPath,
  calendarPath,
  contactsPath,
  coursesPath,
  emailsPath,
  employeesPath,
  eventsPath,
  iltSessionsPath,
  locationsPath,
  meetingRoomsPath,
  participantsPath,
  resourcesPath
} from '@navigation/routes/Routes';

export default [
  {
    type: 'SideNavigationItem',
    text: 'Launchpad.Planning',
    icon: 'calendar_month',
    sideNavigationSubItems: [
      {
        icon: 'date_range',
        text: 'Launchpad.EventsCalendarNavBar',
        linkName: calendarPath,
        isItemDisabled: false
      },
      {
        icon: 'email',
        text: 'Launchpad.Emails',
        linkName: emailsPath,
        isItemDisabled: true
      }
    ]
  },
  {
    type: 'SideNavigationItem',
    text: 'Launchpad.MasterDataNavBar',
    icon: 'stacked_bar_chart',
    sideNavigationSubItems: [
      {
        icon: 'badge',
        text: 'Launchpad.Employees',
        linkName: employeesPath,
        isItemDisabled: false
      },
      {
        icon: 'meeting_room',
        linkName: meetingRoomsPath,
        text: 'Launchpad.Rooms',
        isItemDisabled: false
      },
      {
        icon: 'schema',
        text: 'Launchpad.OrganisationalStructuresSidebar',
        linkName: '/org-structure',
        isItemDisabled: true
      },
      {
        icon: 'contact_mail',
        text: 'Launchpad.ContactsNavBar',
        linkName: contactsPath,
        isItemDisabled: false
      },
      {
        icon: 'insights',
        text: 'Launchpad.Resources',
        linkName: resourcesPath,
        isItemDisabled: false
      },
      {
        icon: 'business',
        text: 'Launchpad.BusinessPartnersSidebar',
        linkName: businessPartnersPath,
        isItemDisabled: false
      },
      {
        icon: 'maps',
        text: 'Launchpad.Locations',
        linkName: locationsPath,
        isItemDisabled: false
      }
    ]
  },
  {
    type: 'SideNavigationItem',
    text: 'Launchpad.TransactionalDataNavBar',
    icon: 'inventory',
    sideNavigationSubItems: [
      {
        icon: 'auto_stories',
        linkName: coursesPath,
        text: 'Launchpad.Courses',
        isItemDisabled: false
      },
      {
        icon: 'event_seat',
        linkName: iltSessionsPath,
        text: 'Launchpad.IltSession',
        isItemDisabled: false
      },
      {
        icon: 'format_list_bulleted',
        text: 'Launchpad.Activities',
        linkName: activitiesPath,
        isItemDisabled: false
      },
      {
        icon: 'insert_invitation',
        text: 'Launchpad.EventsNavBar',
        linkName: eventsPath,
        isItemDisabled: false
      },
      {
        icon: 'manage_accounts',
        text: 'Launchpad.Participants',
        linkName: participantsPath,
        isItemDisabled: false
      }
    ]
  }
];
