import React, { useState } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';

import { get } from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import DialogDataGrid from './DialogDataGrid';

function Tag(props) {
  const { label, onDelete, disabled, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon disabled={disabled} onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'};
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    max-width: ${theme.breakpoints.down('sm') ? '400px' : '500px'}
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const StyledIcon = styled(FilterNoneIcon)(
  () => `
    &:hover {
      cursor: pointer;
    }
  `
);

const TokenValueModalTrigger = ({
  field = {}, // { name, value, onChange, onBlur }
  form: { touched, errors } = {}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onIconClick = () => {},
  selectItem = () => {},
  selectionModel = [],
  extendedComponents,
  extendedComponentProps,
  dialogTitle = '',
  tokenValue = [],
  disabled = false,
  dialogColumns = [],
  fetchDataPath = '',
  initQueryState = {},
  getRowId,
  organizationPersonId = null,
  required = false,
  create = { to: '', search: {}, onClick: null },
  helperText = '',
  ...rest
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Dialog fullWidth maxWidth={'lg'} onClose={() => setModalOpen(false)} open={modalOpen}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {Localize.get(dialogTitle)}
        </DialogTitle>
        <DialogContent>
          {create.to && create.search && (
            <Tooltip title={Localize.get('Buttons.Add')}>
              <Fab
                data-test-id="dialog-add-btn"
                sx={{ position: 'absolute', left: '3rem', bottom: '3rem', zIndex: 9999999 }}
                color="primary"
                aria-label="add"
                onClick={() => {
                  navigate({ to: create.to, search: create.search });
                  create?.onClick && create?.onClick();
                }}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          )}

          <DialogDataGrid
            fetchDataPath={fetchDataPath}
            initQueryState={initQueryState}
            columns={dialogColumns}
            selectionModel={selectionModel}
            selectItem={selectItem}
            setModalOpen={setModalOpen}
            extendedComponents={extendedComponents}
            getRowId={getRowId}
            organizationPersonId={organizationPersonId}
            extendedComponentProps={extendedComponentProps}
          />
        </DialogContent>
      </Dialog>
      <TextField
        onKeyPress={(event) => event.preventDefault()}
        style={{ caretColor: 'transparent' }}
        autoComplete="off"
        readOnly={true}
        disabled={disabled}
        size="small"
        variant="outlined"
        error={Boolean(get(touched, field?.name) && get(errors, field?.name))}
        helperText={get(touched, field?.name) && get(errors, field?.name)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-test-id={`${field?.name}-open`}
                disabled={disabled}
                onClick={() => setModalOpen(true)}
              >
                <StyledIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: tokenValue?.length ? (
            <InputAdornment position="start">
              {tokenValue.map((option, index) => (
                <IconButton
                  data-test-id={field?.name}
                  disableRipple
                  disabled={disabled}
                  key={index}
                  onClick={() => onIconClick({ target: { name: 'delete', value: index } })}
                >
                  <StyledTag label={option.title} />
                </IconButton>
              ))}
            </InputAdornment>
          ) : null
        }}
        {...field}
        {...rest}
        required={required || Boolean(get(errors, field?.name))}
      />
      {helperText && (
        <FormHelperText id="component-helper-text">{Localize.get(helperText)}</FormHelperText>
      )}
    </>
  );
};

export default TokenValueModalTrigger;
