import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default () => {
  return yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.NameRequired')),
    instructors: yup
      .array()
      .nullable(true)
      .required(Localize.get('ValidationMessages.Instructor'))
      .min(1, Localize.get('ValidationMessages.Instructor')),
    reminderValue: yup
      .number()
      .min(1, Localize.get('ValidationMessages.MinNumber'))
      .required(Localize.get('ValidationMessages.NumberRequired'))
  });
};
