import { Navigate } from 'react-location';

import { ACTIVITIES_PATHS, SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import Activities, { CreateActivity } from '@pages/Activities';
import { getByPathAndParams } from '@services/BaseApi';

import { activitiesPath, newPath, rootPath } from '../Routes';

const ActivityRoute = (routeCache) => ({
  path: activitiesPath,
  children: [
    {
      path: rootPath,
      element: <Activities />,
      loader: routeCache.createLoader(
        async () => ({
          config: await getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.MEETING_ROOM }
          }),
          activityPriorities: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_PRIORITY
          }),
          activityStatuses: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_STATUSES
          }),
          activityTaskCategory: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_TASK_CATEGORIES
          }),
          activityAppointmentCategory: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_APPOINTMENTS_CATEGORIES
          }),
          type: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_TYPES
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: newPath,
      element: <CreateActivity />,
      loader: routeCache.createLoader(
        async () => ({
          activityPriorities: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_PRIORITY
          }),
          activityStatuses: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_STATUSES
          }),
          activityTaskCategory: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_TASK_CATEGORIES
          }),
          activityAppointmentCategory: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_APPOINTMENTS_CATEGORIES
          }),
          type: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_TYPES
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      element: <Navigate to={activitiesPath} />
    }
  ]
});

export default ActivityRoute;
