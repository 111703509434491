import Localize from 'react-intl-universal';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LOGIN_PATH } from '@common/network/ApiPaths';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { getByPathAndParams, postAuth, getAuthWithExternalToken } from '@services/BaseApi';

// export const postLogin = createAsyncThunk(
//   'login/post',
//   (loginData, { rejectWithValue, dispatch }) => {
//     return postAuth({
//       path: LOGIN_PATH.LOGIN,
//       loginData: loginData
//     })
//       .then((response) => response.data)
//       .catch((error) => {
//         dispatch(
//           showSnackbar({
//             message: Localize.get('Login.WrongEmailOrPass'),
//             severity: SnackbarSeverityTypes.ERROR
//           })
//         );
//         return rejectWithValue(error.response);
//       });
//   }
// );
export const LOGIN_SLICE = 'login';

export const postLogin = createAsyncThunk(
  `${LOGIN_SLICE}/post`,
  (loginData, { rejectWithValue, dispatch }) => {
    return postAuth({
      path: LOGIN_PATH.LOGIN,
      loginData: loginData
    })
      .then((response) => response.data)
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: Localize.get('Login.WrongEmailOrPass'),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error.response);
      });
  }
);

export const logOut = createAsyncThunk(
  `${LOGIN_SLICE}/regularLogout`,
  (data, { rejectWithValue }) => {
    return getByPathAndParams({
      path: LOGIN_PATH.LOGOUT,
      data: data
    })
      .then((response) => {
        if (response.data) {
          window.location.assign(response.data);
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response);
      });
  }
);

export const checkIsAuthEnabled = createAsyncThunk(`${LOGIN_SLICE}/isAuthEnabled`, () =>
  getByPathAndParams({
    path: LOGIN_PATH.CHECK
  })
    .then((response) => response.data)
    .catch((error) => error)
);

export const checkUser = createAsyncThunk(`${LOGIN_SLICE}/getUser`, (externalToken) =>
  getAuthWithExternalToken({
    path: 'user',
    externalToken
  })
    .then((response) => {
      console.log('data --->', response.data);
      return response.data;
    })
    .catch((error) => {
      console.log('e', error);
    })
);

let JWT_TOKEN = null;
let JWT_REFRESH = null;
try {
  const auth = window.localStorage.getItem('auth');

  if (auth) {
    JWT_TOKEN = JSON.parse(auth).token;
    JWT_REFRESH = JSON.parse(auth).refreshToken;
  } else {
    JWT_TOKEN = null;
    JWT_REFRESH = null;
  }
} catch (err) {
  JWT_TOKEN = null;
  JWT_REFRESH = null;
}

export const initialState = {
  token: JWT_TOKEN,
  refreshToken: JWT_REFRESH,
  isLoggedIn: !!JWT_TOKEN,
  isAuthEnabled: null
};

export const loginSlice = createSlice({
  name: LOGIN_SLICE,
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setExternalToken: (state, action) => {
      // ******* PROBABLY NOT USING ********  CHECK//
      console.log('state', state);
      console.log('action', action);
      // check roles

      // console.log('action.payload.roles --> ', action.payload.roles);

      // console.log('LIST ---> ', action.payload.roles.split(','));
      if (action.payload.roles) {
        // console.log('aa');
        let r = decodeURI(action.payload.roles);
        r = r.split(',').map((item) => item.trim());
        if (r.includes('Admin') || r.includes('Team Admin')) {
          console.log('');
        } else {
          window.open(
            `${process.env.REACT_APP_MEA_URL}tem?email=${action.payload.email}&token=${action.payload.externalToken}&roles=${action.payload.roles}`,
            '_self'
          );
          // console.log('pass  2');
          return;
        }
      }

      // console.log('pass  3');

      localStorage.setItem('auth', JSON.stringify({ token: action.payload.externalToken }));
      localStorage.setItem('email', JSON.stringify({ email: action.payload.email }));
      localStorage.setItem('roles', JSON.stringify({ roles: action.payload.roles }));
      localStorage.setItem('isExternal', JSON.stringify({ isExternal: true }));
      state.token = action.payload.externalToken;
      state.isLoggedIn = true;
      window.location.href = '/';
    },
    setNewExternalToken: (state, action) => {
      // ******* PROBABLY NOT USING ********  CHECK//
      console.log('state', state);
      console.log('action', action);
      // check roles

      // console.log('action.payload.roles --> ', action.payload.roles);

      // console.log('LIST ---> ', action.payload.roles.split(','));
      if (action?.payload?.roles.length > 0) {
        // console.log('aa');
        // let r = decodeURI(action.payload.roles);
        // r = r.split(',').map((item) => item.trim());

        let roles = action.payload.roles;
        if (roles.includes('Admin') || roles.includes('Team Admin')) {
          console.log('');
        } else {
          window.open(
            `${process.env.REACT_APP_MEA_URL}tem?email=${action.payload.email}&token=${
              action.payload.externalToken
            }&roles=${roles.join(',')}`,
            '_self'
          );
          // console.log('pass  2');
          return;
        }
      }

      // console.log('pass  3');

      localStorage.setItem('auth', JSON.stringify({ token: action.payload.externalToken }));
      localStorage.setItem('email', JSON.stringify({ email: action.payload.email }));
      localStorage.setItem('roles', JSON.stringify({ roles: action.payload.roles }));
      localStorage.setItem('isExternal', JSON.stringify({ isExternal: true }));
      state.token = action.payload.externalToken;
      state.isLoggedIn = true;
      window.location.href = '/';
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      if (!action.payload) {
        localStorage.removeItem('auth');
      }
      state.isLoggedIn = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.fulfilled, (state, { payload }) => {
        state.token = payload?.token;
        state.refreshToken = payload?.refreshToken;
        localStorage.setItem('auth', JSON.stringify(payload));
        state.isLoggedIn = true;
      })
      .addCase(postLogin.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.token = null;
        localStorage.removeItem('auth');
        localStorage.removeItem('isExternal');
      })
      .addCase(checkIsAuthEnabled.fulfilled, (state, { payload }) => {
        state.isAuthEnabled = payload;
      })
      .addCase(checkUser.fulfilled, (state, { payload }) => {
        if (payload?.user?.roles) {
          const roles = payload.user.roles;
          if (roles.includes('Admin') || roles.includes('Team Admin')) {
            console.log('');
          } else {
            window.open(
              `${process.env.REACT_APP_MEA_URL}tem?email=${payload.user.email}&token=${
                payload.token
              }&roles=${roles.join(',')}`,
              '_self'
            );
            return;
          }
        }

        localStorage.setItem('auth', JSON.stringify({ token: payload.token }));
        localStorage.setItem('email', JSON.stringify({ email: payload?.user?.email }));
        localStorage.setItem('roles', JSON.stringify({ roles: payload?.user?.roles }));
        localStorage.setItem('isExternal', JSON.stringify({ isExternal: true }));
        state.token = payload.token;
        state.isLoggedIn = true;
        window.location.href = '/';
      });
  }
});

export const selectToken = (state) => state.LOGIN_SLICE.token;
export const selectRefreshToken = (state) => state.LOGIN_SLICE.refreshToken;
export const selectIsLogged = (state) => state.LOGIN_SLICE.isLoggedIn;
export const selectIsAuthEnabled = (state) => state.LOGIN_SLICE.isAuthEnabled;

const { actions, reducer } = loginSlice;

export const {
  setToken,
  setRefreshToken,
  setIsLoggedIn,
  setIsAuthEnabled,
  setExternalToken,
  setNewExternalToken
} = actions;

export default reducer;
