import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { store } from '@common/providers/Redux';

import { FILTER_KEYS } from './config/operators';

export const initialState = {
  action: '',
  isOpen: false,
  config: {},
  filter: null,
  initialValues: null,
  isActive: false
};

export const FILTER_DIALOG_SLICE = 'filter';

export const FILTER_ACTIONS = { Confirm: 'Confirm', Cancel: 'Cancel', Reset: 'Reset' };

export const scrubFiltersForBE = (data = [], isValueExcluded = true) => {
  return data.filters.map((filter) => {
    const filterObject = {
      key: filter.id,
      operation: filter.operator.key
    };

    // If value key is excluded don't send value for boolean operators
    if (isValueExcluded) {
      if (
        filter.operator.key === FILTER_KEYS.IsEmpty ||
        filter.operator.key === FILTER_KEYS.IsNotEmpty
      ) {
        return filterObject;
      }
    }

    return {
      ...filterObject,
      value:
        filter.type === 'date'
          ? dateToFormat(filter.value, dateInitFormats.basicDate)
          : filter.value
    };
  });
};

export const openFilterDialog = createAsyncThunk(
  `${FILTER_DIALOG_SLICE}/openFilterDialog`,
  (config, { dispatch, getState }) => {
    dispatch(openFilter(config));

    return new Promise((resolve) => {
      const handleChange = () => {
        const state = getState();

        if (state.FILTER_DIALOG_SLICE.action === FILTER_ACTIONS.Confirm) {
          dispatch(closeFilterDialog());
          unsubscribe();
          return resolve({
            action: FILTER_ACTIONS.Confirm,
            filters: state.FILTER_DIALOG_SLICE.filter
          });
        }

        if (state.FILTER_DIALOG_SLICE.action === FILTER_ACTIONS.Cancel) {
          dispatch(closeFilterDialog());
          unsubscribe();
          return resolve({ action: FILTER_ACTIONS.Cancel });
        }

        if (state.FILTER_DIALOG_SLICE.action === FILTER_ACTIONS.Reset) {
          unsubscribe();
          return resolve({ action: FILTER_ACTIONS.Reset });
        }
      };

      const unsubscribe = store.subscribe(handleChange);
    });
  }
);

export const filterDialogSlice = createSlice({
  name: FILTER_DIALOG_SLICE,
  initialState,
  reducers: {
    resetFilterState: () => initialState,
    closeFilterDialog: (state, action) => {
      state.action = action.payload;
      state.isOpen = false;
    },
    openFilter: (state, action) => {
      state.isOpen = true;
      state.config = action.payload;
      state.initialValues = { filters: [{ ...action.payload.config[0], value: '' }] };
    },
    setFilter: (state, action) => {
      state.action = FILTER_ACTIONS.Confirm;
      state.filter = action.payload;
      state.isActive = true;
      state.isOpen = false;
    },
    reset: (state) => {
      state.action = FILTER_ACTIONS.Reset;
      state.filter = null;
      state.isActive = false;
      state.isOpen = false;
    }
  }
});

export const selectIsOpen = (state) => state.FILTER_DIALOG_SLICE.isOpen;
export const selectIsActive = (state) => state.FILTER_DIALOG_SLICE.isActive;
export const selectConfig = (state) => state.FILTER_DIALOG_SLICE.config;
export const selectFilters = (state) => state.FILTER_DIALOG_SLICE.filter;
export const selectInitialValues = (state) =>
  state.FILTER_DIALOG_SLICE.filter || state.FILTER_DIALOG_SLICE.initialValues;

const { actions, reducer } = filterDialogSlice;

export const { closeFilterDialog, openFilter, setFilter, resetFilterState, reset } = actions;

export default reducer;
