import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import { checkChangedFields } from '@common/helpers/helpers';
import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { resetFilterState } from '@components/FilterDialog/filtersSlice';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import {
  resetState,
  selectSessionDayDetails,
  setSessionDayDetails,
  saveSessionDay,
  deleteSessionDay,
  selectIsDetailsLoading
} from '@pages/IltSession/iltSessionSlice';

import SessionDayDetails from './Details/SessionDayDetails';
import Sidebar from './Sidebar';
import SessionTabs from './Tabs/SessionTabs';

const SessionDay = () => {
  const dispatch = useDispatch();
  const {
    data: {
      session: { data: session },
      sessionDayDetails: { data: sessionDayDetails }
    }
  } = useMatch();

  const isOpen = useSelector(selectIsOpen);
  const details = useSelector(selectSessionDayDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  // Set session details to store (TODO: Figure out how to do this from route resolver)
  useEffect(() => {
    dispatch(setSessionDayDetails(sessionDayDetails));
  }, [sessionDayDetails?.id]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
      dispatch(resetFilterState());
    };
  }, []);

  const onSave = (values) => {
    const changedFields = checkChangedFields({ ...details }, values);
    return dispatch(
      saveSessionDay({
        ...changedFields,
        iltSessionId: session.id,
        id: details.id
      })
    )
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.SESSION
        });
      });
  };

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('Events.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteSessionDay(details?.id))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };
  return (
    <LayoutContainer>
      <LeftContainer isFullScreen></LeftContainer>

      <RightContainer open={isOpen} isFullScreen>
        <SessionDayDetails entityId={details?.id} headerTitle={details?.name}>
          <SessionTabs
            entityId={details?.id}
            entityType={EntityTypes.SESSION}
            details={details}
            setDetails={setSessionDayDetails}
          />
        </SessionDayDetails>
      </RightContainer>

      <Sidebar
        data={{
          ...details,
          sessionName: session?.name,
          sessionStartDateTime: session?.startDateTime,
          sessionEndDateTime: session?.endDateTime,
          course: session?.course?.name,
          module: session?.module?.name
        }}
        isDetailsLoading={isDetailsLoading}
        onSave={onSave}
        onDelete={onDelete}
        sessionTypeId={session?.type?.id}
      />
    </LayoutContainer>
  );
};

export default SessionDay;
