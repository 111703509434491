import { createSlice } from '@reduxjs/toolkit';

// Saved state through routes change
const initialState = {
  savedState: null
};

export const PERSIST_SLICE = 'persist';

export const persistSlice = createSlice({
  name: PERSIST_SLICE,
  initialState,
  reducers: {
    saveState: (state, { payload }) => {
      state.savedState = payload;
    },
    clearState: (state) => {
      state.savedState = null;
    }
  }
});

export const selectSavedState = (state) => state.PERSIST_SLICE.savedState;

export const { saveState, clearState } = persistSlice.actions;

export default persistSlice.reducer;
