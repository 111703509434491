import Localize from 'react-intl-universal';

import moment from 'moment';

import { dateInitFormats } from '@common/Constants';
import { combineDateAndTime, isValidDate } from '@common/helpers/dates';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { DIALOG_PATHS } from '@common/network/ApiPaths';
import { CLASSROOM_TYPE_ID } from '@pages/IltSession/iltSessionSlice';
import sessionDayInitialValues from '@pages/IltSession/util/sessionDayInitialValues';

import createSessionDayClassroom from '../util/fields/createSessionDayClassroom';
import createSessionDayWebinar from '../util/fields/createSessionDayWebinar';

export const getFieldsConfig = (
  setFieldValue,
  values,
  session,
  errors,
  onSessionDayUpdate,
  onSessionDayDateChange,
  onRoomRemove,
  sessionDayMetrics,
  setFieldTouched
) => {
  return mapPropsToComponent(
    session?.type?.id === CLASSROOM_TYPE_ID ? createSessionDayClassroom : createSessionDayWebinar,
    {
      ['meetingRoomId']: {
        helperText: 'ValidationMessages.MeetingRoomILTSession',
        fetchDataPath: DIALOG_PATHS.MEETING_ROOMS,
        disabled:
          values?.venues?.length === 0 ||
          !values?.name ||
          Boolean(errors?.endTime === Localize.get('ValidationMessages.EndTimeAfterStart')),
        tokenValue:
          values?.meetingRoomId?.id && values?.meetingRoomId?.name
            ? [
                {
                  title: `${values?.meetingRoomId?.name} `
                }
              ]
            : undefined,
        onIconClick: (e, result, resource) => {
          if (e?.target?.name === 'delete') {
            setFieldValue('meetingRoomId', sessionDayInitialValues.meetingRoomId);
            onRoomRemove();
          }
          if (e?.target?.name === 'save') {
            if (!result?.length) {
              setFieldValue('dayDate', sessionDayInitialValues.dayDate);
              setFieldValue('startTime', sessionDayInitialValues.startTime);
              setFieldValue('endTime', sessionDayInitialValues.endTime);
              setFieldValue('meetingRoomId', sessionDayInitialValues.meetingRoomId);
              return;
            }

            const { start, end } = result[0];

            setFieldValue('meetingRoomId', {
              ...resource,
              name: resource?.resourceTitle,
              id: resource?.resourceId
            });
            setFieldValue('dayDate', moment(start).format(dateInitFormats.basicDate));
            setFieldValue('startTime', start);
            setFieldValue('endTime', end);
          }
        }
      },
      ['instructorIds']: {
        tokenValue:
          values?.instructorIds?.map(({ firstName = '', lastName = '', id = '' }) => ({
            title: `${firstName} ${lastName}`,
            id: id
          })) ?? [],
        onIconClick: (id) => {
          setFieldValue(
            'instructorIds',
            values.instructorIds.filter((instructor) => instructor.id !== id)
          );
        },
        selectItem: (value = []) => setFieldValue('instructorIds', value),
        selectionModel: values.instructorIds
      },
      ['startTime']: {
        disabled: Boolean(values?.meetingRoomId?.id),
        ampm: false,
        onChange: (value) => {
          if (!value || !isValidDate(value)) {
            onSessionDayUpdate('predefinedStart', null);
            setFieldValue('startTime', value, true);
            return;
          }

          setFieldTouched('startTime', true);
          setFieldValue('startTime', combineDateAndTime(values.dayDate, value), true);
          onSessionDayUpdate('predefinedStart', combineDateAndTime(values.dayDate, value));
        }
      },
      ['endTime']: {
        disabled: Boolean(values?.meetingRoomId?.id),
        ampm: false,
        onChange: (value) => {
          if (!value || !isValidDate(value)) {
            onSessionDayUpdate('predefinedEnd', null);
            setFieldValue('endTime', value, true);
            return;
          }

          setFieldTouched('endTime', true);
          setFieldValue('endTime', combineDateAndTime(values.dayDate, value), true);
          onSessionDayUpdate('predefinedEnd', combineDateAndTime(values.dayDate, value));
        }
      },
      ['dayDate']: {
        disabled: Boolean(values?.meetingRoomId?.id),
        onChange: (value) => {
          setFieldValue('dayDate', value || null);
          onSessionDayDateChange(value);
        }
      },
      ['name']: {
        onChange: (e) => {
          setFieldValue('name', e.target.value);
          onSessionDayUpdate('name', e.target.value);
        }
      },
      ['hasReminder']: {
        value: values?.hasReminder || false,
        onChange: (event) => {
          const { name, checked } = event.target;
          setFieldValue(name, checked);
          if (!checked) {
            setFieldValue('reminderValue', sessionDayInitialValues.reminderValue);

            setFieldValue('metricTypeId', sessionDayInitialValues.metricTypeId);
          }
        },
        tooltip: {
          title: Localize.get('IltSession.Tooltips.SendRemindersBeforeSession')
        }
      },
      ['reminderValue']: {
        isVisible: values?.hasReminder
      },
      ['metricTypeId']: {
        isVisible: values?.hasReminder,
        options: sessionDayMetrics,
        value: values?.metricTypeId || ''
      }
    }
  );
};

export default getFieldsConfig;
