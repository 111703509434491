import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default () => {
  return yup.object().shape({
    subject: yup.string().required(Localize.get('ValidationMessages.Subject')),
    statusId: yup.number().required(Localize.get('ValidationMessages.StatusRequired')),
    priorityId: yup.number().required(Localize.get('ValidationMessages.PriorityRequired')),
    owner: yup
      .object()
      .shape({
        id: yup.number().required().nullable(true),
        name: yup.string()
      })
      .nullable(true)
      .required(Localize.get('ValidationMessages.OwnerRequired')),
    taskDto: yup.object().shape({
      completion: yup
        .number()
        .integer(Localize.get('ValidationMessages.IntNumberRule'))
        .nullable(true)
        .min(0, Localize.get('ValidationMessages.MinNumber0'))
        .max(100, Localize.get('ValidationMessages.MaxNumber'))
    }),
    startDateTime: yup
      .date()
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      .required(Localize.get('ValidationMessages.EndDate')),
    endDateTime: yup
      .date()
      .min(yup.ref('startDateTime'), Localize.get('ValidationMessages.EndDateStartDate'))
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      .required(Localize.get('ValidationMessages.EndDate'))
  });
};
