import React, { useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import moment from 'moment';

import { FOCUS_FIELD_TIMEOUT } from '@common/Constants';
import RightSidebar from '@components/RightSidebar';
import {
  CANCELLED_STATUS_ID,
  CLASSROOM_TYPE_ID,
  COMPLETED_STATUS_ID
} from '@pages/IltSession/iltSessionSlice';
import sidebar from '@pages/IltSession/util/fields/sidebar';
import initialValues from '@pages/IltSession/util/initialValues';
import useSidebarValidationSchema from '@pages/IltSession/util/schemas/useSidebarSchema';

const isCancelledOrCompleteStatus = (values) =>
  values?.status?.id === COMPLETED_STATUS_ID || values?.status?.id === CANCELLED_STATUS_ID;

const Sidebar = ({
  details = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {}
}) => {
  const sidebarSchema = useSidebarValidationSchema();
  const {
    data: {
      type: { data: type },
      status: { data: status }
    }
  } = useMatch();

  const sessionDayMetrics = useMemo(
    () => [
      { value: Localize.get('MetricTypes.Minutes'), id: 1 },
      { value: Localize.get('MetricTypes.Hours'), id: 2 },
      { value: Localize.get('MetricTypes.Days'), id: 3 }
    ],
    []
  );

  const extendedConfig = (setFieldValue, values) => {
    return {
      ['name']: {
        disabled: isCancelledOrCompleteStatus(values)
      },
      ['maxSeats']: {
        disabled: isCancelledOrCompleteStatus(values)
      },
      ['minSeats']: {
        disabled: isCancelledOrCompleteStatus(values)
      },
      ['startDateTime']: {
        onChange: (value) => setFieldValue('startDateTime', value || null),
        disablePast: true,
        ampm: false,
        minDateTime: moment()
      },
      ['endDateTime']: {
        onChange: (value) => setFieldValue('endDateTime', value),
        disablePast: true,
        ampm: false
      },
      ['status.id']: {
        options: status,
        onChange: (event) => {
          setFieldValue('status', {
            id: event.target.value,
            value: status.find((s) => s.id === event.target.value).value
          });
        }
      },
      ['type.id']: {
        options: type,
        onChange: (event) => {
          setFieldValue('type', {
            id: event.target.value,
            value: type.find((t) => t.id === event.target.value).value
          });
        }
      },
      ['venues']: {
        disabled: isCancelledOrCompleteStatus(values),
        isVisible: values?.type?.id === CLASSROOM_TYPE_ID,
        tokenValue: values?.venues?.map((venue) => ({ title: venue.name, id: venue.id })) ?? [],
        onIconClick: (id) => {
          setFieldValue(
            'venues',
            values.venues.filter((item) => item.id !== id)
          );
        },
        selectItem: (value = []) => {
          if (!value?.length) {
            setFieldValue('venues', initialValues.venues);
            return;
          }

          setFieldValue('venues', value);
        },
        selectionModel: values.venues
      },
      ['courseId']: {
        tokenValue: values?.course?.name
          ? [
              {
                title: `${values.course?.name} `
              }
            ]
          : undefined
      },
      ['moduleId']: {
        tokenValue: values?.module?.name
          ? [
              {
                title: `${values?.module?.name}`
              }
            ]
          : undefined
      },
      ['publishInCatalog']: {
        tooltip: {
          title: Localize.get('IltSession.Tooltips.PublishInCatalog')
        }
      },
      ['enableHotelRequest']: {
        tooltip: {
          title: Localize.get('IltSession.Tooltips.HotelRequestSettings')
        },
        isVisible: details?.type?.id === CLASSROOM_TYPE_ID
      },
      ['hotelRequestSettings']: {
        isVisible: details?.type?.id === CLASSROOM_TYPE_ID
      },
      ['registrationPeriod']: {
        tooltip: {
          title: Localize.get('IltSession.Tooltips.RegistrationPeriod')
        },
        onClick: (e) => {
          if (e?.target?.checked) {
            setTimeout(
              () => document.getElementsByName('registrationFrom')[0]?.focus(),
              FOCUS_FIELD_TIMEOUT
            );
          }
        }
      },
      ['hasRegistrationPeriod']: {
        value: values?.hasRegistrationPeriod || false,
        onChange: (event) => {
          const { name, checked } = event.target;
          setFieldValue(name, checked);
          setFieldValue('registrationBeforeStart', initialValues.registrationBeforeStart);
          setFieldValue(
            'registrationBeforeStartMetricType',
            initialValues.registrationBeforeStartMetricType
          );
        },
        tooltip: {
          title: Localize.get('IltSession.Tooltips.RegistrationDeadline')
        }
      },
      ['registrationBeforeStart']: {
        isVisible: values?.hasRegistrationPeriod
      },
      ['registrationBeforeStartMetricType']: {
        onChange: (event) => {
          setFieldValue('registrationBeforeStartMetricType', {
            id: event.target.value,
            value: sessionDayMetrics.find((t) => t.id === event.target.value).value
          });
        },
        isVisible: values?.hasRegistrationPeriod,
        options: sessionDayMetrics,
        value:
          values?.registrationBeforeStartMetricType?.id ||
          initialValues.registrationBeforeStartMetricType.id
      },
      ['hasCancellationDeadline']: {
        value: values?.hasCancellationDeadline || false,
        onChange: (event) => {
          const { name, checked } = event.target;
          setFieldValue(name, checked);
          setFieldValue('cancellationBeforeStart', initialValues.cancellationBeforeStart);
          setFieldValue(
            'cancellationBeforeStartMetricType',
            initialValues.cancellationBeforeStartMetricType
          );
        },
        tooltip: {
          title: Localize.get('IltSession.Tooltips.RegistrationDeadline')
        }
      },
      ['cancellationBeforeStart']: {
        isVisible: values?.hasCancellationDeadline
      },
      ['cancellationBeforeStartMetricType']: {
        onChange: (event) => {
          setFieldValue('cancellationBeforeStartMetricType', {
            id: event.target.value,
            value: sessionDayMetrics.find((t) => t.id === event.target.value).value
          });
        },
        isVisible: values?.hasCancellationDeadline,
        options: sessionDayMetrics,
        value:
          values?.cancellationBeforeStartMetricType?.id ||
          initialValues.cancellationBeforeStartMetricType.id
      },
      ['hasWaitingList']: {
        disabled: isCancelledOrCompleteStatus(values),
        onChange: (event) => {
          const { name, checked } = event.target;
          setFieldValue(name, checked);
          if (!checked) {
            setFieldValue('hasAutomaticWaitingList', initialValues.hasAutomaticWaitingList);
            setFieldValue('hasAutomaticCancellation', initialValues.hasAutomaticCancellation);
          }
        },
        tooltip: {
          title: Localize.get('IltSession.Tooltips.WaitingList')
        }
      },
      ['hasAutomaticWaitingList']: {
        isVisible: values?.hasWaitingList,
        tooltip: {
          title: Localize.get('IltSession.Tooltips.AutomaticWaitingList')
        }
      },
      ['hasAutomaticCancellation']: {
        isVisible: values?.hasWaitingList,
        onChange: (event) => {
          const { name, checked } = event.target;
          setFieldValue(name, checked);
          setFieldValue(
            'automaticCancellationBeforeStart',
            initialValues.automaticCancellationBeforeStart
          );
          setFieldValue(
            'automaticCancellationBeforeStartMetricType',
            initialValues.automaticCancellationBeforeStartMetricType
          );
        },
        tooltip: {
          title: Localize.get('IltSession.Tooltips.AutomaticWaitingListCancellation')
        }
      },
      ['automaticCancellationBeforeStart']: {
        isVisible: values?.hasAutomaticCancellation
      },
      ['automaticCancellationBeforeStartMetricType']: {
        onChange: (event) => {
          setFieldValue('automaticCancellationBeforeStartMetricType', {
            id: event.target.value,
            value: sessionDayMetrics.find((t) => t.id === event.target.value).value
          });
        },
        isVisible: values?.hasAutomaticCancellation,
        options: sessionDayMetrics,
        value:
          values?.automaticCancellationBeforeStartMetricType?.id ||
          initialValues.automaticCancellationBeforeStartMetricType.id
      }
    };
  };

  return (
    <RightSidebar
      config={{ title: Localize.get('IltSession.Details'), fieldsConfig: sidebar }}
      extendedConfig={extendedConfig}
      data={details}
      validationSchema={sidebarSchema}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
};

export default Sidebar;
