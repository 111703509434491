import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'IltSession.IltSessionInformation',
    fields: [
      {
        label: 'IltSession.SeminarNumber',
        name: 'seminarNumber',
        required: false,
        disabled: false,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'IltSession.Course',
        name: 'courseId',
        required: true,
        disabled: true,
        dialogTitle: 'IltSession.SearchCourse',
        component: 'tokenValue'
      },
      {
        label: 'IltSession.IltModule',
        name: 'moduleId',
        required: true,
        disabled: true,
        dialogTitle: 'IltSession.SearchIltModule',
        component: 'tokenValue'
      },
      {
        label: 'IltSession.IltSessionName',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'IltSession.MaximumSeats',
        name: 'maxSeats',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'IltSession.MinimumSeats',
        name: 'minSeats',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'IltSession.SessionType',
        name: 'type.id',
        required: true,
        disabled: true,
        component: 'select'
      },
      {
        label: 'IltSession.Status',
        name: 'status.id',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Events.StartDate',
        name: 'startDateTime',
        required: true,
        disabled: true,
        component: 'datetimepicker'
      },
      {
        label: 'Events.EndDate',
        name: 'endDateTime',
        required: true,
        disabled: true,
        component: 'datetimepicker'
      },
      {
        label: 'Events.VenueName',
        name: 'venues',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchVenue',
        component: 'tokenValueArray',
        fetchDataPath: DIALOG_PATHS.VENUES,
        value: '',
        initQueryState: {
          id: '',
          name: '',
          city: '',
          country: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'city',
            headerName: 'Address.City',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false,
            valueGetter: (params) => params?.row?.addresses[0].city
          },
          {
            field: 'country',
            headerName: 'Address.Country',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false,
            valueGetter: (params) => params?.row?.addresses[0].country.value
          }
        ]
      },
      {
        label: 'IltSession.PublishInCatalog',
        name: 'isMeaPublished',
        required: false,
        disabled: false,
        component: 'switch'
      }
    ]
  },
  {
    groupName: 'IltSession.HotelRequestSettings',
    name: 'hotelRequestSettings',
    fields: [
      {
        label: 'IltSession.CheckHotel',
        name: 'enableHotelRequest',
        required: false,
        disabled: false,
        component: 'switch',
        column: 5
      }
    ]
  },
  {
    groupName: 'IltSession.WaitListInformation',
    fields: [
      {
        label: 'IltSession.WaitList',
        name: 'hasWaitingList',
        required: false,
        disabled: false,
        component: 'switch'
      },
      {
        label: 'IltSession.AutomaticWaitingList',
        name: 'hasAutomaticWaitingList',
        required: false,
        disabled: false,
        component: 'switch'
      },
      {
        label: 'IltSession.AutomaticWaitingListCancellation',
        name: 'hasAutomaticCancellation',
        required: false,
        disabled: false,
        component: 'switch'
      },
      {
        label: 'IltSession.DeadlineValue',
        name: 'automaticCancellationBeforeStart',
        required: true,
        disabled: false,
        component: 'text',
        column: 3,
        type: 'number'
      },
      {
        label: 'IltSession.DeadlineMetric',
        name: 'automaticCancellationBeforeStartMetricType',
        required: true,
        disabled: false,
        component: 'select',
        column: 3
      }
    ]
  },
  {
    groupName: 'IltSession.RegistrationDeadlineInformation',
    fields: [
      {
        label: 'IltSession.RegistrationDeadline',
        name: 'hasRegistrationPeriod',
        required: false,
        disabled: false,
        component: 'switch',
        column: 3
      },
      {
        label: 'IltSession.DeadlineValue',
        name: 'registrationBeforeStart',
        required: true,
        disabled: false,
        component: 'text',
        column: 3,
        type: 'number'
      },
      {
        label: 'IltSession.DeadlineMetric',
        name: 'registrationBeforeStartMetricType',
        required: true,
        disabled: false,
        component: 'select',
        column: 3
      }
    ]
  },
  {
    groupName: 'IltSession.CancellationDeadlineInformation',
    fields: [
      {
        label: 'IltSession.CancellationDeadline',
        name: 'hasCancellationDeadline',
        required: false,
        disabled: false,
        component: 'switch',
        column: 3
      },
      {
        label: 'IltSession.DeadlineValue',
        name: 'cancellationBeforeStart',
        required: true,
        disabled: false,
        component: 'text',
        column: 3,
        type: 'number'
      },
      {
        label: 'IltSession.DeadlineMetric',
        name: 'cancellationBeforeStartMetricType',
        required: true,
        disabled: false,
        component: 'select',
        column: 3
      }
    ]
  }
];
