import { Navigate } from 'react-location';

import { RESOURCES_PATHS, SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import Resources, { CreateResource } from '@pages/Resources';
import { getByPathAndParams } from '@services/BaseApi';

import { newPath, resourcesPath, rootPath } from '../Routes';

const ResourceRoute = (routeCache) => ({
  path: resourcesPath,
  children: [
    {
      path: rootPath,
      element: <Resources />,
      loader: routeCache.createLoader(
        async () => ({
          config: getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.RESOURCE }
          }),
          resourceCategories: await getByPathAndParams({
            path: RESOURCES_PATHS.RESOURCE_CATEGORIES
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: newPath,
      element: <CreateResource />
    },
    {
      element: <Navigate to={resourcesPath} />
    }
  ]
});

export default ResourceRoute;
