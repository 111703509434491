import moment from 'moment';

import { STANDARD_PRICE_ID } from '../priceTableSlice';

export default {
  typeId: STANDARD_PRICE_ID,
  price: '',
  currencyId: 1,
  validFrom: moment(),
  validTo: moment().add(1, 'days').format('YYYY-MM-DD')
};
