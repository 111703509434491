import { flattenDeep, uniqueId } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_DIRECTION } from '@common/Constants';
import { MEETING_ROOMS_PATHS } from '@common/network/ApiPaths';
import { SORT_DATA } from '@pages/MeetingRooms/util/sortConfig';
import { getByPathAndParams } from '@services/BaseApi';

export const MEETING_ROOMS_PAGE_SIZE = 10;

const initialState = {
  isLoading: false,
  isDiagramChanged: false,
  dragAndDropResources: [],
  dragAndDropNonDraggableItems: [],
  dragAndDropDraggableItems: [
    {
      id: uniqueId('draggable-'),
      name: '',
      dayDate: new Date(),
      predefinedStart: null,
      predefinedEnd: null,
      dayScheduleDto: null,
      isPredefinedTimeInUse: false
    }
  ],
  listOfMeetingRooms: [],
  resource: null,
  result: null,
  isDialogOpen: false,
  initialStateOfDialog: {
    dragAndDropNonDraggableItems: [],
    dragAndDropDraggableItems: [],
    dragAndDropResources: [],
    dragAndDropFilter: null
  },

  // Saves value for venues filter select component
  venuesFilterValue: [],
  // Saves value object for sending data to BE
  filter: {
    venueIds: 0,
    sessionDayDate: moment(new Date()).format(moment.HTML5_FMT.DATE),
    meetingRoomIds: 0
  },

  // Saves value for room filter autocomplete component
  meetingRoomFilterValue: [],
  // Saves value object for sending data to BE
  meetingRoomFilter: {
    page: 0,
    size: MEETING_ROOMS_PAGE_SIZE,
    search: '',
    filters: {},
    totalElements: 0
  }
};

export const DRAG_AND_DROP_SLICE = 'dragAndDropDialog';

export const fetchData = createAsyncThunk(
  `${DRAG_AND_DROP_SLICE}/fetchData`,
  ({ filter = {}, path = '' }) => {
    return getByPathAndParams({
      path: path,
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => error);
  },
  {
    condition: ({ filter }) => !!filter?.sessionDayDate && !!filter?.venueIds
  }
);

export const fetchMeetingRoomsByVenueId = createAsyncThunk(
  `${DRAG_AND_DROP_SLICE}/fetchMeetingRoomsByVenueId`,
  ({ filter }) => {
    return getByPathAndParams({
      path: MEETING_ROOMS_PATHS.GET,
      params: {
        ...filter,
        sortBy: SORT_DATA[0].name,
        sortDirection: SORT_DIRECTION.ASCENDING,
        size: MEETING_ROOMS_PAGE_SIZE
      }
    })
      .then((response) => response.data)
      .catch((error) => error);
  },
  {
    condition: ({ filter }) => !!filter?.filters?.advancedFilters[0]?.value,
    dispatchConditionRejection: true
  }
);

export const dragAndDropSaveResult = createAsyncThunk(
  `${DRAG_AND_DROP_SLICE}/saveResult`,
  (_, { dispatch, getState }) => {
    dispatch(setDragAndDropResult());
    return new Promise((resolve) =>
      resolve({
        result: getState().DRAG_AND_DROP_SLICE.result,
        resource: getState().DRAG_AND_DROP_SLICE.resource
      })
    );
  }
);

export const dragAndDropDialogSlice = createSlice({
  name: 'DRAG_AND_DROP_SLICE',
  initialState,
  reducers: {
    resetState: () => initialState,
    setDragAndDropNonDraggableItems: (state, action) => {
      state.dragAndDropNonDraggableItems = action.payload;
    },
    setDragAndDropDraggableItems: (state, action) => {
      if (action.payload.items?.length) {
        state.initialStateOfDialog.dragAndDropDraggableItems = action.payload.items;
      }
      state.dragAndDropDraggableItems = action.payload.items;
    },
    setIsDiagramChanged: (state, action) => {
      state.isDiagramChanged = action.payload;
    },
    setDragAndDropMeetingRoomsList: (state, action) => {
      state.listOfMeetingRooms = action.payload;
    },
    setMeetingRoomFilterValue: (state, action) => {
      state.meetingRoomFilterValue = action.payload;
    },
    setDragAndDropIsDialogOpen: (state, action) => {
      state.isDialogOpen = action.payload;

      // Reset isDiagramChanged
      if (!action.payload) {
        state.isDiagramChanged = initialState.isDiagramChanged;
      }
    },
    setDragAndDropResources: (state, action) => {
      state.dragAndDropResources = action.payload;
    },
    setDragAndDropFilter: (state, action) => {
      state.filter = action.payload;
    },
    setRemoveResult: (state) => {
      state.result = null;
    },
    setDragAndDropResult: (state) => {
      state.result = state.dragAndDropNonDraggableItems.filter((e) => e.isDraggable);

      // Save snapshot
      state.initialStateOfDialog = {
        dragAndDropNonDraggableItems: state.dragAndDropNonDraggableItems,
        dragAndDropDraggableItems: state.dragAndDropDraggableItems,
        dragAndDropFilter: state.filter,
        meetingRoomFilterValue: state.meetingRoomFilterValue,
        dragAndDropResources: state.dragAndDropResources,
        listOfMeetingRooms: state.listOfMeetingRooms
      };

      if (!state.result?.length) {
        state.result = null;
      } else {
        state.resource = state.dragAndDropResources.find(
          (e) => e.resourceId === state.result[0].resourceId
        );
      }
    },
    setDragAndDropRemoveEvent: (state, action) => {
      const filtered = state.dragAndDropNonDraggableItems.filter(
        (ev) => ev.id !== action.payload.id
      );
      state.dragAndDropNonDraggableItems = [...filtered];
      state.dragAndDropDraggableItems = [...state.dragAndDropDraggableItems, { ...action.payload }];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload, meta }) => {
        const { filterValues, filter } = meta.arg;
        state.filter = filter;
        state.venuesFilterValue = filterValues;

        const oldNonDraggable = state.dragAndDropNonDraggableItems
          .filter((i) => i.isDraggable)
          .map((i) => ({ name: i?.title, ...i }));

        // Set resources
        const resources =
          payload?.content.filter(Boolean).map((room) => ({
            resourceId: room.id,
            resourceTitle: `${room.name} (${room.availableSeats})`,
            venue: room.venue,
            iltSessionId: room.iltSessionId
          })) || [];

        // Set events
        const dragAndDropNonDraggableItems = payload?.content
          ?.filter((item) => item.timeBlockDtos?.length)
          .map((item) => {
            return item.timeBlockDtos.map((timeBlock) => ({
              id: timeBlock.id,
              name: timeBlock.name,
              start: moment(`${timeBlock.timeBlockDate} ${timeBlock.startTime}`).toDate(),
              end: moment(`${timeBlock.timeBlockDate} ${timeBlock.endTime}`).toDate(),
              resourceId: resources.find((resource) => resource.resourceId === item.id)?.resourceId,
              venue: resources.find((resource) => resource.resourceId === item.id)?.venue,
              iltSessionId: timeBlock.eventId
            }));
          });

        if (!state.result) {
          state.initialStateOfDialog = {
            dragAndDropFilter: filter,
            dragAndDropResources: resources,
            dragAndDropNonDraggableItems: flattenDeep(dragAndDropNonDraggableItems).concat(
              oldNonDraggable
            )
          };
        }

        state.dragAndDropNonDraggableItems = flattenDeep(dragAndDropNonDraggableItems).concat(
          oldNonDraggable
        );
        state.dragAndDropResources = resources;
        state.isLoading = false;
      })
      .addCase(fetchData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchData.rejected, (state) => {
        state.isLoading = false;
        state.dragAndDropNonDraggableItems = [];
        state.dragAndDropResources = [];
        state.dragAndDropDraggableItems = [];
        state.initialStateOfDialog = {
          dragAndDropNonDraggableItems: [],
          dragAndDropDraggableItems: [],
          dragAndDropResources: [],
          dragAndDropFilter: null
        };
      })
      .addCase(fetchMeetingRoomsByVenueId.fulfilled, (state, { payload, meta }) => {
        const { filter, isReset = false, isSearch = false } = meta.arg;

        // Set list of meeting rooms
        // If new data or if changing venue id
        if (filter.page === 0 || isReset) {
          if (payload?.content?.length) {
            state.listOfMeetingRooms = payload.content.map((room) => ({
              id: room.id,
              name: `${room.name} (${room.availableSeats})`
            }));
          } else {
            state.listOfMeetingRooms = [];
          }
        }
        // If data loaded via lazy load
        else if (payload?.content?.length) {
          state.listOfMeetingRooms = state.listOfMeetingRooms.concat(
            payload.content.map((room) => ({
              id: room.id,
              name: `${room.name} (${room.availableSeats})`
            }))
          );
        } else {
          state.listOfMeetingRooms = [];
        }

        state.meetingRoomFilter = {
          ...filter,
          page: payload.number,
          totalElements: payload.totalElements
        };

        // Save meeting room current value, ignoring search or lazy load
        if (!isSearch && filter.page === 0) {
          if (payload?.content) {
            state.meetingRoomFilterValue = payload.content
              .slice(0, 3)
              .map(({ id, name, availableSeats }) => ({
                id: id,
                name: `${name} (${availableSeats})`
              }));
          } else {
            state.meetingRoomFilterValue = [];
          }
        }
      })
      .addCase(fetchMeetingRoomsByVenueId.rejected, (state) => {
        state.listOfMeetingRooms = [];
      });
  }
});

export const selectIsLoading = (state) => state.DRAG_AND_DROP_SLICE.isLoading;
export const selectDragAndDropEvents = (state) =>
  state.DRAG_AND_DROP_SLICE.dragAndDropNonDraggableItems;
export const selectDragAndDropResources = (state) => state.DRAG_AND_DROP_SLICE.dragAndDropResources;
export const selectDragAndDropDraggableItems = (state) =>
  state.DRAG_AND_DROP_SLICE.dragAndDropDraggableItems;
export const selectDragAndDropResult = (state) => state.DRAG_AND_DROP_SLICE.result;
export const selectIsDiagramChanged = (state) => state.DRAG_AND_DROP_SLICE.isDiagramChanged;
export const selectDragAndDropFilter = (state) => state.DRAG_AND_DROP_SLICE.filter;
export const selectDragAndDropIsDialogOpen = (state) => state.DRAG_AND_DROP_SLICE.isDialogOpen;
export const selectInitial = (state) => state.DRAG_AND_DROP_SLICE.initialStateOfDialog;
export const selectListOfMeetingRooms = (state) => state.DRAG_AND_DROP_SLICE.listOfMeetingRooms;
export const selectMeetingRoomFilter = (state) => state.DRAG_AND_DROP_SLICE.meetingRoomFilter;
export const selectMeetingRoomFilterValue = (state) =>
  state.DRAG_AND_DROP_SLICE.meetingRoomFilterValue;
export const selectVenuesFilterValue = (state) => state.DRAG_AND_DROP_SLICE.venuesFilterValue;

const { actions, reducer } = dragAndDropDialogSlice;

export const {
  setDragAndDropNonDraggableItems,
  setDragAndDropDraggableItems,
  setDragAndDropResources,
  setDragAndDropResult,
  setDragAndDropRemoveEvent,
  setRemoveResult,
  setDragAndDropFilter,
  setDragAndDropIsDialogOpen,
  setMeetingRoomFilterValue,
  resetState,
  setIsDiagramChanged,
  setDragAndDropMeetingRoomsList
} = actions;

export default reducer;
