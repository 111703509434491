import { createSlice } from '@reduxjs/toolkit';

import { checkLanguage } from '@common/helpers/languages';

export const initialState = {
  lang: checkLanguage()
};

export const USER_SLICE = 'user';

export const usersSlice = createSlice({
  name: USER_SLICE,
  initialState,
  reducers: {}
});

export const selectLanguage = (state) => state.USER_SLICE.lang;

const { reducer } = usersSlice;

export default reducer;
