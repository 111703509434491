import AutocompleteComponent from '@components/AutocompleteComponent';
import CheckboxInput from '@components/CheckboxInput';
import ComboBox from '@components/ComboBox';
import DatePicker from '@components/DatePicker';
import DateTimePickerInput from '@components/DateTimePicker/DateTimePicker';
import DescriptionComponent from '@components/Description/Description';
import DragAndDropWithDialogWithInput from '@components/DragAndDropCalendar/DragAndDropWithDialogWithInput';
import FilePicker from '@components/FilePicker';
import RatingInput from '@components/RatingInput';
import SelectInput from '@components/SelectInput';
import SwitchInput from '@components/SwitchInput';
import TextInput from '@components/TextInput';
import Timepicker from '@components/Timepicker';
import TokenValueModalChipArray from '@components/TokenValueModalChipArray';
import TokenValueModalTrigger from '@components/TokenValueModalTrigger';

export default {
  description: DescriptionComponent,
  text: TextInput,
  select: SelectInput,
  checkbox: CheckboxInput,
  search: TextInput,
  autocomplete: AutocompleteComponent,
  datepicker: DatePicker,
  tokenValue: TokenValueModalTrigger,
  tokenValueArray: TokenValueModalChipArray,
  comboBox: ComboBox,
  switch: SwitchInput,
  datetimepicker: DateTimePickerInput,
  rating: RatingInput,
  timepicker: Timepicker,
  dragAndDropDialogAndInput: DragAndDropWithDialogWithInput,
  filePicker: FilePicker
};
