import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  mode: 'read', // read, edit
  isEditDisabled: false,
  config: null // custom configuration used for opening sidebar in tables
};

export const RIGHTSIDEBAR_SLICE = 'rightSidebar';

export const rightSidebarSlice = createSlice({
  name: RIGHTSIDEBAR_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    openSidebar: (state, { payload }) => {
      state.config = payload;
      state.isOpen = true;
    },
    closeSidebar: (state) => {
      state.isOpen = false;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setIsEditDisabled: (state, action) => {
      state.isEditDisabled = action.payload;
    }
  }
});

export const selectIsOpen = (state) => state.RIGHTSIDEBAR_SLICE.isOpen;
export const selectMode = (state) => state.RIGHTSIDEBAR_SLICE.mode;
export const selectIsEditDisabled = (state) => state.RIGHTSIDEBAR_SLICE.isEditDisabled;
export const selectSidebarConfig = (state) => state.RIGHTSIDEBAR_SLICE.config;

export const { openSidebar, closeSidebar, setMode, setIsEditDisabled, resetState } =
  rightSidebarSlice.actions;

export default rightSidebarSlice.reducer;
