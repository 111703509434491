import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import { omit } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import CreateForm from '@components/CreateForm';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';

import {
  ADVERTISING_SUBSIDY_ID,
  STANDARD_PRICE_ID,
  createPrices,
  selectList
} from '../priceTableSlice';
import getFieldsConfig from './getFieldsConfig';
import initialValues from './initialValues';
import { pricesSchema } from './pricesSchema';

const CreatePrice = () => {
  const {
    data: {
      priceType: { data: priceType },
      currencyType: { data: currencyType }
    }
  } = useMatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listOfPrices = useSelector(selectList);
  const isStandardPriceCreated = Boolean(
    listOfPrices.find(({ type }) => type?.id === STANDARD_PRICE_ID)
  );

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createPrices({ values, navigate, setSubmitting }));
  };

  const onCancel = () => {
    navigate({ search: (previousUrlParams) => ({ ...omit(previousUrlParams, 'mode') }) });
  };

  return (
    <Formik
      validateOnMount
      initialValues={{
        // If Standard price is already added, update initial values to show Advertising Subsidy
        // Otherwise use default typeId - Standard Price
        ...(isStandardPriceCreated
          ? { ...initialValues, typeId: ADVERTISING_SUBSIDY_ID }
          : { ...initialValues })
      }}
      onSubmit={onSubmit}
      validationSchema={pricesSchema}
      enableReinitialize
    >
      {({ isSubmitting, values, handleSubmit, isValid, setFieldValue }) => (
        <>
          <InlineCreateHeaderContainer>
            <Button
              data-test-id="cancel-btn"
              variant="outlined"
              onClick={onCancel}
              color="error"
              sx={{ mr: 1 }}
              startIcon={<CancelIcon />}
            >
              {Localize.get('Buttons.Cancel')}
            </Button>
            <Button
              data-test-id="save-btn"
              variant="outlined"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
            >
              {Localize.get('Buttons.Save')}
            </Button>
          </InlineCreateHeaderContainer>

          <CreateForm
            values={values}
            fieldsConfig={getFieldsConfig(setFieldValue, priceType, currencyType)}
          />
        </>
      )}
    </Formik>
  );
};

export default CreatePrice;
