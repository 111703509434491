import React from 'react';
import Localize from 'react-intl-universal';

import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Skeleton from '@components/CustomSkeleton/CustomSkeleton';
import NoData from '@components/NoData/NoData';

import EventInformation from './EventInformation';
import SessionInformation from './SessionInformation';

const StyledAccordionDetails = styled(AccordionDetails, {
  shouldForwardProp: (prop) => prop !== 'isEmpty' && prop !== 'isLoading'
})(({ theme, isEmpty, isLoading }) => ({
  pt: 1,
  minHeight: isEmpty ? 'auto' : '200px',
  flexDirection: isEmpty ? 'column' : isLoading ? 'column' : 'row',
  display: 'flex',
  marginLeft: `${theme.spacing(2)} !important`,
  marginBottom: theme.spacing(1)
}));

const SessionOverview = ({ isLoading = false, details = null }) => {
  return (
    <Box>
      <StyledAccordionDetails isLoading={isLoading} isEmpty={details === null}>
        {isLoading ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
            <Box>
              <Skeleton
                skeletonCount={6}
                skeletonProps={{
                  variant: 'rectangular',
                  height: '40px',
                  width: 270,
                  sx: { mb: 1 }
                }}
              />
            </Box>
            <Box>
              <Skeleton
                skeletonCount={3}
                skeletonProps={{
                  variant: 'rectangular',
                  height: '40px',
                  width: 270,
                  sx: { ml: 3, mb: 1 }
                }}
              />
            </Box>
          </Box>
        ) : details ? (
          <>
            <Box sx={{ mr: 2 }}>
              <SessionInformation details={details} />
            </Box>
            <Box sx={{ mr: 2 }}>
              <EventInformation details={details} />
            </Box>
          </>
        ) : (
          <NoData titleText={Localize.get('NoDataMessage.TitleTextOverview')} />
        )}
      </StyledAccordionDetails>
    </Box>
  );
};

export default SessionOverview;
