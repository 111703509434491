import moment from 'moment';

import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { EVENTS_PATHS, LOCALES_PATHS } from '@common/network/ApiPaths';
import create from '@pages/Events/util/fields/create';
import initialValues from '@pages/Events/util/initialValues';

export const getFieldsConfig = (setFieldValue, values, setValues, nativeLanguage, type, status) => {
  return mapPropsToComponent(create, {
    ['statusId']: {
      path: EVENTS_PATHS.EVENTS_STATUSES,
      options: status
    },
    ['typeId']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue('typeId', data.newValue);
      },
      path: EVENTS_PATHS.EVENTS_TYPES,
      options: type
    },
    ['startDateTime']: {
      onChange: (value) => setFieldValue('startDateTime', value || null),
      disablePast: true,
      ampm: false,
      minDateTime: moment()
    },
    ['endDateTime']: {
      onChange: (value) => setFieldValue('endDateTime', value),
      disablePast: true,
      ampm: false
    },
    ['registrationDueDate']: {
      onChange: (value) => setFieldValue('registrationDueDate', value || null),
      ampm: false,
      disablePast: true
    },
    ['venues']: {
      required: Boolean(values.statusId === 1),
      tokenValue: values?.venues?.map((venue) => ({ title: venue.name, id: venue.id })) ?? [],
      onIconClick: (id) => {
        setFieldValue(
          'venues',
          values.venues.filter((item) => item.id !== id)
        );
        setFieldValue('country', '');
        setFieldValue('city', '');
      },
      selectItem: (value = []) => {
        if (!value?.length) {
          setFieldValue('venues', initialValues.venues);
          return;
        }
        setFieldValue('venues', value);
        setFieldValue('country', '');
        setFieldValue('city', '');
        setFieldValue('venues.id', values.venues.id);
      },
      selectionModel: values.venues
    },
    ['nativeLanguageId']: {
      path: EVENTS_PATHS.EVENTS_LANGUAGES,
      options: nativeLanguage
    },
    ['isPrivate']: {
      onChange: (e, newValue) =>
        setValues({
          ...values,
          isPrivate: newValue,
          isMeaPublished: newValue ? false : initialValues.isMeaPublished,
          isWebPublished: newValue ? false : initialValues.isWebPublished
        })
    },
    ['isWebPublished']: {
      disabled: values.isPrivate
    },
    ['isMeaPublished']: {
      disabled: values.isPrivate
    },
    ['country']: {
      path: LOCALES_PATHS.COUNTRIES,
      formItemChangeHandler: (e, data) => setFieldValue('country', data?.newValue),
      isVisible: values.statusId !== 1 && Boolean(!values.venues?.length)
    },
    ['city']: {
      isVisible: values.statusId !== 1 && Boolean(!values.venues?.length)
    }
  });
};

export default getFieldsConfig;
