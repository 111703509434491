import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { store } from '@common/providers/Redux';

export const SnackbarSeverityTypes = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error'
};

const initialState = {
  isOpen: false,
  config: {
    message: '',
    severity: '',
    duration: 0,
    vertical: 'bottom',
    horizontal: 'center'
  }
};
export const SNACKBAR_SLICE = 'snackbar';

export const SNACKBAR_ACTIONS = { Confirm: 'Confirm', Cancel: 'Cancel' };

export const snackbar = createAsyncThunk(
  `${SNACKBAR_SLICE}/open`,
  (config, { dispatch, getState }) => {
    dispatch(showSnackbar(config));

    return new Promise((resolve) => {
      const handleChange = () => {
        const state = getState();

        if (state.CONFIRM_DIALOG.action === SNACKBAR_ACTIONS.Confirm) {
          dispatch(hideSnackbar());
          unsubscribe();
          return resolve(SNACKBAR_ACTIONS.Confirm);
        }

        if (state.CONFIRM_DIALOG.action === SNACKBAR_ACTIONS.Cancel) {
          dispatch(hideSnackbar());
          unsubscribe();
          return resolve(SNACKBAR_ACTIONS.Cancel);
        }
      };

      const unsubscribe = store.subscribe(handleChange);
    });
  }
);

export const snackbarSlice = createSlice({
  name: SNACKBAR_SLICE,
  initialState,
  reducers: {
    hideSnackbar: (state, action) => {
      state.action = action.payload;
      state.isOpen = false;
    },
    showSnackbar: (state, action) => {
      state.config = action.payload;
      state.isOpen = true;
    }
  }
});

export const selectIsOpen = (state) => state.SNACKBAR_SLICE.isOpen;
export const selectConfig = (state) => state.SNACKBAR_SLICE.config;

const { actions, reducer } = snackbarSlice;

export const { hideSnackbar, showSnackbar } = actions;

export default reducer;
