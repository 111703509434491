import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default () => {
  let createValidationSchema = yup.object().shape({
    subject: yup.string().required(Localize.get('ValidationMessages.Subject')),
    startDateTime: yup
      .date()
      .required(Localize.get('ValidationMessages.StartDate'))
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid')),
    endDateTime: yup
      .date()
      .min(yup.ref('startDateTime'), Localize.get('ValidationMessages.EndDateStartDate'))
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      .required(Localize.get('ValidationMessages.EndDate')),
    location: yup.string(),
    owner: yup.object().shape({
      id: yup.number().nullable(true).required(Localize.get('ValidationMessages.OwnerRequired'))
    }),
    taskDto: yup.object().shape({
      completion: yup
        .number()
        .integer(Localize.get('ValidationMessages.IntNumberRule'))
        .nullable(true)
        .min(0, Localize.get('ValidationMessages.MinNumber0'))
        .max(100, Localize.get('ValidationMessages.MaxNumber'))
    })
  });

  return [createValidationSchema];
};
