import Localize from 'react-intl-universal';

import * as yup from 'yup';

export const accommodationSchema = (accommodationList = []) =>
  yup.object().shape({
    roomType: yup.string().required(Localize.get('ValidationMessages.RoomRequired')),
    maxOccupancy: yup
      .number()
      .required(Localize.get('ValidationMessages.MaxOccupancy'))
      .typeError(Localize.get('ValidationMessages.MaxOccupancy'))
      .min(1, Localize.get('ValidationMessages.PositiveNumber'))
      .test({
        name: 'max-occupancy-per-room-type',
        test: function (value, context) {
          let roomTypeId = context?.parent?.roomType;
          if (
            accommodationList
              .filter(({ roomType }) => roomType.id == roomTypeId)
              .some(({ maxOccupancy }) => maxOccupancy == value)
          ) {
            return this.createError({
              message: Localize.get('ValidationMessages.MaxOccupancyExist')
            });
          }
          return true;
        }
      }),
    description: yup.string().max(100, Localize.get('ValidationMessages.DescriptionTooLong'))
  });
