export default [
  {
    groupName: 'ResourcesTile.Price',
    fields: [
      {
        label: 'Labels.PriceType',
        name: 'type.id',
        required: true,
        disabled: false,
        component: 'select',
        maxLength: 30
      },
      {
        label: 'ResourcesTile.Price',
        name: 'price',
        required: true,
        disabled: false,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'ResourcesTile.Currency',
        name: 'currency.id',
        required: true,
        disabled: false,
        component: 'select',
        maxLength: 30
      },
      {
        label: 'Labels.ValidFrom',
        name: 'validFrom',
        required: true,
        disabled: false,
        component: 'datepicker',
        maxLength: 30
      },
      {
        label: 'Labels.ValidTo',
        name: 'validTo',
        required: true,
        disabled: false,
        component: 'datepicker',
        maxLength: 30
      }
    ]
  }
];
