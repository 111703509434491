export default [
  {
    groupName: 'Labels.Accommodation',
    fields: [
      {
        label: 'Locations.RoomType',
        name: 'roomType.id',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Locations.MaxOccupancy',
        name: 'maxOccupancy',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.Description',
        name: 'description',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  }
];
