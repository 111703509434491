import React, { useEffect, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { ACTION_MODES } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import { clearState, selectSavedState } from '@common/storage/persistSlice';
import CreateForm from '@components/CreateForm';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import {
  selectCreatedBusinessPartner,
  setCreatedBusinessPartner
} from '@pages/BusinessPartners/businessPartnersSlice';
import createParticipantSchema from '@pages/IltSession/util/schemas/createParticipantSchema';

import { createWaitingList, selectFilter, selectId } from '../waitingListTableSlice';
import getFieldsConfig, { mapPropsToAddress } from './getFieldsConfig';
import initialValues from './initialValues';

const CreateWaitingList = ({ session }) => {
  const {
    data: {
      participantData: {
        data: { allergy, language }
      },
      participantStatuses: { data: statuses },
      phoneTypes: { data: phoneTypes },
      emailTypes: { data: emailTypes },
      addressTypes: { data: addressTypes }
    }
  } = useMatch();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createdBusinessPartner = useSelector(selectCreatedBusinessPartner);
  const savedState = useSelector(selectSavedState);
  const startDate = session?.startDateTime;
  const endDate = session?.endDateTime;
  const selectedId = useSelector(selectId);
  const filter = useSelector(selectFilter);

  useEffect(() => window.scrollTo(0, 0), []);

  const getInitialValues = useMemo(() => {
    const { participantFormValues = null } = savedState || {};

    // Participant form values are saved, load them as initial values
    if (participantFormValues) {
      return {
        ...participantFormValues,
        iltSession: { name: session?.name, id: session?.id },
        arrivalDate: moment(startDate).toDate(),
        departureDate: moment(endDate).toDate(),
        company: {
          id: createdBusinessPartner?.organizationPerson?.id || '',
          name: createdBusinessPartner?.organizationPerson?.name
        }
      };
    }

    return {
      ...initialValues,
      iltSession: { name: session?.name, id: session?.id },
      arrivalDate: moment(startDate).toDate(),
      departureDate: moment(endDate).add(1, 'days').toDate(),
      company: {
        id: createdBusinessPartner?.organizationPerson?.id || '',
        name: createdBusinessPartner?.organizationPerson?.name
      }
    };
  }, [initialValues, createdBusinessPartner, savedState]);

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createWaitingList(values))
      .unwrap()
      .then(() => {
        dispatch(setCreatedBusinessPartner(null));
        dispatch(clearState());
        navigate({
          search: (previousUrlParams) => ({
            ...omit(previousUrlParams, 'mode')
          })
        });
      })
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'WAITING_LIST', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const onCancel = () => {
    dispatch(clearState());
    dispatch(setCreatedBusinessPartner(null));
    navigate({ search: (previousUrlParams) => ({ ...omit(previousUrlParams, 'mode') }) });
  };

  return (
    <Formik
      validateOnMount
      initialValues={getInitialValues}
      onSubmit={onSubmit}
      validationSchema={createParticipantSchema}
    >
      {({ isSubmitting, values, setFieldValue, handleSubmit, isValid, setValues }) => (
        <>
          <InlineCreateHeaderContainer>
            <Button
              data-test-id="cancel-btn"
              variant="outlined"
              onClick={onCancel}
              color="error"
              sx={{ mr: 1 }}
              startIcon={<CancelIcon />}
            >
              {Localize.get('Buttons.Cancel')}
            </Button>
            <Button
              data-test-id="save-btn"
              variant="outlined"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
            >
              {Localize.get('Buttons.Save')}
            </Button>
          </InlineCreateHeaderContainer>

          <CreateForm
            values={values}
            mapPropsToAddress={mapPropsToAddress}
            fieldsConfig={getFieldsConfig(
              values,
              setFieldValue,
              setValues,
              allergy,
              language,
              phoneTypes,
              emailTypes,
              addressTypes,
              statuses,
              session,
              dispatch,
              { selectedId, filter }
            )}
          />
        </>
      )}
    </Formik>
  );
};

export default CreateWaitingList;
