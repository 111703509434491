import React, { useCallback } from 'react';
import { useNavigate } from 'react-location';

import { get } from 'lodash';

import { Box, Typography, Tooltip, Zoom, alpha } from '@mui/material';
import Icon from '@mui/material/Icon';
import { makeStyles, useTheme } from '@mui/styles';

import newsBackground from '@assets/images/news-tail.jpg';
import { TAB_KEYS } from '@common/Constants';
import { coursesPath } from '@navigation/routes/Routes';

const useStyles = makeStyles((theme) => ({
  newsBackground: {
    position: 'relative',
    borderRadius: '8px',
    boxShadow: theme.shadows[4],
    margin: ' 0.9rem',
    cursor: 'pointer',
    backgroundColor: theme.palette.launchpad.main,
    backgroundImage: `url(${newsBackground})`,
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    opacity: '0.7',
    height: '11.9rem',
    [theme.breakpoints.down('md')]: {
      width: '11.5rem',
      paddingRight: '15px'
    },
    '&:hover': {
      opacity: '0.6'
    }
  },
  tileContainer: {
    position: 'relative',
    border: '1px solid transparent',
    borderRadius: ' 8px',
    boxShadow: '0px 3px 13px rgba(0, 0, 0, 0.17)',
    height: '11.9rem',
    margin: ' 0.9rem',
    cursor: 'pointer',
    backgroundColor: theme.palette.launchpad.tileBackground,
    '&:hover': {
      border: `1px solid ${theme.palette.launchpad.border}`
    }
  },
  firstContainer: {
    width: '10.5rem'
  },
  tileHeader: {
    fontSize: ' 1.2rem',
    fontWeight: '400',
    paddingLeft: '1.25rem',
    marginBottom: '5px',
    textDecoration: 'none !important',
    lineHeight: '1.5rem',
    wordBreak: 'break-word',
    paddingRight: '5px'
  },
  tileSubHeader: {
    fontSize: '0.938rem',
    paddingLeft: '1.25rem',
    marginTop: '1px',
    marginBottom: '1.5rem',
    lineHeight: '1rem',
    paddingRight: '5px',
    color: theme.palette.launchpad.subHeaderColor,
    wordBreak: 'break-word'
  },
  tileIconNumber: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2.6rem !important',
    fontWeight: '100 !important',
    borderRadius: ' 5rem',
    padding: '0.25rem',
    height: '3.7rem',
    minWidth: '3.7rem',
    backgroundColor: theme.palette.launchpad.tileBackground
  },
  tileNumberAboveText: {
    marginTop: '-0.2rem !important',
    textAlign: 'center',
    fontSize: '0.9rem !important'
  },
  aboveNumber: {
    position: 'absolute',
    top: '2.2rem',
    right: '0',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2.6rem !important',
    fontWeight: '100 !important',
    padding: '0.25rem'
  },
  footerText: {
    position: 'absolute',
    fontSize: '0.938rem',
    paddingLeft: '1.25rem',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  chartContainer: {
    width: '24rem',
    height: '11.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  chartContainerPlaceholder: {
    width: '24rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  chartLargeContainer: {
    width: '11.8rem',
    paddingTop: '0.8rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  newsBox: {
    width: '100%',
    height: '6rem',
    backgroundColor: alpha(theme.palette.primary.main, 0.7),
    position: 'absolute',
    bottom: ' 0%',
    borderRadius: ' 0 0 8px 8px',
    color: theme.palette.launchpad.newsLines
  },
  newsLine: {
    fontSize: '1rem',
    margin: '5px 0 0.5rem 1rem',
    fontWeight: '400'
  },
  newsHeder: {
    fontSize: '1.25rem',
    margin: '5px 0 1px 1rem',
    fontWeight: '500',
    lineHeight: ' 0.8rem',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.2rem',
      fontSize: '1rem'
    }
  },
  newsDate: {
    fontSize: '1rem',
    fontWeight: '400',
    margin: '0',
    paddingLeft: ' 1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem'
    }
  }
}));

const Tile = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    wide = 'extra',
    tileHeader = '',
    tileSubheader = '',
    iconName = 'employee',
    iconNumber = '',
    iconNumberAbove = '',
    iconNumberText = '',
    iconNumberAboveText = '',
    iconNumberBelowText = '',
    numberTextColor = '',
    numberTextAboveColor = '',
    footerText = '',
    chart = '',
    linkName = '',
    newsBox = false,
    newsLine = '',
    newsHeder = '',
    newsDate = '',
    provided = {},
    offsetWidth,
    dataset,
    dataTestId,
    iconMarginBottom
  } = props;

  const tileWidth = () => {
    switch (wide) {
      case 'small':
        return '12rem';
      case 'large':
        return '25rem';
      case 'extra':
        return '38rem';
      default:
        return '11.5rem';
    }
  };
  const toLink = useCallback(
    (linkName) => {
      if (!linkName) {
        return;
      }

      if (linkName.includes(coursesPath)) {
        navigate({ to: linkName });
        return;
      }

      navigate({ to: linkName, search: { activeTab: TAB_KEYS.OVERVIEW } });
    },
    [navigate, linkName]
  );

  return (
    <Box
      role="presentation"
      data-test-id={dataTestId}
      sx={{
        width: {
          cursor: !linkName ? 'unset !important' : 'pointer !important',
          xs: `${offsetWidth / 2 - 28}px`,
          md: `${tileWidth()}`,
          opacity: !linkName ? '0.4' : 1
        }
      }}
      className={newsBox ? classes.newsBackground : classes.tileContainer}
      onClick={() => toLink(linkName)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <Box
        data-test-id="text-header-sub-header"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box className={classes.firstContainer}>
          <Box sx={{ minHeight: `${footerText ? '4.6rem' : '5.6rem'}` }}>
            <h3 data-test-id={`${tileHeader}-tile-launchpad`} className={classes.tileHeader}>
              {tileHeader}
            </h3>
            <p className={classes.tileSubHeader}>{tileSubheader}</p>
          </Box>

          <Box
            data-test-id={`${tileHeader}-tile-icon`}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginLeft: '1.25rem '
            }}
          >
            {iconName && (
              <Icon
                color="primary"
                sx={{
                  fontSize: '2.4rem',
                  marginBottom: `${iconMarginBottom ? iconMarginBottom : null}`
                }}
              >
                {iconName}
              </Icon>
            )}
            <Box>
              <Tooltip
                data-test-id="number-text"
                title={iconNumberText}
                placement="top"
                arrow
                TransitionComponent={Zoom}
              >
                <Typography
                  data-test-id={`${tileHeader}-number`}
                  className={classes.tileIconNumber}
                  sx={{
                    color: `${
                      numberTextColor
                        ? get(theme.palette, numberTextColor)
                        : theme.palette.launchpad.contrastText
                    }`,
                    visibility: `${newsBox ? 'hidden' : 'visible'}`
                  }}
                >
                  {iconNumber}
                </Typography>
              </Tooltip>
              {iconNumberBelowText && (
                <Typography
                  className={classes.tileNumberAboveText}
                  sx={{ position: 'absolute', right: '10px' }}
                >
                  {iconNumberBelowText}
                </Typography>
              )}
            </Box>
          </Box>

          <Box className={classes.aboveNumber}>
            {iconNumberAbove !== '' && (
              <Typography
                color={
                  numberTextAboveColor !== ''
                    ? get(theme.palette, numberTextAboveColor)
                    : theme.palette.primary.light
                }
                className={classes.tileIconNumber}
              >
                {iconNumberAbove}
              </Typography>
            )}
            <Typography className={classes.tileNumberAboveText}>{iconNumberAboveText}</Typography>
          </Box>
          <Box className={classes.footerText}>{footerText}</Box>
        </Box>
        {wide !== 'small' && (
          <Box
            className={
              wide === 'extra'
                ? dataset?.length !== 0
                  ? classes.chartContainer
                  : classes.chartContainerPlaceholder
                : classes.chartLargeContainer
            }
          >
            {/* TO DO NEW MUI chart react-chartjs-2*/}
            {chart}
          </Box>
        )}
        {newsBox && (
          <Box className={classes.newsBox}>
            <h5 className={classes.newsLine}>{newsLine} </h5>
            <h3 className={classes.newsHeder}> {newsHeder}</h3>
            <h5 className={classes.newsDate}>{newsDate}</h5>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Tile;
