import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default () =>
  yup.object().shape({
    maxOccupancy: yup
      .number()
      .required(Localize.get('ValidationMessages.NumberRequired'))
      .typeError(Localize.get('ValidationMessages.MaxOccupancy'))
      .min(1, Localize.get('ValidationMessages.PositiveNumber')),
    description: yup.string().max(100, Localize.get('ValidationMessages.DescriptionTooLong'))
  });
