import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { store } from '@common/providers/Redux';

export const initialState = {
  isOpen: false,
  config: {},
  action: ''
};

export const CONFIRM_DIALOG = 'confirmDialog';

export const CONFIRM_ACTIONS = { Confirm: 'Confirm', Cancel: 'Cancel' };

export const openConfirmDialog = createAsyncThunk(
  `${CONFIRM_DIALOG}/openConfirmDialog`,
  (config, { dispatch, getState }) => {
    dispatch(openDialog(config));

    return new Promise((resolve) => {
      const handleChange = () => {
        const state = getState();

        if (state.CONFIRM_DIALOG.action === CONFIRM_ACTIONS.Confirm) {
          dispatch(closeConfirmDialog());
          unsubscribe();
          return resolve(CONFIRM_ACTIONS.Confirm);
        }

        if (state.CONFIRM_DIALOG.action === CONFIRM_ACTIONS.Cancel) {
          dispatch(closeConfirmDialog());
          unsubscribe();
          return resolve(CONFIRM_ACTIONS.Cancel);
        }
      };

      const unsubscribe = store.subscribe(handleChange);
    });
  }
);

export const confirmDialogSlice = createSlice({
  name: CONFIRM_DIALOG,
  initialState,
  reducers: {
    closeConfirmDialog: (state, action) => {
      state.action = action.payload;
      state.isOpen = false;
    },
    openDialog: (state, action) => {
      state.config = action.payload;
      state.isOpen = true;
    }
  }
});

export const selectIsOpen = (state) => state.CONFIRM_DIALOG.isOpen;
export const selectConfig = (state) => state.CONFIRM_DIALOG.config;

const { actions, reducer } = confirmDialogSlice;

export const { closeConfirmDialog, openDialog } = actions;

export default reducer;
