const capitalizeFirstLetter = (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1);

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

function replaceAll(text, correction) {
  const reg = new RegExp(Object.keys(correction).join('|'), 'g');
  return text.replace(reg, (matched) => correction[matched]);
}

export { capitalizeFirstLetter, isJsonString, replaceAll };
