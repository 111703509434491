import LaunchPad from '@pages/LaunchPad';

import { rootPath } from '../Routes';

const LaunchpadRoute = () => ({
  path: rootPath,
  element: <LaunchPad />
});

export default LaunchpadRoute;
