import { Navigate } from 'react-location';

import OrgStructures, { CreateOrgStructure } from '@pages/OrgStructures';

import { orgStructuresNewPath, orgStructuresPath, rootPath } from '../Routes';

const OrgStructureRoute = () => ({
  path: orgStructuresPath,
  children: [
    {
      path: rootPath,
      element: <OrgStructures />
    },
    {
      path: orgStructuresNewPath,
      element: <CreateOrgStructure />
    },
    {
      element: <Navigate to={orgStructuresPath} />
    }
  ]
});

export default OrgStructureRoute;
