import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

const businessPartnerFilters = [
  {
    label: 'BusinessPartners.Name',
    id: 'name',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Type',
    id: 'businessPartnerType',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: []
  },
  {
    label: 'Labels.Role',
    id: 'role',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: []
  },
  {
    label: 'Labels.SupplierType',
    id: 'supplierType',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: []
  },
  {
    label: 'Labels.VAT',
    id: 'vatNumber',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.AbcClassification',
    id: 'abcClassification',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: []
  },
  {
    label: 'Labels.Country',
    id: 'country',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: []
  },
  {
    label: 'BusinessPartners.AddressCity',
    id: 'city',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Address.PostalCode',
    id: 'postalCode',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  }
];

export default businessPartnerFilters;
