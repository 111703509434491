import React, { useEffect } from 'react';
import { useMatch } from 'react-location';
import { useDispatch } from 'react-redux';

import axios from 'axios';

import { CircularProgress } from '@mui/material';

import { setNewExternalToken, checkUser } from '@pages/Login/loginSlice';

const ExternalLogin = () => {
  const dispatch = useDispatch();
  const {
    // params: { externalToken, email, roles }
    params: { externalToken }
  } = useMatch();

  useEffect(async () => {
    if (externalToken) {
      dispatch(checkUser(externalToken));
    }
  }, [externalToken]);

  return (
    <>
      <CircularProgress size="2rem" />
    </>
  );
};

export default ExternalLogin;
