import { isNil, omit } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  NAVIGATE_TOASTER_TIMEOUT,
  TAB_KEYS,
  dateInitFormats
} from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { EVENTS_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeSidebar } from '@components/RightSidebar/rightSidebarSlice';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { setDetails as setEventDetails } from '@pages/Events/eventsSlice';
import {
  deleteByPath,
  getByPathAndParams,
  patchByPathAndData,
  postByPathAndData
} from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    // WAITING FOR THE BE
    // sortBy: SORT_DATA_HOTELS[0].name,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0]
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  details: null
};

export const EVENTS_PRICES_SLICE = 'eventPrices';

export const fetchPrices = createAsyncThunk(
  `${EVENTS_PRICES_SLICE}/prices`,
  ({ entityId, filter }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: EVENTS_PATHS.GET_PRICES,
      pathVariables: { id: entityId },
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => {
        return rejectWithValue(error.response);
      });
  }
);

export const deletePrices = createAsyncThunk(
  `${EVENTS_PRICES_SLICE}/delete`,
  ({ id, priceIds }, { dispatch, rejectWithValue }) => {
    return deleteByPath({
      path: EVENTS_PATHS.DELETE_PRICE,
      pathVariables: { id, priceIds }
    })
      .then((response) => {
        dispatch(setEventDetails({ counts: { [TAB_KEYS.PRICE]: response.data.count } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PRICES, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PRICES, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return { response, priceIds };
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const createPrices = createAsyncThunk(
  `${EVENTS_PRICES_SLICE}/create`,
  ({ values, navigate, setSubmitting }, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const { details, selectedId } = state.EVENTS_SLICE;
    const price = parseInt(values.price);
    const entityId = parseInt(selectedId);

    const formattedValidFrom = moment(values.validFrom).format('YYYY-MM-DD');
    const formattedValidTo = moment(values.validTo).format('YYYY-MM-DD');

    return postByPathAndData({
      path: EVENTS_PATHS.POST_PRICES,
      data: {
        typeId: values.typeId,
        price: price,
        currencyId: values.currencyId,
        validFrom: formattedValidFrom,
        validTo: formattedValidTo,
        eventId: entityId
      },
      pathVariables: { id: entityId }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PRICES, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        dispatch(
          setEventDetails({
            ...details,
            counts: {
              ...details.counts,
              price: response.data.counts.price
            }
          })
        );

        setTimeout(() => {
          navigate({
            search: (previousUrlParams) => {
              const newParams = { ...omit(previousUrlParams, 'mode') };
              return { ...newParams, activeTab: TAB_KEYS.PRICE };
            }
          });
        }, NAVIGATE_TOASTER_TIMEOUT);

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      })
      .finally(() => setSubmitting(false));
  }
);

export const patchPrices = createAsyncThunk(
  `${EVENTS_PRICES_SLICE}/patchPrice`,
  ({ data, id, eventId }, { rejectWithValue, dispatch }) => {
    const patchData = { ...data };

    if (patchData?.currency) {
      patchData.currencyId = patchData.currency.id;
      delete patchData.currency;
    }
    if (patchData?.type) {
      patchData.typeId = patchData.type.id;
      delete patchData.type;
    }

    if (patchData?.validFrom) {
      patchData.validFrom = moment(patchData.validFrom).format(dateInitFormats.basicDate);
    }

    if (patchData?.validTo) {
      patchData.validTo = moment(patchData.validTo).format(dateInitFormats.basicDate);
    }

    return patchByPathAndData({
      path: EVENTS_PATHS.PATCH_PRICES,
      data: patchData,
      pathVariables: { eventId, id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PRICES, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response?.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response);
      });
  }
);

export const eventPriceTableSlice = createSlice({
  name: EVENTS_PRICES_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setDetails: (state, { payload }) => {
      state.details = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchPrices.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload?.content ?? [];
      })
      .addCase(fetchPrices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPrices.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(createPrices.fulfilled, (state, { payload }) => {
        state.data = [payload, ...state.data];
        state.isLoading = false;
        state.totalElements += 1;
      })
      .addCase(createPrices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPrices.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deletePrices.fulfilled, (state, { payload }) => {
        const { priceIds } = payload;
        state.selectionModel = [];
        state.data = state.data.filter(({ id }) => !priceIds.includes(id));
        state.totalElements -= priceIds.length;
      })
      .addCase(patchPrices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchPrices.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(patchPrices.fulfilled, (state, { payload, meta }) => {
        state.data = state.data.map((item) =>
          item.id === meta.arg.id ? deepMerge(item, payload) : item
        );
        state.details = deepMerge(state.details, payload);
        state.isLoading = false;
      })
      .addCase(closeSidebar, (state) => {
        state.details = null;
      });
  }
});

export const selectList = (state) => state.EVENTS_PRICES_SLICE.data;
export const selectRows = (state) => state.EVENTS_PRICES_SLICE.rows;
export const selectDetails = (state) => state.EVENTS_PRICES_SLICE.details;
export const selectTotalElements = (state) => state.EVENTS_PRICES_SLICE.totalElements;
export const selectTotalPages = (state) => state.EVENTS_PRICES_SLICE.totalPages;
export const selectFilter = (state) => state.EVENTS_PRICES_SLICE.filter;
export const selectIsLoading = (state) => state.EVENTS_PRICES_SLICE.isLoading;
export const selectSelectionModel = (state) => state.EVENTS_PRICES_SLICE.selectionModel;

const { actions, reducer } = eventPriceTableSlice;

export const { setDetails, setData, setFilterParams, resetState, setSelectionModel } = actions;

export default reducer;
