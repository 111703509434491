import { copyObjectWithoutRef } from '@common/helpers/helpers';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { ADDRESS_PATH, BUSINESS_PARTNERS_PATHS, LOCALES_PATHS } from '@common/network/ApiPaths';
import { account } from '@pages/BusinessPartners/util/fields';

export const mapPropsToAddress = (setFieldValue, arrayItemIndex, arrayItem, values) => {
  return {
    ['region']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue(`organizationPerson.addressDtos[${arrayItemIndex}].region`, data?.newValue);
      },
      path: ADDRESS_PATH.GET_REGION_BY_ID,
      pathVariables: {
        id: arrayItem?.country?.id
      },
      pathVariablesRequired: ['id'],
      disabled: !values.organizationPerson.addressDtos[arrayItemIndex]?.country
    },
    ['country']: {
      path: LOCALES_PATHS.COUNTRIES,
      formItemChangeHandler: (e, data, name) => {
        const fieldIndex = name && name.split('.')[2];
        const addressDtos = copyObjectWithoutRef(values.organizationPerson.addressDtos);
        addressDtos[fieldIndex].country = data.newValue;
        addressDtos[fieldIndex].region = null;
        setFieldValue('organizationPerson.addressDtos', addressDtos);
      }
    }
  };
};

export const getAccountConfig = (
  values,
  setFieldValue,
  roleTypes,
  phoneTypes,
  emailTypes,
  addressTypes
) => {
  return mapPropsToComponent(account, {
    ['owner']: {
      tokenValue:
        values?.owner?.firstName && values?.owner?.lastName
          ? [
              {
                title: `${values.owner.firstName} ${values.owner.lastName}`
              }
            ]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('owner', null);
        }
      },
      selectItem: (value) => {
        setFieldValue('owner', { ...value, id: value?.personTypeId });
      }
    },
    ['roles']: {
      options: roleTypes,
      disabled: values.businessPartnerType === 2
    },
    ['abcClassification']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue('abcClassification', data.newValue);
      },
      path: BUSINESS_PARTNERS_PATHS.ABC_CLASSIFICATION
    },
    ['organizationPerson.organization.parentalAccount']: {
      tokenValue: values.organizationPerson?.organization?.parentalAccount?.name
        ? [{ title: values.organizationPerson.organization.parentalAccount.name }]
        : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('organizationPerson.organization.parentalAccount', null);
        }
      },
      selectItem: (value) => {
        setFieldValue('organizationPerson.organization.parentalAccount', value);
        setFieldValue(
          'organizationPerson.organization.parentalAccount.id',
          value.organizationPersonId
        );
      }
    },
    ['organizationPerson.phoneDtos']: {
      ['type.id']: {
        options: phoneTypes
      }
    },
    ['organizationPerson.emailDtos']: {
      ['type.id']: {
        options: emailTypes
      }
    },
    ['organizationPerson.addressDtos']: {
      ['type.id']: {
        options: addressTypes
      }
    }
  });
};

export default getAccountConfig;
