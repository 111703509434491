import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

export const contactsFilters = [
  {
    label: 'Labels.FirstName',
    id: 'firstName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.LastName',
    id: 'lastName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Title',
    id: 'title',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: [
      {
        label: 'Mr',
        key: 'Mr'
      },
      {
        label: 'Mrs',
        key: 'Mrs'
      },
      {
        label: 'Ms',
        key: 'Ms'
      }
    ]
  },
  {
    label: 'ContactsTile.JobTitle',
    id: 'jobTitle',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'ContactsTile.Function',
    id: 'function',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'ContactsTile.Department',
    id: 'department',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'ContactsTile.AccountName',
    id: 'businessPartner',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Country',
    id: 'country',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  },
  {
    label: 'ContactsTile.City',
    id: 'city',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  }
];
