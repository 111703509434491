import React, { useEffect, useState, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import ActivityStatus from '@components/ActivityStatus';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import {
  FILTER_ACTIONS,
  openFilterDialog,
  resetFilterState,
  scrubFiltersForBE,
  selectIsActive
} from '@components/FilterDialog/filtersSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import { activitiesNewPath } from '@navigation/routes/Routes';
import {
  setFilterParams,
  setLoading,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectDetails,
  selectIsLoading,
  selectIsDetailsLoading,
  selectList,
  setSelectedId,
  selectId,
  initialState,
  saveActivity,
  fetchActivities,
  fetchActivityDetails,
  resetState,
  setDetails,
  deleteActivity
} from '@pages/Activities/activitiesSlice';
import Sidebar from '@pages/Activities/components/Sidebar';

import { ActivitiesDetails, ActivitiesTabs } from './components';
import { activitiesFilters } from './util/fields/filter';
import { SORT_DATA } from './util/sortConfig';
import { getActivitiesOptions } from './util/util';

const Activities = () => {
  const {
    data: {
      activityAppointmentCategory: { data: activityAppointmentCategory },
      activityTaskCategory: { data: activityTaskCategory }
    }
  } = useMatch();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);
  const selectedId = useSelector(selectId);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const filter = useSelector(selectFilter);

  const isLoading = useSelector(selectIsLoading);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);
  const isFilterActive = useSelector(selectIsActive);
  // eslint-disable-next-line no-unused-vars
  const taskCategoryOptions = (activitiesFilters.find((el) => el.id === 'taskCategory').options =
    activityTaskCategory.map((el) => ({
      label: `TaskCategories.${el.value}`,
      key: el.value
    })));
  // eslint-disable-next-line no-unused-vars
  const appointmentCategoryOptions = (activitiesFilters.find(
    (el) => el.id === 'appointmentCategory'
  ).options = activityAppointmentCategory.map((el) => ({
    label: `AppointmentCategories.${el.value}`,
    key: el.value
  })));

  // Get List of entities on initialization and sort change
  useEffect(
    () => dispatch(fetchActivities(filter)),
    [filter.sortBy, filter.sortDirection, filter.page]
  );

  // Get initial List of entities and on sort change
  useEffect(() => dispatch(fetchActivityDetails(selectedId)), [selectedId]);

  // Get List of entities on search change
  useDebounce(() => dispatch(fetchActivities(filter)), SEARCH_INPUT_DELAY, [filter.search]);

  // Resets state on component destroy
  useEffect(() => {
    return () => {
      dispatch(resetFilterState());
      dispatch(resetState());
    };
  }, []);

  const onSave = useCallback(
    (values) => {
      return dispatch(saveActivity({ ...values, id: selectedId }))
        .unwrap()
        .then(() => {
          return Promise.resolve();
        })
        .catch((rejectedValueOrSerializedError) => {
          return Promise.reject({
            rejectedValueOrSerializedError,
            entityType: EntityTypes.ACTIVITY
          });
        });
    },
    [selectedId]
  );

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('Activities.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteActivity(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  const renderMasterListItem = useCallback(
    ({ id, type, subject, status }) => {
      return (
        <MasterListItem
          key={id}
          id={id}
          heading={{ left: subject, right: id }}
          rightDetails={{
            thirdRow: <ActivityStatus status={status?.id}>{status?.value}</ActivityStatus>
          }}
          leftDetails={{
            firstRow: `${type?.value}`
          }}
          selectedId={selectedId}
          onItemSelect={(payload) => dispatch(setSelectedId(payload))}
        />
      );
    },
    [selectedId]
  );

  const onFilterClick = useCallback(() => {
    dispatch(
      openFilterDialog({
        title: `${Localize.get('Activities.Item')} ${Localize.get('Labels.Filter')}`,
        config: activitiesFilters.map((filter) => ({
          ...filter,
          label: Localize.get(filter.label),
          operator: { label: Localize.get(filter.operator.label), key: filter.operator.key },
          options: getActivitiesOptions(filter.options)
        }))
      })
    ).then(({ payload }) => {
      if (payload.action === FILTER_ACTIONS.Cancel) {
        return;
      }

      if (payload.action === FILTER_ACTIONS.Reset) {
        dispatch(fetchActivities(filter));
        return;
      }

      dispatch(
        fetchActivities({
          ...filter,
          filters: { advancedFilters: scrubFiltersForBE(payload.filters) }
        })
      );
    });
  }, [details?.type?.value, filter?.sortBy, filter?.sortDirection, filter?.page]);

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'Activities.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isFilterActive={isFilterActive}
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          onFilterClick={onFilterClick}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />

        <MasterList isLoading={isLoading} data={data} renderMasterItem={renderMasterListItem} />
        <MasterListFooter
          onPageChange={(e, page) => dispatch(fetchActivities({ ...filter, page: page - 1 }))}
          onAddClick={() => navigate({ to: activitiesNewPath, replace: false })}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          onCancel={() => setIsOpenDialogSort(false)}
          open={isOpenDialogSort}
          sortState={filter}
          fields={SORT_DATA}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <ActivitiesDetails entityId={selectedId} details={details}>
          <ActivitiesTabs
            entityId={selectedId}
            entityType={details?.type?.id === 1 ? EntityTypes.APPOINTMENT : EntityTypes.TASK}
            details={details}
            setDetails={setDetails}
          />
        </ActivitiesDetails>
      </RightContainer>

      <Sidebar
        isDetailsLoading={isDetailsLoading}
        data={details}
        onSave={onSave}
        onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default Activities;
