import React from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useMatch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import { omit } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { TAB_KEYS } from '@common/Constants';
import CreateForm from '@components/CreateForm';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { selectDetails } from '@pages/IltSession/iltSessionSlice';

import { createTask } from '../tasksTableSlice';
import getFieldsConfig from './utils/getFieldsConfig';
import tasksInitialValues from './utils/tasksInitialValues';
import useCreateTaskSchema from './utils/useCreateTaskSchema';

const CreateTask = () => {
  // activity priorities, statuses and categories are the same as on activity tile and this part is re-used from that tile
  const {
    data: {
      activityPriorities: { data: activityPriorities },
      activityStatuses: { data: activityStatuses },
      activityTaskCategories: { data: activityTaskCategories }
    }
  } = useMatch();
  const details = useSelector(selectDetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const taskSchema = useCreateTaskSchema();

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await dispatch(createTask({ data: values, sessionId: details?.id }));
      navigate({ search: { activeTab: TAB_KEYS.TASK }, replace: false });
    } catch (rejectedValueOrSerializedError) {
      console.error(rejectedValueOrSerializedError);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={taskSchema}
      onSubmit={onSubmit}
      initialValues={tasksInitialValues}
    >
      {({ isSubmitting, handleSubmit, isValid, setFieldValue, values }) => (
        <>
          <InlineCreateHeaderContainer>
            <Button
              data-test-id="cancel-btn"
              variant="outlined"
              onClick={() =>
                navigate({
                  search: (previousUrlParams) => ({ ...omit(previousUrlParams, 'mode') })
                })
              }
              color="error"
              sx={{ mr: 1 }}
              startIcon={<CancelIcon />}
            >
              {Localize.get('Buttons.Cancel')}
            </Button>

            <Button
              data-test-id="save-btn"
              variant="outlined"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
            >
              {Localize.get('Buttons.Save')}
            </Button>
          </InlineCreateHeaderContainer>

          <CreateForm
            values={values}
            fieldsConfig={getFieldsConfig(
              setFieldValue,
              values,
              activityStatuses,
              activityPriorities,
              activityTaskCategories
            )}
          />
        </>
      )}
    </Formik>
  );
};

export default CreateTask;
