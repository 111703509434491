export const rootPath = '/';
export const catchPath = '*';
export const newPath = '/new';

// Activities
export const activitiesPath = '/activities';
export const activitiesNewPath = `${activitiesPath}${newPath}`;

// Business Partners
export const businessPartnersPath = '/business-partners';
export const businessPartnerNewPath = `${businessPartnersPath}${newPath}`;

// Calendar
export const calendarPath = '/calendar';

// Calendar
export const emailsPath = '/emails';

// Contacts
export const contactsPath = '/contacts';
export const contactsNewPath = `${contactsPath}${newPath}`;

// Courses
export const coursesPath = '/courses';

// Employees
export const employeesPath = '/employees';
export const employeesNewPath = `${employeesPath}${newPath}`;

// Events
export const eventsPath = '/events';
export const eventsDetailsSlug = '/:eventId';
export const eventsSessionDetailsSlug = '/session-details/:id';
export const eventsParticipantDetailsSlug = '/participant-details/:id';
export const eventsDetailsHotelSlug = '/hotels/:id';
export const eventsHotelBookingSlug = '/hotel-contingent/:id';

export const eventsDetailsHotelPath = `${eventsPath}${eventsDetailsSlug}${eventsDetailsHotelSlug}`;

export const eventsNewPath = `${eventsPath}${newPath}`;
export const eventsDetailPath = `${eventsPath}${eventsDetailsSlug}`;
export const eventsSessionDetailsPath = `${eventsPath}${eventsDetailsSlug}${eventsSessionDetailsSlug}`;
export const eventsParticipantDetailsPath = `${eventsPath}${eventsDetailsSlug}${eventsParticipantDetailsSlug}`;
export const eventsHotelContingentPath = `${eventsPath}${eventsDetailsSlug}${eventsHotelBookingSlug}`;

// Ilt Sessions
export const iltSessionsPath = '/ilt-sessions';
export const iltSessionsDetailsSlug = '/:sessionId';
export const iltSessionsDetailsHotelSlug = '/hotels/:id';
export const iltSessionsDetailsSessionDaySlug = '/session-day/:id';
export const iltSessionsHotelBookingSlug = '/hotel-contingent/:id';

export const iltSessionsNewPath = `${iltSessionsPath}${newPath}`;
export const iltSessionsDetailPath = `${iltSessionsPath}${iltSessionsDetailsSlug}`;
export const iltSessionsHotelContingentPath = `${iltSessionsPath}${iltSessionsDetailsSlug}${iltSessionsHotelBookingSlug}`;
export const iltSessionsDetailsHotelPath = `${iltSessionsPath}${iltSessionsDetailsSlug}${iltSessionsDetailsHotelSlug}`;
export const iltSessionsSessionDayPath = `${iltSessionsPath}${iltSessionsDetailsSlug}${iltSessionsDetailsSessionDaySlug}`;

// Locations
export const locationsPath = '/locations';
export const locationsNewPath = `${locationsPath}${newPath}`;

// Locations
export const meetingRoomsPath = '/meeting-rooms';
export const meetingRoomsNewPath = `${meetingRoomsPath}${newPath}`;

// Org Structures
export const orgStructuresPath = '/org-structures';
export const orgStructuresNewPath = `${orgStructuresPath}${newPath}`;

// Participants
export const participantsPath = '/participants';
export const participantsNewPath = `${participantsPath}${newPath}`;

// Resources
export const resourcesPath = '/resources';
export const resourcesNewPath = `${resourcesPath}${newPath}`;

// Login
export const loginPath = '/login';

// Saml Login
export const samlLoginPath = '/saml-login/:externalToken';
