import React from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useDispatch } from 'react-redux';

import { Field, Form, Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import { RESOURCES_PATHS } from '@common/network/ApiPaths';
import CreateFooter from '@components/CreateFooter';
import FormContainer from '@components/FormContainer';
import FormGroupName from '@components/FormGroupName';
import Header from '@components/Header';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import FormComponentsMap from '@config/FormComponentsMap';
import { resourcesPath } from '@navigation/routes/Routes';
import { createResource } from '@pages/Resources/resourcesSlice';
import resourceSchema from '@pages/Resources/util/schemas/resourceSchema';

import fieldsCreate from '../../util/fields/createResourceFields';
import { initResource } from '../../util/util';

const CreateResource = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCancelClick = () => {
    navigate({
      to: resourcesPath,
      search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
      replace: false
    });
  };

  const onSubmit = (resource, actions) => {
    dispatch(createResource(resource))
      .unwrap()
      .then(() =>
        navigate({
          to: resourcesPath,
          search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
          replace: false
        })
      )
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'RESOURCE', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <>
      <Header>{Localize.get('ResourcesTile.NewResource')}</Header>
      <Formik
        validateOnMount
        initialValues={initResource}
        onSubmit={onSubmit}
        validationSchema={resourceSchema}
      >
        {({ isSubmitting, values, handleSubmit, isValid, setValues, setFieldValue }) => {
          return (
            <FormContainer>
              <Box sx={{ width: '100%' }} noValidate>
                <Form>
                  {mapPropsToComponent(fieldsCreate, {
                    ['resourceCategory']: {
                      formItemChangeHandler: (e, data) => {
                        setFieldValue('resourceCategory', data.newValue);
                      },
                      path: RESOURCES_PATHS.RESOURCE_CATEGORIES
                    },
                    ['glAccount']: {
                      formItemChangeHandler: (e, data) => {
                        setFieldValue('glAccount', data.newValue);
                      },
                      path: RESOURCES_PATHS.GL_ACCOUNTS
                    },
                    ['giftWarehouse']: {
                      formItemChangeHandler: (e, data) => {
                        setFieldValue('giftWarehouse', data.newValue);
                      },
                      path: RESOURCES_PATHS.GIFT_WAREHOUSES
                    },
                    ['availableAmount']: {
                      disabled: values.irrAmount
                    },
                    ['irrAmount']: {
                      onChange: (e, newValue) =>
                        setValues({
                          ...values,
                          irrAmount: newValue,
                          availableAmount: initResource.availableAmount
                        })
                    },
                    ['price']: {
                      disabled: !values.billableItem
                    },
                    ['currency']: {
                      disabled: !values.billableItem,
                      formItemChangeHandler: (e, data) => {
                        setFieldValue('currency', data.newValue);
                      },
                      path: RESOURCES_PATHS.CURRENCIES
                    },
                    ['billableItem']: {
                      onChange: (e, newValue) =>
                        setValues({
                          ...values,
                          billableItem: newValue,
                          price: initResource.price,
                          currency: initResource.currency
                        })
                    }
                  }).map(({ groupName, fields }, index) => (
                    <Grid container spacing={{ xs: 0, md: 0 }} key={index}>
                      <Grid item xs={12}>
                        <FormGroupName groupName={Localize.get(groupName)} />
                      </Grid>

                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        {fields?.map(({ component, label, ...rest }, fieldIndex) => (
                          <Grid key={`${index}-${fieldIndex}`} item xs={12} sm={6} sx={{ mb: 3 }}>
                            {component ? (
                              <FormControl fullWidth size="small">
                                <Field
                                  {...rest}
                                  label={Localize.get(label)}
                                  component={FormComponentsMap[component]}
                                />
                              </FormControl>
                            ) : (
                              <div />
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Form>
              </Box>
              <CreateFooter>
                <Button
                  data-test-id="cancel-btn"
                  variant="outlined"
                  onClick={onCancelClick}
                  color="error"
                  sx={{ mr: 1 }}
                  startIcon={<CancelIcon />}
                >
                  {Localize.get('Buttons.Cancel')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  data-test-id="save-btn"
                  variant="outlined"
                  disabled={isSubmitting || !isValid}
                  onClick={handleSubmit}
                  startIcon={<SaveIcon />}
                >
                  {Localize.get('Buttons.Save')}
                </Button>
              </CreateFooter>
            </FormContainer>
          );
        }}
      </Formik>
    </>
  );
};
export default CreateResource;
