export default [
  {
    groupName: 'ResourcesTile.PriceInformation',
    fields: [
      {
        label: 'Labels.PriceType',
        name: 'typeId',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'ResourcesTile.Price',
        name: 'price',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'ResourcesTile.Currency',
        name: 'currencyId',
        required: true,
        disabled: false,
        component: 'select'
      }
    ]
  }
];
