import React from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useSelector } from 'react-redux';

import { Box, Link } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import Skeleton from '@components/CustomSkeleton/CustomSkeleton';
import NoData from '@components/NoData/NoData';
import TabContainer from '@components/TabContainer/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import { businessPartnerNewPath } from '@navigation/routes/Routes';
import {
  selectDetails,
  selectIsDetailsLoading
} from '@pages/BusinessPartners/businessPartnersSlice';

import BasicInfo from './BasicInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`
    }
  }
}));

const BusinessPartnersOverview = () => {
  const details = useSelector(selectDetails);
  const isLoading = useSelector(selectIsDetailsLoading);
  const navigate = useNavigate();

  const classes = useStyles({ width: '400px' });

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Labels.Overview')}
          </Typography>
        </div>
        <TabContainer>
          {isLoading ? (
            <Box display="flex" flexDirection="column">
              <Skeleton
                skeletonCount={6}
                skeletonProps={{
                  variant: 'rectangular',
                  height: '60px',
                  width: '400px',
                  sx: { mb: 1 }
                }}
              />
            </Box>
          ) : details ? (
            <Paper sx={{ height: '400px', width: '400px' }}>
              <BasicInfo data={details} />
            </Paper>
          ) : (
            <NoData
              titleText={Localize.get('NoDataMessage.TitleTextOverview')}
              subtitleText={
                <Link
                  onClick={() => navigate({ to: businessPartnerNewPath })}
                  sx={{
                    cursor: 'pointer',
                    fontSize: '16px'
                  }}
                >
                  {Localize.get('Links.InviteLink', {
                    entity: Localize.get('BusinessPartners.Item')
                  })}
                </Link>
              }
            />
          )}
        </TabContainer>
      </TabContent>
    </div>
  );
};

export default BusinessPartnersOverview;
