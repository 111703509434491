import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';

import fieldsConfig from './fieldsConfig';

export const getFieldsConfig = (setFieldValue, priceType, currencyType) => {
  return mapPropsToComponent(fieldsConfig, {
    ['typeId']: {
      options: priceType
    },
    ['currencyId']: {
      options: currencyType
    },
    ['validFrom']: {
      onChange: (value) => setFieldValue('validFrom', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['validTo']: {
      onChange: (value) => setFieldValue('validTo', dateToFormat(value, dateInitFormats.dateTime))
    }
  });
};

export default getFieldsConfig;
