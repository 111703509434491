import { Navigate } from 'react-location';

import Calendar from '@pages/Calendar/Calendar';

import { calendarPath, rootPath } from '../Routes';

const CalendarRoute = () => ({
  path: calendarPath,
  children: [
    { path: rootPath, element: <Calendar /> },
    {
      element: <Navigate to={calendarPath} />
    }
  ]
});

export default CalendarRoute;
