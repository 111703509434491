import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import Overview from '@components/Overview';
import { activitiesNewPath } from '@navigation/routes/Routes';
import { selectDetails, selectIsDetailsLoading } from '@pages/Activities/activitiesSlice';

const ActivitiesOverview = () => {
  const details = useSelector(selectDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  return (
    <Overview
      invite={{
        text: Localize.get('Links.InviteLink', {
          entity: Localize.get('Activities.Item')
        }),
        link: activitiesNewPath
      }}
      isLoading={isDetailsLoading}
      data={details}
      config={
        !details
          ? { title: 'Activities.Overview' }
          : details?.type?.id === 1
          ? {
              title: 'Activities.AppointmentOverview',
              skeletonCount: 5,
              header: {
                title: 'Labels.Subject',
                subTitle: ['subject'],
                icon: 'event_available'
              },
              details: [
                {
                  label: 'Labels.Category',
                  name: 'appointmentDto.appointmentCategory.value'
                },
                {
                  label: 'Labels.StartDate',
                  name: 'startDateTime'
                },
                {
                  label: 'Labels.EndDate',
                  name: 'endDateTime'
                },
                {
                  label: 'Labels.Location',
                  name: 'appointmentDto.location'
                }
              ]
            }
          : {
              title: 'Activities.TaskOverview',
              skeletonCount: 5,
              header: {
                title: 'Labels.Subject',
                subTitle: ['subject'],
                icon: 'task'
              },
              details: [
                {
                  label: 'Labels.Category',
                  name: 'taskDto.taskCategory.value'
                },
                {
                  label: 'Labels.StartDate',
                  name: 'startDateTime'
                },
                {
                  label: 'Labels.EndDate',
                  name: 'endDateTime'
                }
              ]
            }
      }
    />
  );
};

export default ActivitiesOverview;
