import Typography from '@mui/material/Typography';

const FormContainer = ({ groupName = '', formGroupNameSX = {} }) => (
  <Typography
    component="h5"
    variant="h5"
    color="text.secondary"
    sx={{ mb: 3, mt: 4, ...formGroupNameSX }}
  >
    {groupName}
  </Typography>
);

export default FormContainer;
