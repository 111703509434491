import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { ACTIVITIES_PATHS } from '@common/network/ApiPaths';
import RightSidebar from '@components/RightSidebar';
import sidebarAppointment from '@pages/Activities/util/fields/sidebarAppointment';
import sidebarTask from '@pages/Activities/util/fields/sidebarTask';
import initialValues from '@pages/Activities/util/initialValues';
import useSidebarSchema from '@pages/Activities/util/schemas/useSidebarSchema';

const Sidebar = ({ isDetailsLoading = false, onSave = () => {}, onDelete = () => {}, data }) => {
  const {
    data: {
      activityPriorities: { data: activityPriorities },
      activityStatuses: { data: activityStatuses },
      activityTaskCategory: { data: activityTaskCategory },
      activityAppointmentCategory: { data: activityAppointmentCategory },
      type: { data: type }
    }
  } = useMatch();
  const sidebarSchema = useSidebarSchema();

  const extendedConfig = (setFieldValue, values, setValues) => ({
    ['status.id']: {
      path: ACTIVITIES_PATHS.ACTIVITIES_STATUSES,
      options: activityStatuses
    },
    ['priority.id']: {
      path: ACTIVITIES_PATHS.ACTIVITIES_PRIORITY,
      options: activityPriorities
    },
    ['type.value']: {
      path: ACTIVITIES_PATHS.ACTIVITIES_TYPES,
      options: type
    },
    ['taskDto.taskCategory']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue('taskDto.taskCategory', data.newValue);
      },
      options: activityTaskCategory
    },
    ['taskDto.processor']: {
      tokenValue:
        values?.taskDto?.processor?.firstName && values?.taskDto?.processor?.lastName
          ? [
              {
                title: `${values.taskDto.processor.firstName} ${values.taskDto.processor.lastName}`
              }
            ]
          : undefined,

      selectionModel: values?.taskDto.processorId,

      selectItem: (processor) => {
        setFieldValue('taskDto.processor', processor);
      },

      onIconClick: (e) => {
        if (e?.target?.name === 'delete')
          setFieldValue('taskDto.processor', initialValues.taskDto.processor);
      }
    },
    ['taskDto.completion']: {
      onChange: (e) => {
        setFieldValue('taskDto.completion', e.target.value);
      }
    },
    ['appointmentDto.appointmentCategory']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue('appointmentDto.appointmentCategory', data.newValue);
      },
      path: ACTIVITIES_PATHS.ACTIVITIES_APPOINTMENTS_CATEGORIES,
      options: activityAppointmentCategory
    },
    ['startDateTime']: {
      ampm: false,
      onChange: (value) =>
        setFieldValue('startDateTime', dateToFormat(value, dateInitFormats.dateTimeWithSeconds))
    },
    ['endDateTime']: {
      ampm: false,
      onChange: (value) => {
        setFieldValue('endDateTime', dateToFormat(value, dateInitFormats.dateTimeWithSeconds));
      }
    },
    onIconClick: (e) => {
      if (e?.target?.name === 'delete') {
        setValues({
          ...values,
          venue: initialValues.venue
        });
      }
    },
    ['owner.id']: {
      tokenValue:
        values?.owner?.firstName && values.owner?.lastName
          ? [{ title: `${values.owner.firstName} ${values.owner.lastName}` }]
          : undefined,
      selectionModel: values?.owner?.id,
      selectItem: (owner) => {
        setValues({
          ...values,
          owner: {
            id: owner.personTypeId,
            firstName: owner.firstName,
            lastName: owner.lastName
          }
        });
      },
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            owner: initialValues.owner
          });
        }
      }
    },
    ['account']: {
      tokenValue:
        values?.account?.id && values.account?.name
          ? [
              {
                title: `${values.account.name} `
              }
            ]
          : undefined,
      selectionModel: values?.account?.id,
      selectItem: (account) => {
        setValues({
          ...values,
          account: {
            id: account.id,
            name: account.name,
            organizationPersonId: account.organizationPersonId
          }
        });
      },
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            account: initialValues.account
          });
        }
      }
    },
    ['contact']: {
      tokenValue:
        values?.contact?.firstName && values.contact?.lastName
          ? [{ title: `${values.contact.firstName} ${values.contact.lastName}` }]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') setFieldValue('contact', initialValues.contact);
      },
      selectItem: (value) => {
        setFieldValue('contact', { ...value, id: value?.personTypeId });
      }
    }
  });

  return (
    <RightSidebar
      config={{
        title: Localize.get('Activities.Details'),
        fieldsConfig: data?.type?.id === 1 ? sidebarAppointment : sidebarTask
      }}
      validationSchema={sidebarSchema}
      extendedConfig={extendedConfig}
      data={data}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
};

export default Sidebar;
